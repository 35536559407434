import {EditBlockAction, registerModal} from './Modal'
import {finishEvent} from '../../../custom/eventUtils'
import React from 'react'
import {Block} from '../Block'
import './modalEditBlockAction.scss'
import {useSelector} from 'react-redux'
import {blockTypesSelector} from '../../../state/reducers/code'

registerModal(EditBlockAction, ({resolve, selected = {}}) => {
	const {block} = selected
	const blockTypes = useSelector(blockTypesSelector)
	const blockType = blockTypes[block.type]
	console.log("-> render ModalEditBlockAction.js", {block, blockType})

	if (!blockType) return null

	return <div className="centerDiv" onClick={finishEvent}>
		<h1>Edit block</h1>
		<div className="mediumModal">
			<div className="blockContainer blocks">
				<Block block={block} blockType={blockType} editMode/>
			</div>
		</div>
		<div className="mediumModal">
			<button onClick={resolve}><span className="fa-regular fa-floppy-disk"/> OK</button>
			<button onClick={() => resolve()}><span className="fa-regular fa-ban"/> Cancel</button>
		</div>
	</div>

})
