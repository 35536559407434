import {logoutUser} from '../../custom/serverApi'
import {loginPrepared} from './loginAction'

export const switchMenuType = "MENU/TOGGLE"
export const switchMenu = (isOpenNow) => {
	return {type: switchMenuType, open: !isOpenNow}
}

export const logout = async (dispatch) => {
	const success = await logoutUser()
	success && dispatch(loginPrepared())
}


