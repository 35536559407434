import {registerCallAction} from 'redux-action-processor'
import {sendAction} from '../custom/serverApi'
import {
	activateModuleAction,
	addModuleAction,
	addRoomAction,
	changeIconAction,
	changePortAction,
	changeRoomAction,
	changeRoomNameAction,
	changeRoomsAction,
	deactivateModuleAction,
	placeNewIconAction,
	removeIconAction,
	removeModuleAction,
	saveModuleAction
} from '../state/actions/contextAction'
import {
	changeTimerAction,
	changeTimerActiveAction,
	changeTimerDaysAction,
	changeTimerTimeAction,
	removeTimerAction
} from '../state/actions/timersActions'

const removeNullsAndKeys = (obj, ...keys) => {
	const object = {...obj}
	const keysToRemove = [...Object.keys(object).filter(key => object[key] == null), ...keys]
	keysToRemove.forEach(key => delete object[key])
	return object
}

const trimBulb = bulb => removeNullsAndKeys(bulb, 'roomIndex', 'moduleIndex')
const trimPort = port => removeNullsAndKeys(port, 'moduleIndex')

let savingRoomsAndPorts = false
const saveRoomsAndPortsAction = async (store) => {
	if (savingRoomsAndPorts) {
		return
	}
	savingRoomsAndPorts = true
	await new Promise(resolve => setTimeout(resolve, 800))
	const {control} = store.getState()
	const {controls, portMap} = control
	const rooms = control.rooms.map(({name, configurationIndex}, index) => {
		const bulbs = controls
			.filter(({roomIndex}) => roomIndex === index)
			.map(trimBulb)
		return {name, configurationIndex, bulbs}
	})
	const ports = Object.values(portMap).map(trimPort)
	sendAction('configuration', {rooms, ports})
	savingRoomsAndPorts = false
}

let savingTimers = false
const saveTimersAction = async (store) => {
	if (savingTimers) {
		return
	}
	savingTimers = true
	await new Promise(resolve => setTimeout(resolve, 800))
	const {control} = store.getState()
	const timers = control.timers.map(({name, configuration, commands, active}) =>
		({name, configuration, commands, active})
	)
	sendAction('configuration', {timers})
	savingTimers = false
}

export function initConfigurationLogic(store) {
	const change = [
		changeRoomNameAction, changeRoomAction, changeRoomsAction, addRoomAction, placeNewIconAction, removeIconAction,
		changeIconAction, changePortAction
	]
	registerCallAction(change, async () => await saveRoomsAndPortsAction(store))

	const timersChange = [
		changeTimerAction, changeTimerTimeAction, changeTimerDaysAction,
		changeTimerActiveAction, removeTimerAction
	]
	registerCallAction(timersChange, async () => await saveTimersAction(store))

	registerCallAction(activateModuleAction, (action) => sendAction('module', {activateModuleIndex: action.moduleIndex}))

	registerCallAction(deactivateModuleAction, (action) => sendAction('module', {deactivateModuleIndex: action.moduleIndex}))

	registerCallAction(removeModuleAction, (action) => sendAction('configuration', {removeModuleIndex: action.moduleIndex}))

	registerCallAction(addModuleAction, (action) => sendAction('configuration', {newModule: action.module}))

	registerCallAction(saveModuleAction, (action) => sendAction('configuration', {saveModule: {module: action.module, index: action.index}}))
}
