import React from 'react'
import './topPanel.scss'
import {Buttons} from './Buttons'

export const defaultHeight = 48
export const paddingTop = defaultHeight + 5

export const TopPanel = ({buttons, height = defaultHeight, children}) =>
	<div className="TopPanel" style={{height: `${height}px`}}>
		<Buttons buttons={buttons}/>
		{children}
	</div>
