import React from 'react'
import {finishEvent} from '../../../custom/eventUtils'

// const keys = "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday".split(',')
const TABLE = [
	"MON,TUE,WED,THU,FRI".split(','),
	"SAT,SUN".split(',')
]
const DAY_NAMES = {
	MON: 'monday',
	TUE: 'tuesday',
	WED: 'wednesday',
	THU: 'thursday',
	FRI: 'friday',
	SAT: 'saturday',
	SUN: 'sunday'
}
const KEYS = "monday,tuesday,wednesday,thursday,friday,saturday,sunday".split(',')
const DAYS = "MON,TUE,WED,THU,FRI,SAT,SUN".split(',')

const handleClick = (e, toSet, value, onChange) => {
	finishEvent(e)
	if (onChange) {
		const days = value || defaultValue()
		let newValue
		newValue = {...value}
		for (const i in KEYS) {
			newValue[KEYS[i]] = toSet === true
		}
		onChange(newValue)
	}
}
const handleDayClick = (e, key, value, onChange) => {
	finishEvent(e)
	if (onChange) {
		const days = value || defaultValue()
		const day = days[key] !== true
		onChange({
			...value,
			[key]: day
		})
	}
}

const defaultValue = () => KEYS.reduce((value, key) => {value[key] = false;return value;}, {})

export const Week = ({value, active, onChange}) => {
	const days = value || defaultValue()

	return <div className={`m-week ${active ? 'm-week-active' : ''}`}>
		<table>
			<thead>
			<tr>
				<td className={`clickable head ${days[DAY_NAMES.MON] ? 'selected' : 'available'}`}
						onClick={e => handleDayClick(e, DAY_NAMES.MON, value, onChange)}>MON
				</td>
				<td className={`clickable head ${days[DAY_NAMES.TUE] ? 'selected' : 'available'}`}
						onClick={e => handleDayClick(e, DAY_NAMES.TUE, value, onChange)}>TUE
				</td>
				<td className={`clickable head ${days[DAY_NAMES.WED] ? 'selected' : 'available'}`}
						onClick={e => handleDayClick(e, DAY_NAMES.WED, value, onChange)}>WED
				</td>
				<td className={`clickable head ${days[DAY_NAMES.THU] ? 'selected' : 'available'}`}
						onClick={e => handleDayClick(e, DAY_NAMES.THU, value, onChange)}>THU
				</td>
				<td className={`clickable head ${days[DAY_NAMES.FRI] ? 'selected' : 'available'}`}
						onClick={e => handleDayClick(e, DAY_NAMES.FRI, value, onChange)}>FRI
				</td>
			</tr>
			<tr>
				<td className={`clickable head ${days[DAY_NAMES.SAT] ? 'selected' : 'available'}`}
						onClick={e => handleDayClick(e, DAY_NAMES.SAT, value, onChange)}>SAT
				</td>
				<td className={`clickable head ${days[DAY_NAMES.SUN] ? 'selected' : 'available'}`}
						onClick={e => handleDayClick(e, DAY_NAMES.SUN, value, onChange)}>SUN
				</td>
				<td></td>
				<td className="clickable"
						onClick={e => handleClick(e, true, value, onChange)}>all
				</td>
				<td className="clickable"
						onClick={e => handleClick(e, false, value, onChange)}>none
				</td>
			</tr>
			</thead>
		</table>
	</div>
}
