import React from 'react'
import {changeForm, doLogin} from '../state/actions/loginAction'
import {finishEvent} from '../custom/eventUtils'
import {useDispatch, useSelector} from 'react-redux'
import {metaNameSelector} from '../state/reducers/meta'
import {authenticatedUserSelector} from '../state/reducers/login.reducer'
import {eventValue} from './inputUtils'

export const Login = () => {
	const dispatch = useDispatch()
	const formName = useSelector(metaNameSelector)
	const {loginDisabled, wrongPassword, username, password, rememberMe, name} = useSelector(authenticatedUserSelector)

	return <div className="login-form">
		<form onSubmit={(e) => {
			finishEvent(e)
			dispatch(doLogin(username, password, rememberMe, name))
		}}>
			<h1 className="login">{formName} login</h1>
			<div className="form-group ">
				<input type="text" name="username" value={username} className="form-control" placeholder="Username"
							 autoComplete="username"
							 onChange={eventValue(username => dispatch(changeForm('username', username)))}
				/>
				<i className="fa-regular fa-user"/>
			</div>
			<div className={"form-group log-status" + (wrongPassword ? " wrong-entry" : "")}>
				<input type="password" name="password" value={password} className="form-control" placeholder="Password"
							 autoComplete="current-password"
							 onChange={eventValue(password => dispatch(changeForm('password', password)))}
				/>
				<i className="fa-regular fa-lock"/>
			</div>
			<div className="form-group">
				<div className="form-row">
					<label className="link">
						<input type="checkbox" name="rememberMe" checked={rememberMe}
									 onChange={() => dispatch(changeForm('rememberMe', !rememberMe))}/>Remember me</label>
				</div>
			</div>
			{rememberMe ?
				<div className="form-group ">
					<input type="text" name="name" value={name} className="form-control" placeholder="Name of this computer"
								 onChange={eventValue(name => dispatch(changeForm('name', name)))}
					/>
					<i className="fa-regular fa-display"/>
				</div>
				: null
			}
			{wrongPassword
				? <span className="alert">Invalid Credentials</span>
				: null
			}
			<input type="submit" className="log-btn" disabled={loginDisabled} value="Log in"/>
		</form>
	</div>
}
