import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {roomsSelector} from '../../state/reducers/control'
import {addRoom} from '../../state/actions/contextAction'
import {onlyHide, openModal, StringValue} from './context/Modal'
import './roomSelectBox.scss'

export const RoomSelectBox = ({selectedRoom, onClick}) => {
	const dispatch = useDispatch()
	const rooms = useSelector(roomsSelector)
	const hasSelectedRoom = selectedRoom != null && selectedRoom >= 0 ? true : null

	const r = hasSelectedRoom ? rooms[selectedRoom] : null

	console.log("-> render RoomSelectBox.js", {selectedRoom, r, hasSelectedRoom})
	const addNewRoom = () => openModal(StringValue, {title: "New room name", okIcon: "fa-plus"})
		.then(name => dispatch(addRoom(rooms.length, name)))
		.catch(onlyHide)

	return <span className="roomSelectBox">
		{rooms.map(({name}, roomIndex) => {
			const selected = selectedRoom === roomIndex
			return <span key={name} className={`${selected ? 'sel' : ''} clickable`}
									onClick={() => onClick(roomIndex, selected, name)}>{name}</span>
		})}
		<span title="Add new room" className="fa-regular fa-plus clickable" onClick={addNewRoom}/>
	</span>
}
