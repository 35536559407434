import React from 'react'
import {finishEvent} from '../../../custom/eventUtils'
import {FormAction, onlyHide, openModal, registerModal, UserAction} from './Modal'
import {autoLoginForm, passChangeForm} from '../../forms/forms'
import {sendSetUserAutoLoginAction, sendSetUserPasswordAction} from '../../../custom/serverApi'
import {Field} from './ModalForm'

registerModal(UserAction, ({resolve, selected = {}}) => {
	const {user} = selected

	console.log("-> render ModalUserAction.js", {selected})

	return <div className="centerDiv" onClick={finishEvent}>
		<h1>{`User ${user.name}`}</h1>
		<div className="smallForm">
			<Field field="lastLogin" value={new Date(user.lastLogin).toUTCString()} title="Last login"/>
			<Field field="autoLoginIpAddress" value={user.autoLoginIpAddress} title="Autologin"/>
		</div>
		<div className="smallForm">
			<button title="Change password" onClick={
				() => openModal(FormAction, {
					title: `Change password for ${user.username}`,
					form: passChangeForm,
					requireChange: true,
					value: user
				})
					.then(sendSetUserPasswordAction)
					.then(() => resolve())
					.catch(onlyHide)
			}>
				<span className="fa-regular fa-key"/> Change password
			</button>
			<button title="Autologin user" onClick={
				() => openModal(FormAction, {
					title: `Autologin for ${user.username}`,
					form: autoLoginForm,
					requireChange: true,
					value: user
				})
					.then(sendSetUserAutoLoginAction)
					.then(() => resolve())
					.catch(onlyHide)
			}>
				<span className="fa-regular fa-key"/> Autologin
			</button>
			<button onClick={() => resolve()}><span className="fa-regular fa-check"/> OK</button>
		</div>
	</div>
})
