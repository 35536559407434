import React, {useState} from 'react'
import {finishEvent} from '../../../custom/eventUtils'
import './modal.scss'

export const ControlSelect = "ControlSelect"
export const ModuleSelect = "ModuleSelect"
export const RoomSelect = "RoomSelect"
export const ControlPositionSelect = "ControlPositionSelect"
export const IconSelect = "IconSelect"
export const StringValue = "StringValue"
export const ConfirmAction = "ConfirmAction"
export const FormAction = "FormAction"
export const ModuleAction = "NewModuleAction"
export const UserAction = "UserAction"
export const UnitValue = "UnitValue"
export const EditIconAction = "EditIconAction"
export const EditTimerAction = "EditTimerAction"
export const EditRoomAction = "EditRoomAction"
export const EditPortValue = "EditPortValue"
export const EditBlockAction = "EditBlockAction"
export const EditEspHomeAction = "EditEspHomeAction"

const types = {}
export const registerModal = (type, Element) => types[type] = Element

const modal = {instances: []}
export const onlyHide = () => {
}
export const openModal = (type, selected) => new Promise((resolve, reject) => {
	if (types[type] && modal.open) modal.open({type, selected, resolve, reject})
})

const createResolveFunction = (resolve, reject, closeLastModal) => value => {
	closeLastModal()
	if (value !== undefined) resolve(value)
	else reject()
}

const ModalItem = ({type, selected, resolve, reject, closeLastModal}) => {
	const Element = types[type]

	const finish = createResolveFunction(resolve, reject, closeLastModal)

	return (
		<div className="fullModal" onClick={(e) => {
			finishEvent(e)
			finish()
		}}>
			<Element resolve={finish} selected={selected}/>
		</div>
	)
}

export const Modal = () => {
	const [modals, setModals] = useState([])
	modal.open = (item) => setModals([...modals, item])

	console.log("-> render Modal.js", {modals})

	if (!modals.length) return null

	const closeLastModal = () => {
		const newModals = [...modals]
		newModals.pop()
		setModals(newModals)
	}

	return <>
		{modals.map(({type, selected, resolve, reject}, i) => <ModalItem
			key={`${i} ${type}`} type={type} selected={selected}
			resolve={resolve} reject={reject} closeLastModal={closeLastModal}/>)}
	</>
}
