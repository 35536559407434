import {
	receivedAllLogsAction,
	receivedNewLogsAction,
	toggleDebugAction,
	toggleScrollingAction,
	toggleTimeAction
} from '../actions/application'

const initialState = {
	logs: [],
	keepScrolling: true,
	debug: false,
	time: false,
	users: [],
}

export default function maintenance(state = initialState, action = {}) {
	switch (action.type) {
		case receivedAllLogsAction:
			return {
				...state,
				logs: action.logs
			}
		case receivedNewLogsAction:
			action.logs.forEach(message => console.log(message))
			return {
				...state,
				logs: [...state.logs, ...action.logs]
			}
		case toggleScrollingAction:
			return {
				...state,
				keepScrolling: !state.keepScrolling
			}
		case toggleDebugAction:
			return {
				...state,
				debug: !state.debug
			}
		case toggleTimeAction:
			return {
				...state,
				time: !state.time
			}
		case "users":
			return {
				...state,
				users: action.users
			}
		default:
			return state
	}
}

export const keepScrollingSelector = state => state.maintenance.keepScrolling
export const debugSelector = state => state.maintenance.debug
export const timeSelector = state => state.maintenance.time
export const usersSelector = state => state.maintenance.users
export const logsSelector = state => state.maintenance.logs
