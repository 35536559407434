function newLine(i, line) {
    let t = "\n", l = 0
    while (l < i && line.charAt(l) === ' ') l++
    while (l-- > 0) t += ' '
    return t
}

function addColon(s) {
    if (!s || s.length === 0) return ";"
    if (s.charAt(s.length - 1) === ";") return s
    return s + ";"
}

function fnc(row, start, end, parameters, key, line) {
    const firstLetter = key.substr(0, 1)
    const lineSeparator = newLine(start, line),
        isAnonymous = firstLetter === "a",
        prefix = !isAnonymous && parameters.length > 0 ? (firstLetter === "c" ? "const ": "")+parameters[0] +" = " : "",
        attributes = parameters.length > (isAnonymous ? 0 : 1) ? (isAnonymous ? parameters : parameters.slice(1)).join(', ') : "",
        fin = line.charAt(end) !== ')' ? lineSeparator : ""
    if (isAnonymous) {
        return {
            text: prefix + "(" + attributes + ") => ;",
            cursor: {row: row, column: start + prefix.length+6+attributes.length}
        }
    }
    return {
        text: prefix + "(" + attributes + ") => {" + lineSeparator + "  " + lineSeparator + "  return"+ lineSeparator + "}"+fin,
        cursor: {row: row+1, column: start + 2}
    }
}

function iter(row, start, end, parameters, key, line) {
    const lineSeparator = newLine(start, line)
    let moveToRow = row
    let moveToColumn = start
    let content = "    "
    let block
    if (parameters.length === 0) {
        block = ";;"
        content = ""
        moveToColumn += 5
    } else {
        let variable = parameters.length === 1 ? 'i' : parameters[0],
            isArr = parameters[1] !== "in",
            iterator = parameters.length !== 1 ? isArr ? parameters[1] : parameters[2] : parameters[0],
            val = parameters[isArr ? 2 : 3]
        if (!val && isArr && iterator.length > 1 && iterator.charAt(iterator.length - 1) === 's') val = iterator.substr(0, iterator.length - 1)
        block = isArr
            ? "let " + variable + "=0;" + variable + "<" + iterator + ".length;i++"
            : "let " + variable + " in " + iterator
        content += (val ? 'var ' + val + " = " : "") + iterator + "[" + (isArr ? "" : "''+") + variable + "];"
        moveToRow++
        moveToColumn += 4
        if (val) {
            content += lineSeparator + "    "
            moveToRow++
        }
    }
    return {
        text: "for (" + block + ") {" + lineSeparator + content + lineSeparator + "}" + lineSeparator,
        cursor: {row: moveToRow, column: moveToColumn}
    }
}

function ifKey(row, start, end, parameters, key, line) {
    let lineSeparator = newLine(start, line),
        moveToRow = row,
        moveToColumn = start,
        isIffe = key === "iffe",
        doElse = key === "ife" || isIffe,
        condition = parameters[0], elseContent, content = elseContent = "    ",
        main = parameters[1], varia = parameters[2]
    moveToColumn += 4
    if (isIffe || key === "iff") {
        if (!condition) condition = "callBack"
        varia = main
        main = condition + "()"
        if (isIffe) {
            if (varia) {
                moveToRow += 4
                moveToColumn -= 8 + main.length
            } else {
                moveToColumn -= main.length
                moveToRow += 2
            }
        } else {
            moveToRow += 2
            moveToColumn -= 4 + main.length
        }
    }
    if (condition) {
        moveToRow++
        if (main) {
            content += addColon(main)
            moveToColumn += main.length
        }
        if (varia) {
            elseContent += addColon(varia)
        }
    } else {
        condition = ""
    }
    return {
        text: "if (" + condition + ") {" + lineSeparator + content + lineSeparator + "}" + (doElse ? " else {" + lineSeparator + elseContent + lineSeparator + "}" : "") + lineSeparator,
        cursor: {row: moveToRow, column: moveToColumn}
    }

}

function mcb(row, start, end, parameters, key, line) {
    let lineSeparator = newLine(start, line),
        name = parameters.join(" ")
    return {
        text: "customBlock(\"" + name + "\", \"type\", {}, () => {" + lineSeparator + "    " + lineSeparator + "});" + lineSeparator,
        cursor: {row: row + 1, column: start + 4}
    }
}

function msb(row, start, end, parameters, key, line) {
    let lineSeparator = newLine(start, line),
        input = [], output1, output2, name = []
    for (let i = 0; i < parameters.length; i++) {
        let parameter = parameters[i], c = parameter.substr(0,2)
        if (c === 'di') {
            input.push(parameter)
        } else if (c === 'do') {
            if (!output1) output1 = parameter
            else if (!output2) output2 = parameter
        } else name.push(parameter)
    }
    if (!output1) output1 = "do"
    if (!output2) output2 = "null"
    return {
        text: "switchBlock(\""+(name.join(' '))+"\", " + (input.length > 0 ? input.length > 1 ? '[' + input.join(", ") + ']' : input[0] : "di") + ", " + output1 + ", " + output2 + ");" + lineSeparator,
        cursor: {row: row + 2, column: start}
    }
}

function cb(row, start, end, parameters, key, line) {
    return {
        text: "callBack",
        cursor: {row: row, column: start + 8}
    }
}

function t(row, start, end, parameters, key, line) {
    let value
    if (!parameters.length) value = ""
    else {
        try {
            let ms = 0
            for (let l = 0; l < parameters.length; l++) {
                var vs = parameters[l].split('+')
                for (let k = 0; k < vs.length; k++) {
                    let v = vs[k], num = "", t = ""
                    for (let i = 0; i < v.length; i++) {
                        let c = v.charAt(i)
                        if (c >= '0' && c <= '9') num += c
                        else if (c === '.' || c === ',') num += '.'
                        else if (c >= 'a' && c <= 'z') t += c
                        else {
                            throw Error("unknown '" + c + "' in " + v)
                        }
                    }
                    if (t === 'm') ms += Math.round(num * 60000)
                    else if (t === 'ms') ms += Math.round(+num)
                    else if (t === 's') ms += Math.round(num * 1000)
                    else if (t === 'h') ms += Math.round(num * 3600000)
                    else if (t === 'd') ms += Math.round(num * 86400000)
                    else if (t === 'w') ms += Math.round(num * 604800000)
										else throw Error("unknown time type '" + t + "' in '" + v + "' use e.g. " + num + "ms (use ms, m, s, h, d or w)")
                }
            }
            value = "/*" + parameters.join('+') + "=*/" + ms
        } catch (e) {
            value = "" + e
        }
    }
    return {
        text: value,
        cursor: {row: row, column: start + value.length}
    }
}

function g(row, start, end, parameters, key, line) {
    let value = parameters[0], column = start + 6
    if (!value) {
        value = ""
    } else {
        let c = value.charAt(0)
        if (c >= '0' && c <= '9') {
            value = "di" + (c > "0" ? "0" : "") + value
        }
        column += value.length + 1
    }
    return {
        text: "getIt(" + value + ")",
        cursor: {row: row, column: column}
    }
}

function sw(row, start, end, parameters, key, line) {
    let value = parameters[0],
        method = key !== "sw" ? key !== "su" ? key !== "sd" ? key !== "sc"
            ? ""
            : "changeIt"
            : "setDown"
            : "setUp"
            : "switchIt",
        column = start + 9
    if (!value) {
        value = ""
    } else {
        let c = value.charAt(0)
        if (c >= '0' && c <= '9') {
            value = "do" + (c > "0" ? "0" : "") + value
        }
        column += value.length + 2

        if (key === "sc") {
            value += ", "
            let extra = parameters[1]
            if (extra) {
                value += extra
                column += extra.length + 2
            }
        }

    }
    return {
        text: method + "(" + value + ");",
        cursor: {row: row, column: column}
    }
}

function ou(row, start, end, parameters, key, line) {
    let lineSeparator = newLine(start, line),
        method = key !== "ou" ? key !== "od" ? key !== "oc"
            ? ""
            : "onChange"
            : "onDown"
            : "onUp",
        moveToRow = row,
        moveToColumn = start,
        name = parameters[0],
        content = parameters[1]
    if (!name) {
        name = ""
    } else {
        let c = name.charAt(0)
        if (c >= '0' && c <= '9') {
            name = "di" + (c > "0" ? "0" : "") + name
        }
    }
    if (content) {
        content = addColon(content)
        moveToRow += 3
    } else {
        content = ""
        moveToRow++
        moveToColumn += 4
    }
    return {
        text: method + "(" + name + ", function () {" + lineSeparator + "    " + content + lineSeparator + "});" + lineSeparator,
        cursor: {row: moveToRow, column: moveToColumn}
    }
}

function dg(row, start, end, parameters, key, line) {
    let lineSeparator = newLine(start, line),
        moveToRow = row,
        moveToColumn = start,
        name = parameters[0],
        input = []
    for (let i = 1; i < parameters.length; i++) {
        let parameter = parameters[i], c = parameter.charAt(0)
        if (c >= '0' && c <= '9') {
            parameter = "di" + (c > "0" ? "0" : "") + parameter
        }
        input.push(parameter)
    }
    if (!name) {
        name = ""
			moveToColumn += 13
		} else {
			moveToRow += 2
		}
	return {
		text: "defineGroup(\"" + name + "\", [" + input.join(", ") + "]);" + lineSeparator,
		cursor: {row: moveToRow, column: moveToColumn}
	}
}

const templates = {
	"t": t,
	"dg": dg,
	"g": g,
	"sc": sw,
	"sw": sw,
	"su": sw,
	"sd": sw,
	"ou": ou,
	"od": ou,
	"oc": ou,
	"cb": cb,
	"cub": mcb,
	"swb": msb,
	"fun": fnc,
	"func": fnc,
	"cfun": fnc,
	"cfunc": fnc,
	"afun": fnc,
	"afunc": fnc,
	"if": ifKey,
	"iff": ifKey,
	"ife": ifKey,
	"iffe": ifKey,
	"iter": iter
}

export default templates
