import {serverConnectionAction} from '../actions/application'
import {switchMenuType} from '../actions/menuAction'

const initialState = {
	open: false,
	offline: true
}

export default function menu(state = initialState, action = {}) {
	switch (action.type) {
		case serverConnectionAction:
			return {
				...state,
				offline: !action.connected
			}
		case switchMenuType:
			return {
				...state,
				open: action.open
			}
		default:
			return state
	}
}

export const offlineSelector = state => state.menu.offline
export const openMenuSelector = state => state.menu.open
