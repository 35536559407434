export const VISUAL = "Visual"
export const CONTROL = "Control"
export const TIMERS = "Timers"
export const SETTINGS = "Settings"
export const MESSAGES = "Messages"
export const MODULES = "Modules"
export const MAINTENANCE = "Maintenance"
export const USER = "User"

export const contentItems = [
	{name: VISUAL, icon: "fa-compass"},
	{name: CONTROL, icon: "fa-braille"},
	{name: TIMERS, icon: "fa-clock"},
	{name: USER, icon: "fa-user"},
	{name: MODULES, icon: "fa-cubes"},
	{name: MAINTENANCE, icon: "fa-bug"},
	{name: MESSAGES, icon: "fa-message"},
	{name: SETTINGS, icon: "fa-cogs"}
]
