import React from 'react'
import {usePortValue} from '../../custom/portValues'
import {sendCommands} from '../../custom/serverApi'
import {defaultIcons} from '../../shared/faConstants'
import {onOffAuto} from '../../shared/portConstants'
import {finishEvent} from '../../custom/eventUtils'
import {editPortValue} from '../../features/edit'

const Item = ({value, action, type}) =>
	<span className={`clickable ${onOffAuto[type]} ${value === type ? 'sel' : ''}`}
				onClick={action(type)}>{onOffAuto[type]}</span>

export const OnOffAuto = ({style, valueKey: key, onClick, icon, selected}) => {
	const value = usePortValue(key)

	console.log("-> render OnOffAuto.js", {key, value})

	const action = (value) => e => finishEvent(e, () => sendCommands(`${key}:${value}`))

	return <div
		className={`valueSelector ${selected ? 'sel' : ''} ${onClick ? 'clickable' : ''}`}
		style={style}
		onClick={onClick}>
		<span onClick={e => finishEvent(e, () => editPortValue(key))} key="icon"
					className={icon || defaultIcons.onOffAuto}/>
		<span className="buttons">
			<Item value={value} action={action} type={onOffAuto.on}/>
			<Item value={value} action={action} type={onOffAuto.auto}/>
			<Item value={value} action={action} type={onOffAuto.off}/>
		</span>
	</div>
}
