import React from 'react'
import {useSelector} from 'react-redux'
import {Visual} from './content/Visual'
import {Modules} from './content/Modules'
import {Control} from './content/Control'
import {Settings} from './content/Settings'
import {Maintenance} from './content/Maintenance'
import {User} from './content/User'
import {Timers} from './content/Timers'
import {CONTROL, MAINTENANCE, MESSAGES, MODULES, SETTINGS, TIMERS, USER, VISUAL} from './items'
import {selectedMenuSelector} from '../state/reducers/visual'

const items = {
	[VISUAL]: <Visual/>,
	[MODULES]: <Modules/>,
	[CONTROL]: <Control/>,
	[SETTINGS]: <Settings/>,
	[MESSAGES]: null,
	[MAINTENANCE]: <Maintenance/>,
	[TIMERS]: <Timers/>,
	[USER]: <User/>
}

export const Switcher = () => {
	const selected = useSelector(selectedMenuSelector)
	console.log("-> render Switcher.js", {selected})

	return items[selected] || <span>{selected} is not implemented yet</span>
}
