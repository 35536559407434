import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import AceEditor from 'react-ace'
import liveTemplates from '../../custom/liveTemplates'
import 'brace/mode/javascript'
import 'brace/theme/tomorrow'
import 'brace/ext/language_tools'
import brace from 'brace'
import {editCode} from '../../state/actions/codeActions'
import {isDigital, isInput} from '../../shared/portUtils'
import {portMapSelector} from '../../state/reducers/control'
import {blocksSelector, blockTypesSelector, codeSelector, editedCodeSelector} from '../../state/reducers/code'
import {defaultHeight, TopPanel} from '../context/TopPanel'
import {sendAction} from '../../custom/serverApi'
import {ControlSelect, EditEspHomeAction, onlyHide, openModal} from '../reusable/context/Modal'
import {Block} from "../reusable/Block";
import {
	espHomeInstanceSelector,
	espHomeInstancesMapSelector,
	espHomeInstancesSelector,
	espHomeYamlMapSelector,
	tabSelector
} from "../../state/reducers/settings";
import {BLOCKS, CODE, ESPHOME, selectEspHomeInstanceAction, setTabAction} from "../../state/actions/settingsActions";

const {Tooltip} = brace.acequire("ace/tooltip")

function getKeywords(s, i) {
	//TODO support for String (is in string)
	let result = ""
	const keywords = []
	while (i-- > 0) {
		const c = s.charAt(i)
		if (c === ' ') {
			if (result.length) {
				keywords.push({key: result, column: i + 1})
			}
			result = ""
		} else {
			result = c + result
		}
	}
	if (result.length) {
		keywords.push({key: result, column: i + 1})
	}
	return keywords
}

const keywordsToParameters = (keywords, i) => {
	const parameters = []
	while (i-- > 0) {
		parameters.push(keywords[i].key)
	}
	return parameters
}


function getActualCompletions(portMap) {
	const cs = {
		"customBlock": "name, type, {inputs:[],outputs:[]}, () => {}",
		"switchBlock": "name, input, output1, output2",
		"onChange": "port, () => {}",
		"onUp": "port, () => {}",
		"onDown": "port, () => {}",
		"changeIt": "port, value",
		"setUp": "port",
		"setDown": "port",
		"switchIt": "port",
		"getIt": "port",
		"defineGroup": "name, [ports]",
		"log": "...",
		"runAfter": "interval, () => {}",
		"runEvery": "interval, () => {}"
	}
	const arr = []
	Object.keys(cs).forEach(method => {
		//TODO add definitions
		arr.push({name: method, value: method, meta: `${method}(${cs[method]})`, score: 10000})
	})
	Object.keys(portMap).forEach(key => {
		pushTo(arr, portMap[key], `${isDigital(key) ? 'digital' : 'analog'} ${isInput(key) ? 'input' : 'output'}`)
	})
	return arr
}

function pushTo(arr, port, type) {
	const {key} = port
	arr.push({name: key, value: key, meta: `'${port.name}' ${type}`, score: 10000, key})
}

export const commands = [{
	name: "Delete line",
	bindKey: {win: "Ctrl-Y", mac: "Command-Y"},
	exec: () => console.log("delete line")
}, {
	name: "Delete line",
	bindKey: {win: "Ctrl-D", mac: "Command-D"},
	exec: () => console.log("duplicate line or selected text")
}
]

const options = {
	enableBasicAutocompletion: true,
	enableLiveAutocompletion: false,
	enableSnippets: false,
	showLineNumbers: true,
	tabSize: 2
}

const Program = () => {
	const dispatch = useDispatch()
	const portMap = useSelector(portMapSelector)
	const code = useSelector(codeSelector)
	const editedCode = useSelector(editedCodeSelector)
	const [{session, editor}, setState] = useState({})

	let program
	const onLoad = (editor) => {
		console.log('on load editor')
		const completions = getActualCompletions(portMap)
		const completionsMap = completions.reduce((map, completion) => {
			map[completion.value] = completion
			return map
		}, {})

		const session = editor.getSession()
		editor.keyBinding.origOnTextInput = editor.keyBinding.onTextInput
		editor.keyBinding.onTextInput = (text) => {
			let moveCursor
			if (text === "\n") {
				let cursor = editor.getCursorPosition(), line = session.getLine(cursor.row),
					keywords = getKeywords(line, cursor.column)
				for (let i = 0; i < keywords.length; i++) {
					let keyword = keywords[i]
					let keywordHandler = liveTemplates[keyword.key]
					if (keywordHandler) {
						let result = keywordHandler(cursor.row, keyword.column, cursor.column, keywordsToParameters(keywords, i), keyword.key, line)
						if (result) {
							session.getDocument().removeInLine(cursor.row, keyword.column, cursor.column)
							if (result.text) text = result.text
							if (result.cursor) moveCursor = result.cursor
							break
						}
					}
				}
			}
			editor.keyBinding.origOnTextInput(text)
			if (moveCursor) editor.moveCursorTo(moveCursor.row, moveCursor.column)
		}
		let tooltip = new Tooltip(editor.container)

		const annotations = []
		if (program) {
			const {context: {warnings}} = program
			if (warnings && warnings.length) {
				warnings.forEach(({lines, text}) => lines.forEach(({row}) => {
					annotations.push({
						type: "error",// warning info
						row,
						// column: 5,
						text
					})
				}))
			}
		}

		session.setAnnotations(annotations)
		session.selection.on("changeCursor", () => {
			let cursor = editor.getCursorPosition(), token = session.getTokenAt(cursor.row, cursor.column)
			if (program) {
				const {lines, blocks} = program
				const {row, column} = cursor
				const {start} = lines[row] || {}
				const charAt = start + column
				const block = blocks.find(({start, end}) => start <= charAt && end >= charAt)
				if (block) {
					console.log('selecting block', {block})
				}

				console.log("cursor at " + charAt, block)
			}
			let completion = token ? completionsMap[token.value] : null
			if (completion) {
				console.log("code", completion)
				let renderer = editor.renderer
				let lineHeight = renderer.layerConfig.lineHeight

				let pos = renderer.$cursorLayer.getPixelPosition(cursor, true)

				let rect = editor.container.getBoundingClientRect()
				pos.top += rect.top - renderer.layerConfig.offset - 2 * lineHeight
				pos.left += rect.left - editor.renderer.scrollLeft
				pos.left += renderer.$gutterLayer.gutterWidth
				if (pos.top < 0) pos.top += 4 * lineHeight
				tooltip.show(completion.meta, pos.left, pos.top)
				if (completion.key) {
					const port = portMap[completion.key]
					if (port) {
						console.log('found port', port)
					}
				}
			} else {
				tooltip.hide()
			}
			session.setAnnotations(annotations)
		})

		editor.completers.push({
			getCompletions: (editor, session, pos, prefix, callback) => callback(null, completions)
		})
		setState({session, editor})
	}

	const onChange = (text) => {
		dispatch(editCode(text))
		/*
				session.setAnnotations([
					{
						type: "error",// warning info
						row: 12,
						column: 5,
						text: "the error"
					}
				])
		*/
	}

	return <div className="" style={{width: "100%", height: `calc(100% - ${defaultHeight}px)`}}>
		<AceEditor
			mode="javascript"
			theme="tomorrow"
			name="code"
			onLoad={onLoad}
			onChange={onChange}
			fontSize={13}
			focus={true}
			showPrintMargin={true}
			showGutter={true}
			highlightActiveLine={true}
			width="100%"
			height="100%"
			value={editedCode || code}
			commands={commands}
			setOptions={options}/>
	</div>
}

const Blocks = () => {
	const blocks = useSelector(blocksSelector)
	const blockTypes = useSelector(blockTypesSelector)

	return <div className="blocks" style={{width: "100%", height: `calc(100% - ${defaultHeight}px)`}}>
		{blocks
			//.filter(block => block.type !== 'registerCustomBlock')
			.map((block, blockIndex) =>
				<Block key={blockIndex} block={block} blockType={blockTypes[block.type]} canEdit/>
			)}
	</div>
}
const EspHome = ({instance}) => {
	const espHomeYamlMap = useSelector(espHomeYamlMapSelector)
	if (!espHomeYamlMap) {
		sendAction('espHome', {action: "generate"})
	}
	console.log("-> render espHome", {instance, espHomeYamlMap})
	return <div className="" style={{width: "100%", height: `calc(100% - ${defaultHeight}px)`}}>
		<pre>
		{(instance && espHomeYamlMap && espHomeYamlMap[instance]) || null}
		</pre>
	</div>
}

export const Settings = () => {
	const dispatch = useDispatch()
	const tab = useSelector(tabSelector)
	const code = useSelector(codeSelector)
	const editedCode = useSelector(editedCodeSelector)
	const espHomeInstance = useSelector(espHomeInstanceSelector)
	const espHomeInstances = useSelector(espHomeInstancesSelector)
	const instancesMap = useSelector(espHomeInstancesMapSelector)
	const [{editor}, setState] = useState({})

	console.log("-> render Settings.js")

	const save = editedCode !== code ? () => sendAction('program', {action: "save", code: editedCode}) : null
	const insertPort = () => openModal(ControlSelect, {})
		.then(key => editor.insert(key))
		.catch(onlyHide)

	const buttons = [
		{
			title: 'Show blocks',
			onClick: () => dispatch(setTabAction(BLOCKS)),
			icon: 'fa-engine',
			label: "Blocks",
			selected: tab === BLOCKS
		},
		{
			title: 'Show Code',
			onClick: () => dispatch(setTabAction(CODE)),
			icon: 'fa-code',
			label: "Code",
			selected: tab === CODE
		},
		{
			title: 'Show EspHome',
			onClick: () => dispatch(setTabAction(ESPHOME)),
			icon: 'fa-microchip',
			label: "EspHome",
			selected: tab === ESPHOME
		}
	]
	if (tab === CODE) {
		buttons.push(
			{title: 'Save', onClick: save, icon: 'fa-floppy-disk', label: 'Save', selected: !!save},
			{title: 'Insert Port key', onClick: insertPort, icon: 'fa-plus', label: 'Port'})
	}
	if (tab === ESPHOME) {
		for (const instanceKey of espHomeInstances) {
			const selected = instanceKey === espHomeInstance
			buttons.push(
				{title: instanceKey, selected, onClick: () => dispatch(selectEspHomeInstanceAction(instanceKey))})

		}
		if (espHomeInstance) {
			buttons.push(
				{title: 'Save YAML', onClick: () => sendAction('espHome', {action: "generate", instance: espHomeInstance})})
			buttons.push(
				{
					title: 'Edit', onClick: () => {
						openModal(EditEspHomeAction, {instance: espHomeInstance, data: instancesMap[espHomeInstance]})
							.then((data) => data && sendAction('espHome', {action: "generate"}))
							.catch(onlyHide)
					}
				})
		}
		buttons.push(
			{
				title: 'Add new', onClick: () => {
					openModal(EditEspHomeAction, {espHomeInstances})
						.then((data) => data && sendAction('espHome', {action: "generate"}))
						.catch(onlyHide)
				}
			})
	}

	return <>
		<div style={{width: "100%", height: `${defaultHeight}px`}}>
			<TopPanel buttons={buttons}/>
		</div>
		{tab === CODE && <Program/>}
		{tab === BLOCKS && <Blocks/>}
		{tab === ESPHOME && <EspHome instance={espHomeInstance}/>}
	</>
}
