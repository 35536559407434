import {useEffect, useRef, useState} from 'react'
import {finishEvent} from '../../custom/eventUtils'

export const useDraggableContext = (initializer) => {
	const [reference] = useState({})
	if (!reference.context) {
		reference.context = initializer()
	}
	return reference.context
}

export const useDroppableRef = (draggableContext, id) => {
	const ref = useRef()
	const [localContext] = useState({})
	localContext.id = id
	useEffect(() => {
		const handleDragLeave = (event) => {
			finishEvent(event)
			if (draggableContext.dropping === localContext.id) {
				draggableContext.dragLeave(ref.current)
				draggableContext.dropping = null
				draggableContext.droppingRef = null
			}
		}
		const handleDragOver = (event) => {
			if (!draggableContext.canDrop(draggableContext.dragged, localContext.id)) return
			if (draggableContext.dropping !== localContext.id) {
				if (draggableContext.droppingRef) draggableContext.dragLeave(draggableContext.droppingRef.current)
				draggableContext.dragEnter(ref.current)
				draggableContext.dropping = localContext.id
				draggableContext.droppingRef = ref
			}
			finishEvent(event)
			return false
		}
		const handleDrop = (event) => {
			finishEvent(event)
			draggableContext.dragLeave(ref.current)
			draggableContext.drop(draggableContext.dragged, draggableContext.dropping)
		}
		const {current} = ref
		if (current) {
			current.addEventListener('dragleave', handleDragLeave)
			current.addEventListener('dragover', handleDragOver)
			current.addEventListener('drop', handleDrop)
		}
		return () => {
			const {current} = ref
			if (!current) return
			current.removeEventListener('dragleave', handleDragLeave)
			current.removeEventListener('dragover', handleDragOver)
			current.removeEventListener('drop', handleDrop)
		}
	})
	return ref
}

export const useDraggableRef = (draggableContext, id) => {
	const ref = useRef()
	const [localContext] = useState({})
	localContext.id = id
	useEffect(() => {
		const handleDragStart = () => draggableContext.dragged = localContext.id
		const handleDragEnd = () => draggableContext.dragged = null
		const {current} = ref
		if (current) {
			current.addEventListener('dragstart', handleDragStart)
			current.addEventListener('dragend', handleDragEnd)
		}
		return () => {
			const {current} = ref
			if (!current) return
			current.removeEventListener('dragstart', handleDragStart)
			current.removeEventListener('dragend', handleDragEnd)
		}
	})
	return ref
}

