import {onOffAuto} from './portConstants.js'

export const basicType = 'basic'
export const faSolid = 'fa-solid'
export const faBeatAndFade = 'fa-beat-fade'
export const faSpin = 'fa-spin'
export const faActive = 'faActive'
export const faHot = 'faHot'
export const faCold = 'faCold'

export const defaultIcons = {
  meter: 'fa-regular fa-meter',
  [onOffAuto.fieldName]: 'fa-regular fa-power-off',
  shade: 'fa-regular fa-sun',
  term: 'fa-regular fa-temperature-half',
  temp: 'fa-regular fa-temperature-half',
  thermostat: 'fa-regular fa-temperature-half'
}

export const basicIconClassNames = {
  none: ['fa-regular fa-toggle-off', 'fa-regular fa-toggle-on'],
  light: ['fa-regular fa-circle', 'fa-solid fa-circle'],
  athome: ['fa-regular fa-house-leave', 'fa-regular fa-house-person-arrive'],
  fan: ['fa-regular fa-fan', 'fa-regular fa-fan fa-spin faActive'],
  heat: ['fa-regular fa-heat', 'fa-regular fa-heat faHot fa-beat-fade'],
  night: ['fa-regular fa-house-day', 'fa-regular fa-house-night'],
  run: ['fa-solid fa-person', 'fa-solid fa-person-running'],
  door: ['fa-regular fa-door-closed', 'fa-regular fa-door-open'],
  lock: ['fa-regular fa-lock', 'fa-regular fa-lock-open'],
  outlet: ['fa-regular fa-outlet', 'fa-regular faActive fa-outlet']
}

export const lightKeysIcons = {
  hueKey: "fa-regular fa-palette",
  brightnessKey: "fa-regular fa-brightness",
  saturationKey: "fa-regular fa-adjust",
  temperatureKey: "fa-regular fa-sun",
  redKey: "fa-solid fa-circle redKey",
  greenKey: "fa-solid fa-circle greenKey",
  blueKey: "fa-solid fa-circle blueKey"
}

export const timeKeysIcons = {
  timeKey: "fa-regular fa-clock"
}

export const settingsKeysIcons = {
  typeKey: "fa-regular fa-gear"
}

export const iconKeys = {
  'Control': [
    'fa-square', 'fa-square-check', 'fa-circle', 'fa-stroopwafel', 'fa-clock', 'fa-heat', 'fa-fan', 'fa-fan-table',
    'fa-light-switch', 'fa-light-switch-on', 'fa-light-switch-off',
    'fa-traffic-light-stop', 'fa-traffic-light-slow', 'fa-traffic-light-go', 'fa-traffic-light',
    'fa-plug', 'fa-power-off', 'fa-toggle-on',
    'fa-toggle-off'
  ],
  'Light': [
    'fa-flashlight',
    'fa-lightbulb', 'fa-lightbulb-slash', 'fa-lightbulb-on', 'fa-lightbulb-exclamation-on', 'fa-lightbulb-exclamation',
    'fa-lightbulb-dollar', 'fa-light-ceiling',
    'fa-lamp-street', 'fa-lamp-floor', 'fa-lamp-desk', 'fa-brightness-low', 'fa-brightness', 'fa-loader'
  ],
  'Home appliance': [
    'fa-refrigerator', 'fa-oven', 'fa-washer', 'fa-dryer', 'fa-dryer-alt', 'fa-air-conditioner', 'fa-outlet',
    'fa-camera-web', 'fa-camera-web-slash', 'fa-projector', 'fa-camera-cctv',
    'fa-print', 'fa-print-slash', 'fa-television', 'fa-laptop', 'fa-fan',
    'fa-sink', 'fa-bath', 'fa-shower', 'fa-shower-alt',
    'fa-hot-tub-person', 'fa-booth-curtain',
    'fa-keyboard', 'fa-mobile', 'fa-cauldron', 'fa-tank-water',
    'fa-sprinkler', 'fa-sprinkler-ceiling',
    'fa-mug', 'fa-mug-hot', 'fa-coffee', 'fa-glass',
    'fa-door-open', 'fa-door-closed', 'fa-window-frame-open', 'fa-window-frame', 'fa-garage-open', 'fa-garage',
    'fa-store', 'fa-store-slash', 'fa-vault',
    'fa-bell', 'fa-bell-slash', 'fa-bell-on',
    'fa-pipe-valve', 'fa-faucet', 'fa-faucet-drip', 'fa-square-ring', 'fa-alarm-clock'
  ],
  'Action': [
    'fa-house', 'fa-house-user', 'fa-home-blank', 'fa-house-leave', 'fa-house-person-arrive', 'fa-person-shelter',
    'fa-house-flood',
    'fa-lock', 'fa-lock-open', 'fa-lock-hashtag', 'fa-lock-a'
  ],
  'Measuring': [
    'fa-temperature-low', 'fa-temperature-empty', 'fa-temperature-quarter', 'fa-temperature-half',
    'fa-temperature-three-quarters', 'fa-temperature-full', 'fa-temperature-hot', 'fa-temperature-frigid',
    'fa-temperature-arrow-up', 'fa-temperature-arrow-down',
    'fa-meter', 'fa-meter-fire', 'fa-meter-droplet', 'fa-meter-bolt',
    'fa-gauge-min', 'fa-gauge-low', 'fa-gauge', 'fa-gauge-high', 'fa-gauge-max',
    'fa-gauge-simple-min', 'fa-gauge-simple-low', 'fa-gauge-simple', 'fa-gauge-simple-high', 'fa-gauge-simple-max',
    'fa-battery-empty', 'fa-battery-1', 'fa-battery-2', 'fa-battery-half', 'fa-battery-4', 'fa-battery-full',
    'fa-battery-bolt', 'fa-battery-exclamation', 'fa-battery-slash',
    'fa-sensor', 'fa-sensor-on', 'fa-sensor-alert',
    'fa-sensor-fire', 'fa-radiation',
    'fa-volume-off', 'fa-volume-low', 'fa-volume', 'fa-volume-high',
    'fa-weight-scale', 'fa-sliders'
  ],
  'Weather': [
    'fa-sun', 'fa-sunset', 'fa-sunrise',
    'fa-moon', 'fa-house-day', 'fa-house-night',
    'fa-umbrella', 'fa-droplet', 'fa-droplet-slash', 'fa-dewpoint',
    'fa-snowflake', 'fa-sensor-cloud', 'fa-cloud-drizzle', 'fa-cloud-showers-heavy',
    'fa-cloud-fog', 'fa-sun-haze'

  ],
  'Vehicles': [
    'fa-train', 'fa-train-track', 'fa-bus', 'fa-car', 'fa-cab', 'fa-truck',
    'fa-ship', 'fa-bicycle', 'fa-motorcycle', 'fa-moped', 'fa-plane', 'fa-rocket',
    'fa-charging-station', 'fa-car-side-bolt', 'fa-rv', 'fa-van-shuttle',
    'fa-helicopter', 'fa-drone-alt'
  ],
  'Symbols': [
    'fa-qrcode', 'fa-trash', 'fa-tree', 'fa-recycle', 'fa-refresh', 'fa-shield', 'fa-sitemap',
    'fa-sort', 'fa-tag', 'fa-random', 'fa-retweet',
    'fa-fire',
    'fa-gear', 'fa-hotel', 'fa-hourglass', 'fa-inbox', 'fa-key', 'fa-leaf',
    'fa-warning', 'fa-wheelchair',
    'fa-location-arrow', 'fa-magnet', 'fa-magic', 'fa-map', 'fa-map-marker', 'fa-map-pin', 'fa-microphone',
    'fa-microphone-slash', 'fa-window-minimize',
    'fa-window-maximize', 'fa-adjust', 'fa-microchip', 'fa-envelope',
    'fa-bolt', 'fa-bolt-slash', 'fa-bluetooth', 'fa-bookmark', 'fa-navicon', 'fa-newspaper', 'fa-pencil', 'fa-paper-plane',
    'fa-paw', 'fa-percent', 'fa-image', 'fa-music', 'fa-video', 'fa-camera', 'fa-calendar',
    'fa-users', 'fa-heart', 'fa-child', 'fa-male', 'fa-female', 'fa-clone',
    'fa-comment', 'fa-crosshairs', 'fa-cutlery', 'fa-eye', 'fa-eye-slash', 'fa-film',
    'fa-flag',
    'fa-bahai', 'fa-wrench', 'fa-fingerprint',
    'fa-wifi-exclamation', 'fa-wifi', 'fa-feed',
    'fa-light-emergency', 'fa-light-emergency-on',
    'fa-toilet-paper', 'fa-toilet-paper-slash',
    'fa-engine', 'fa-car-battery', 'fa-water'
  ]
}

export const findBasicIconTypeByClassName = ([fistIcon, secondIcon] = []) => {
  for (const [type, [basicFirstIcon, basicSecondIcon]] of Object.entries(basicIconClassNames)) {
    if (fistIcon === basicFirstIcon && secondIcon === basicSecondIcon) return type
  }
  return undefined
}
