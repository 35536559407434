import React from 'react'
import {sendCommands} from '../../custom/serverApi'
import {usePortValue} from '../../custom/portValues'
import {finishEvent} from '../../custom/eventUtils'
import './basicIcon.scss'

export const basicIconClick = ({key}) => sendCommands(`${key}:s`)

export const createBasicIconOnClick = icon => e => {
	finishEvent(e)
	basicIconClick(icon)
}

export const BasicIcon = ({iconKey, name, style, icons, selected, onClick, component}) => {
	const value = usePortValue(iconKey)
	return <span className={`Control ${component}Control ${icons[value ? 1 : 0]} ${selected ? 'sel' : ''}`}
							 onClick={onClick}
							 style={style}
							 title={`${name} (${iconKey})`}
	/>
}
