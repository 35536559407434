import {configurationDataType} from "../actions/loginAction";
import {BLOCKS, espHomeYaml, selectEspHomeInstance, setTab} from "../actions/settingsActions";

const initialState = {
	tab: BLOCKS,
	espHomeInstances: [],
	espHomeYamlMap: null,
	espHomeInstance: '',
	espHome: {
		instances: []
	}
}

export default function settings(state = initialState, action = {}) {
	const type = action.type

	if (type === espHomeYaml) {
		console.log('received espHomeYaml', action)
		return {
			...state,
			espHomeInstances: Object.keys(action.yaml),
			espHomeYamlMap: action.yaml
		}
	} else if (type === configurationDataType) {
		const {espHome} = action.data
		if (!espHome) return state
		console.log('received espHome', espHome)
		const espHomeInstances = Object.keys(espHome.instances)
		return {
			...state,
			espHome,
			espHomeInstance: espHomeInstances[0],
			espHomeInstances
		}
	} else if (type === setTab) {
		return {
			...state,
			tab: action.tab
		}
	} else if (type === selectEspHomeInstance) {
		return {
			...state,
			espHomeInstance: action.instance
		}
	}
	return state
}

export const tabSelector = state => state.settings.tab
export const espHomeInstanceSelector = state => state.settings.espHomeInstance
export const espHomeInstancesSelector = state => state.settings.espHomeInstances
export const espHomeYamlMapSelector = state => state.settings.espHomeYamlMap
export const espHomeInstancesMapSelector = state => state.settings.espHome.instances
