import React from 'react'
import {useDispatch} from 'react-redux'
import {finishEvent} from '../../custom/eventUtils'
import './buttons.scss'
import {faSolid} from '../../shared/faConstants'

const Text = ({icon, label, title}) => {
	if (icon && !label) return null
	return <span className="label">{label || title}</span>
}

const Button = ({title, onDispatch, onClick, icon, label, selected}) => {
	const dispatch = useDispatch()

	const clickHandler = onDispatch
		? () => dispatch(onDispatch(!selected))
		: onClick

	return <span className={`button ${selected ? 'sel' : ''}`} title={title} onClick={clickHandler}>
	<span className={icon ? icon.includes(faSolid) ? icon : `fa-regular ${icon}` : undefined}/>
	<Text title={title} label={label} icon={icon}/>
</span>
}

export const Buttons = ({buttons = []}) => <div onClick={finishEvent} className="Buttons">
	{buttons.map((button, i) => <Button key={i} {...button} />)}
</div>
