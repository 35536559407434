export const loginSuccessType = "LOGIN/FINISHED"
export const loginSuccess = (data) => ({type: loginSuccessType, data})

export const loginPreparedType = "LOGIN/PREPARED"
export const loginPrepared = () => ({type: loginPreparedType})

export const loginFailedType = "LOGIN/FAILED"
export const loginFailed = (error) => ({type: loginFailedType, error})

export const changeFormType = "LOGIN/CHANGE_FORM"
export const changeForm = (key, value) => ({type: changeFormType, key, value})

export const doLoginType = "LOGIN/STARTED"
export const doLogin = (username, password, rememberMe, name) => ({
	type: doLoginType, username, password, rememberMe, name
})

export const configurationDataType = "DATA/CONFIGURATION"
export const configurationData = (data) => ({type: configurationDataType, data})

export const unregisteredDevicesAction = "devices/unregistered"
export const newModuleAction = "modules/new"
export const removedModuleAction = "modules/removed"
