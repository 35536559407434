import {EditPortValue, registerModal} from './Modal'
import InputSlider from 'react-input-slider'
import {eventValue, isEnter, stylesActive} from '../../inputUtils'
import React, {useState} from 'react'
import {hasUnit, rangeByUnit} from '../../../shared/portConstants'
import {useSelector} from 'react-redux'
import {portMapSelector} from '../../../state/reducers/control'
import {finishEvent} from '../../../custom/eventUtils'
import {getPortValue} from '../../../custom/portValues'

const Value = ({range, value, setValue, unit, resolve}) => {
	if (range) return <div className="sliders">
		<span>{value} {unit}</span>
		<InputSlider className="u-slider-value"
								 styles={stylesActive}
								 xmin={range.minValue} xmax={range.maxValue} xstep={range.step} x={value}
								 onChange={({x}) => setValue(Math.round(10 * x) / 10)}
								 onDragEnd={() => null}/>
	</div>

	return <input type="text" value={`${value}`}
								onChange={eventValue(value => setValue(+value))}
								onKeyDown={e => isEnter(e) ? resolve(value) : null}/>
}


registerModal(EditPortValue, ({resolve, selected = {}}) => {
	const {portKey} = selected
	const portMap = useSelector(portMapSelector)
	const port = portMap[portKey]
	const unit = port?.unit || hasUnit[port?.type]
	const range = rangeByUnit[unit]

	const [value, setValue] = useState(getPortValue(portKey))

	return <div className="centerDiv" onClick={finishEvent}>
		<h1>{`${port?.name || 'Unnamed module port'} ${portKey}`}</h1>
		<div className="smallForm">
			<Value range={range} value={value} setValue={setValue} unit={unit} resolve={resolve}/>
		</div>
		<div className="smallForm">
			<button onClick={() => resolve(value)}><span className="fa-regular fa-check"/> OK</button>
			<button onClick={() => resolve()}><span className="fa-regular fa-ban"/> Cancel</button>
		</div>
	</div>
})
