import {useEffect, useRef, useState} from 'react'

const portValues = {}

export const getPortValue = (key) => portValues[key]

export const usePortValue = (key) => {
	const [value, setValue] = useState(getPortValue(key))
	useEffect(() => {
		if (key) addPortListener(key, setValue)
		const actualValue = getPortValue(key)
		if (value !== actualValue) setValue(actualValue)
		return () => {
			if (key) removePortListener(key, setValue)
		}
	}, [key])
	return value
}

export const usePortRefListener = (key, field, getter) => {
	const ref = useRef()
	useEffect(() => {
		const listener = (value) => {
			const fieldValue = getter(value, ref.current)
			if (field) ref.current[field] = fieldValue
		}
		addPortListener(key, listener)
		listener(getPortValue(key))
		return () => removePortListener(key, listener)
	}, [key, getter])
	return ref
}

const portListeners = {}
export const addPortListener = (key, listener) => {
	let listeners = portListeners[key]
	if (!listeners) {
		portListeners[key] = listeners = []
	}
	listeners.push(listener)
}
export const removePortListener = (key, listener) => {
	let listeners = portListeners[key]
	if (listeners) {
		const index = listeners.indexOf(listener)
		if (index >= 0) {
			listeners.splice(index, 1)
		}
	}
}

const updatePortValue = (change) => {
	const [key, stringValue] = change.split(':')
	const value = +stringValue
	portValues[key] = value
	const listeners = portListeners[key]
	if (listeners) {
		for (const listener of listeners) {
			try {
				listener(value, key)
			} catch (e) {
				console.error("Error port listener", e)
			}
		}
	}
}

export const processPortChanges = data => {
	data.split(',').forEach(updatePortValue)
}
