import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Time} from '../reusable/datetime/time'
import {Week} from '../reusable/datetime/week'
import {changeTimer, changeTimerActive, changeTimerDays, changeTimerTime} from '../../state/actions/timersActions'
import {newTimer, timersSelector} from '../../state/reducers/control'
import {EditTimerAction, onlyHide, openModal} from '../reusable/context/Modal'
import './timers.scss'

const existing = true

export const Timer = ({timer}) => {
	const dispatch = useDispatch()
	const {index, active, name, commands, configuration: {days, time}} = timer

	const edit = (event) => {
		event.stopPropagation()
		openModal(EditTimerAction, {timer, existing})
			.then((changedTimer) => dispatch(changeTimer(changedTimer)))
			.catch(onlyHide)
	}

	return <div className={`timer-item ${active ? 'active' : ''}`}
							onClick={() => dispatch(changeTimerActive(!active, index))}>
		<div className="title clickable">
			{`${name}`}
			<span className="fa-regular fa-edit" onClick={edit}></span>
		</div>
		<div className="clickable code" onClick={edit}>{commands}</div>
		<Week value={days} onChange={(days) => dispatch(changeTimerDays(days, index))} active={active}/>
		<Time value={time} onChange={(time) => dispatch(changeTimerTime(time, index))} active={active}/>
	</div>
}

export const Timers = () => {
	const dispatch = useDispatch()
	const timers = useSelector(timersSelector)

	console.log("-> render Timers.js", {timers})

	return <div className="timers">
		<div className="caption">Timers</div>
		{timers && timers.map(timer => <Timer key={`${timer.name || timer.index}`} timer={timer}/>)}
		<div className="timer-item new-timer" onClick={(event) => {
			event.stopPropagation()
			openModal(EditTimerAction, {timer: newTimer(timers, timers.length)})
				.then((changedTimer) => dispatch(changeTimer(changedTimer)))
				.catch(onlyHide)
		}}>
			<span className="fa-solid fa-plus"></span>
		</div>
	</div>
}
