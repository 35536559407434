import {resizeWindowAction} from '../actions/application'
import {configurationDataType} from '../actions/loginAction'
import {openPanelType, resizeAction} from '../actions/visualAction'
import {CONTROL, VISUAL} from '../../view/items'
import {fitSize} from '../../custom/dom'
import {celsiusTempUnit} from '../../shared/portConstants'

const smallScreenThreshold = 550
const startScreen = {width: window.innerWidth, height: window.innerHeight}

const initialState = {
	selected: startScreen.width < smallScreenThreshold ? CONTROL : VISUAL,
	image: null,
	imageData: null,
	screen: startScreen,
	options: {},
	power: {}
}

const loadData = (state, action) => {
	const {data} = action
	const {picture, defaultTempUnit = celsiusTempUnit} = (data && data.meta) || {}
	const image = picture ? picture : state.image
	const size = image === state.image ? state.size : null
	let power = data.power ? state.power || {} : null
	if (power) {
		const {power:{meter, tariff}} = data
		const powerKey = meter ? meter.key : null
		const powerUnit = meter ? meter.unit : null
		const tariffKey = tariff ? tariff.key : null
		const tariffs = tariff ? tariff.types : null
		power = {...power, powerKey, powerUnit, tariffKey, tariffs}
	}
	return {...state, image, size, power, defaultTempUnit}
}

export default function visual(state = initialState, action = {}) {
	switch (action.type) {
		case openPanelType:
			return {
				...state,
				selected: action.item.name
			}
		case resizeAction:
			return {
				...state,
				sectionSize: action.sectionSize,
				size: action.size,
				options: action.options,
				style: fitSize(action.sectionSize, action.size, action.options)
			}
		case resizeWindowAction:
			return {
				...state,
				screen: action.screen,
				style: fitSize(action.screen, state.size, state.options)
			}
		case configurationDataType:
			return loadData(state, action)
		default:
			return state
	}
}

export const selectedMenuSelector = state => state.visual.selected
export const defaultTempUnitSelector = state => state.visual.defaultTempUnit
export const imageSelector = state => state.visual.image
export const sizeSelector = state => state.visual.size
export const screenSizeSelector = state => state.visual.screen
export const sectionSizeSelector = state => state.visual.sectionSize
export const styleSelector = state => state.visual.style
