import React, {useState} from 'react'
import {finishEvent} from '../../../custom/eventUtils'
import {ModulePortTypes} from './ModuleContext'
import {useDispatch, useSelector} from 'react-redux'
import {customParameterSelector} from '../../../state/reducers/control'
import {Field, required} from './ModalForm'
import {activateModule, deactivateModule, removeModule} from '../../../state/actions/contextAction'
import {sendAction} from '../../../custom/serverApi'
import {activeModuleSelector} from '../../../state/reducers/meta'
import {ConfirmAction, ModuleAction, onlyHide, openModal, registerModal} from './Modal'
import {labelForModuleField} from '../../forms/forms'

const labels = {
	"^-?(\\d+\\.)?\\d+$": 'number',
	"^-?\\d+$": 'integer',
	"^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$": 'URL',
	"^([0-9A-Fa-f]{1,2}[:-]){5}([0-9A-Fa-f]{1,2})$": 'MAC address'
}
const regExpDescription = reqVal => {
	const label = labels[reqVal]
	if (label) return `be a ${label}`
	return `meet expression: ${reqVal}`
}
const validateAndNormalize = (value, def) => {
	if (def.optional === true && value === '') return undefined
	if (def.optional === false && value === '') throw new Error(required)

	if (def.reqVal && !new RegExp(def.reqVal).exec(value)) {
		if (value === '')
			throw new Error(required)
		throw new Error(`'${value}' needs to ${regExpDescription(def.reqVal)}`)
	}
	if (def.options && !def.options.includes(value)) {
		throw new Error(`Sorry, value '${value}' needs to be one of: ${def.options.join(', ')}`)
	}
	if (def.type === 'byte') {
		const i = parseInt(value)
		if (i >= 0 && i < 256) {
			return i
		}
		throw new Error(`'${value}' is not a byte number`)
	}
	if (def.type === 'number') {
		const i = parseFloat(value)
		if (!isNaN(i)) {
			return i
		}
		throw new Error(`'${value}' is not a number`)
	}
	return value
}

const renderFieldsData = (customParameter, module) => {
	const parameters = {}
	let hasErrors = false
	if (!customParameter) {
		return {fields: [], parameters: {}, hasErrors, module}
	}
	const fields = Object.entries(customParameter).map(([field, def]) => {
		const enteredValue = module.parameters[field]
		const value = enteredValue != null ? `${enteredValue}` : `${def.defaultValue != null ? def.defaultValue : ''}`
		let error = null
		try {
			parameters[field] = validateAndNormalize(value, def)
		} catch (e) {
			parameters[field] = def.defaultValue
			hasErrors = true
			error = e.message
		}
		return {field, value, def, error}
	})

	return {fields, parameters, hasErrors, module, customParameter}
}


const resolveData = (resolve, index, fieldsData) => {
	const module = fieldsData.module
	const parameters = fieldsData.parameters
	resolve({index, module: {...module, parameters}})
}

const ActiveButton = ({moduleIndex}) => {
	const dispatch = useDispatch()
	const active = useSelector(activeModuleSelector(moduleIndex))
	if (active) {
		return <button onClick={() => dispatch(deactivateModule(moduleIndex))}>
			<span className="fa-regular fa-ban"/> Deactivate
		</button>
	}

	return <button onClick={() => dispatch(activateModule(moduleIndex))}>
		<span className="fa-regular fa-check"/> Activate
	</button>
}

const RemoveButton = ({moduleIndex, resolve}) => {
	const dispatch = useDispatch()
	const active = useSelector(activeModuleSelector(moduleIndex))

	if (active) return null

	const onClick = () => openModal(ConfirmAction, {
		title: `Do you really want to remove module?`,
		okIcon: 'fa-trash'
	}).then(() => {
		resolve()
		dispatch(removeModule(moduleIndex))
	}).catch(onlyHide)

	return <button onClick={onClick}><span className="fa-regular fa-trash"/> Remove</button>
}

const ModuleButtons = ({extraActions, isNew, moduleIndex, resolve}) => {
	if (isNew) return null

	return <div>
		<ActiveButton moduleIndex={moduleIndex}/>
		<RemoveButton moduleIndex={moduleIndex} resolve={resolve}/>
		{Object.keys(extraActions).map((action, i) =>
			<button key={`actionButton${i}`}
							onClick={() => sendAction('module', {action, moduleIndex, values: null})}>
				{action}
			</button>
		)}
	</div>
}

registerModal(ModuleAction, ({resolve, selected = {}}) => {
	const moduleIndex = selected.index
	const [module, setModule] = useState(selected.module)
	const customParameter = useSelector(customParameterSelector)
	const fieldsData = renderFieldsData(customParameter[module.type], module)
	const isNew = moduleIndex === -1

	console.log("-> render ModalModuleAction.js", {selected, fieldsData})

	return <div className="centerDiv" onClick={finishEvent}>
		<h1>{isNew ? 'Add new module' : 'Edit module'}</h1>
		<div className="smallForm">
			{isNew
				? <div>
					{Object.keys(customParameter).map((type, index) =>
						<span className={`options ${type === module.type ? 'sel' : ''}`} key={index} onClick={() => {
							setModule({type, name: module.name, parameters: {}})
						}}> {`${type}`} </span>)}
				</div>
				: <div className="moduleTitle">
					<ModulePortTypes keys={module.keys}/>
					<span className="moduleType">{module.type}</span>
				</div>}

			<Field field="name" title="name" value={module.name} errors={!module.name && [required]}
						 onChange={name => setModule({...module, name})}
			/>

			{fieldsData.fields.map(({field, value, def, error}) =>
				<Field field={field} title={labelForModuleField(module.type, field)} value={value} errors={error && [error]}
							 def={def}
							 onChange={value => setModule({...module, parameters: {...module.parameters, [field]: value}})}
				/>
			)}
		</div>
		<ModuleButtons isNew={isNew} moduleIndex={moduleIndex} resolve={resolve} extraActions={module.extraActions}/>
		<div className="smallForm">
			<button disabled={fieldsData.hasErrors || !module.name || !module.type}
							onClick={() => resolveData(resolve, moduleIndex, fieldsData)}>{
				isNew
					? <><span className={`fa-regular ${selected.okIcon || 'fa-plus'}`}/> Add and activate device</>
					: <><span className={`fa-regular ${selected.okIcon || 'fa-floppy-disk'}`}/> Save</>}
			</button>
			<button onClick={() => resolve()}><span className={`fa-regular ${selected.cancelIcon || 'fa-ban'}`}/> Cancel
			</button>
		</div>
	</div>
})
