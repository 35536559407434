import {Sha256} from '../shared/sha256'
import {processPortChanges} from './portValues'
import {configurationData, loginPrepared} from '../state/actions/loginAction'
import {receivedAllLogs, receivedNewLogs, serverConnection} from '../state/actions/application'
import {espHomeYaml, espHomeYamlAction} from "../state/actions/settingsActions";

const localStorageKey = "authorization"
const sessionStorageKey = "a"
const headerAuthKey = "authorization"
const options = {}


const parseHash = hash => {
	const result = {}
	if (hash) {
		const parameters = hash.substr(1).split("&")
		for (const parameter of parameters) {
			const [name, value] = parameter.split('=')
			result[name] = value
		}
	}
	return result
}

const setUrl = (url) => {
	const apiUrl = localStorage.getItem("apiUrl")
	options.nextReconnection = Date.now()
	options.url = apiUrl || url.replace(":3000", ":8080")
	options.useAuth = options.url !== url
}

const setAuthorization = authorization => {
	if (authorization) {
		options.authorization = authorization
		sessionStorage.setItem(sessionStorageKey, authorization)
		document && (document.cookie = `authorization=${authorization} path=/api`)
		console.log(`Authenticated URL is ${window.location.origin}#token=${authorization}`)
	} else {
		delete options.authorization
		sessionStorage.removeItem(sessionStorageKey)
	}
}

setUrl(window.location.origin + '/api')
setAuthorization(parseHash(window.location.hash).token || sessionStorage.getItem(sessionStorageKey))

const getAuth = () => options.useAuth && options.authorization ? `?${headerAuthKey}=${options.authorization}` : ""

export const apiImgUrl = image => `${options.url}/img/${image}${getAuth()}`

const storeLogin = (data) => {
	setAuthorization(data && data.token)
	if (data) {
		localStorage.setItem(localStorageKey, JSON.stringify(data))
	} else {
		localStorage.removeItem(localStorageKey)
	}
}

const loadLogin = () => {
	const value = localStorage.getItem(localStorageKey)
	try {
		return value ? JSON.parse(value) : null
	} catch (e) {
		return null
	}
}

const method = "POST"

const post = (data, authorization) => {
	const body = JSON.stringify(data)
	const headers = new Headers()

	headers.append("Content-Type", "application/json")
	headers.append("Content-Length", body.length)
	authorization && headers.append(headerAuthKey, authorization)

	return {method, body, headers}
}

const get = (authorization) => {
	if (authorization) {
		const headers = new Headers()
		headers.append(headerAuthKey, authorization)
		return {method: 'GET', headers}
	}
}


const apiAuthorizationUser = async ({url, authorization}) => {
	const response = await fetch(url + "/Authorization/user", get(authorization))
	if (response.status === 401) throw new Error("401")
	const json = await response.text()
	console.log(`Received authorization ${json}`)
	const data = JSON.parse(json)
	if (data.token) setAuthorization(data.token)
	return data.user
}

const apiAuthorizationChallenge = ({url}, data) => fetch(url + "/Authorization/challenge", post(data)).then(resp => resp.json())

const apiAuthorizationAuthenticate = ({url}, data) => fetch(url + "/Authorization/authenticate", post(data)).then(resp => resp.json())

const apiAuthorizationLogout = async ({url, authorization}) => {
	const resp = await fetch(url + "/Authorization/logout", get(authorization))
	return resp.status !== 401
}

const openMessaging = (onMessage) => {
	const {url} = options
	options.nextReconnection = Date.now() + 3000
	function reconnect() {
		if (options.authenticationFailed) {
			return
		}
		if (options.nextReconnection < Date.now()) {
			openMessaging(onMessage)
		} else {
			setTimeout(reconnect, 500)
		}
	}
	const ws = new WebSocket(`${url.replace("http", "ws")}/messaging${getAuth()}`)
	let connected = false
	ws.onmessage = event => {
		onMessage(JSON.parse(event.data))
	}
	ws.onclose = (e) => {
		if (connected) {
			console.log(`closing messaging ${e.code}`)
			connected = false
			dispatch(serverConnection(false))
		}
		reconnect()
	}
	ws.onopen = () => {
		options.ws = ws
		connected = true
		dispatch(serverConnection(true))
		options.receiveLogs && receiveLogs()
	}
}

const authenticateFlow = async ({id, username, passHash, name}) => {
	const {challenge} = await apiAuthorizationChallenge(options, {id, username})
	const hash = Sha256.hash(challenge + passHash)
	const data = await apiAuthorizationAuthenticate(options, {id, username, name, hash})
	if (data.error) throw data.error
	const {Authorization, Device, User} = data
	storeLogin({token: Authorization, id: Device && Device.id, hash: Device && Device.passHash})
	options.authenticationFailed = false
	return User
}

export const loginUser = async ({username, password, name}) => {
	const passHash = Sha256.hash(password)
	return await authenticateFlow({username, passHash, name})
}

export const checkUser = async () => {
	const {token, id, hash} = loadLogin(localStorageKey) || {}
	if (token) setAuthorization(token)
	const user = await apiAuthorizationUser(options)
	if (user) {
		return user
	} else if (id && hash) {
		return await authenticateFlow({id, passHash: hash})
	}
	throw new Error("User not authorized")
}

let dispatch

export function registerStore(store) {
	dispatch = store.dispatch
}

export const logoutUser = async () => {
	const success = await apiAuthorizationLogout(options)
	storeLogin(null)
	return success
}

export const initializeConfiguration = async () => {
	openMessaging(async (message) => {
		switch (message.type) {
			case 'NOT_AUTHORIZED':
				try {
					await checkUser()
				} catch (e) {
					options.authenticationFailed = true
					dispatch(loginPrepared())
				}
				return
			case 'portChange/all':
				return processPortChanges(message.data)
			case 'log':
				if (message.isAll) {
					dispatch(receivedAllLogs(message.logs))
				} else {
					dispatch(receivedNewLogs(message.logs))
				}
				return
			case 'configuration':
				return dispatch(configurationData(message.data))
			case 'espHomeYaml':
				return dispatch(espHomeYamlAction(message.yaml))
			case 'modules/active':
				return dispatch(message)
			default:
				dispatch(message)
		}
	})
}

export const sendCommands = command => sendAction("port", {command})

export const receiveLogs = () => {
	options.receiveLogs = true
	sendAction("logs", {action: "start"})
}

export const stopLogs = () => {
	options.receiveLogs = false
	sendAction("logs", {action: "stop"})
}

export const sendAction = (type, data = {}) => {
	const {ws} = options
	const action = JSON.stringify({type, ...data})
	console.log(`action => ${action}`)
	ws && ws.send(action)
}

export const sendRemoveUserAction = (username) => sendAction('user', {removeUser: {username}})
export const sendAddUserAction = (username) => sendAction('user', {newUser: {username}})
export const sendLogoutUserAction = (username) => sendAction('user', {logoutUser: {username}})
export const sendSaveUserAction = (user) => sendAction('user', {saveUser: {user}})
export const sendRefreshUsersAction = () => sendAction('user')
export const sendSetUserPasswordAction = ({username, password}) => {
	const passHash = Sha256.hash(password)
	sendAction('user', {passChange: {username, passHash}})
}
export const sendSetUserAutoLoginAction = ({username, autoLoginIpAddress}) =>
	sendAction('user', {autoLogin: {username, autoLoginIpAddress}})
