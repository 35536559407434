import React from 'react'
import InputSlider from 'react-input-slider'
import {stylesActive, stylesInactive} from '../../inputUtils'
import {finishEvent} from '../../../custom/eventUtils'

const change = (key, value, onChange) => pos => {
	if (!onChange) return
	const _value = value || defaultValue()
	if (_value[key] !== pos.x) {
		const newValue = {..._value, [key]: pos.x}
		onChange(newValue)
	}
}

function defaultValue() {
	const now = new Date()
	return {hours: now.getHours(), minutes: now.getMinutes()}
}

const format = num => `${num < 10 ? 0 : ''}${num}`

export const Time = ({value, onChange, minStep = 1, hourStep = 1, active, children}) => {
	const {hours, minutes} = value || defaultValue()

	console.log("-> render Time.js", {value})

	const styles = active ? stylesActive : stylesInactive

	return <div className={`m-time ${active ? 'm-time-active' : ''}`}>
		<div className="clickable showtime">
			<span className="time">{format(hours)}</span>
			<span className="separator">:</span>
			<span className="time">{format(minutes)}</span>
		</div>

		<div className="sliders" onClick={finishEvent}>
			<div className="time-text">Hours:</div>
			<InputSlider className="u-slider" styles={styles} xmin={0} xmax={23} xstep={hourStep} x={hours}
									 onChange={change('hours', value, onChange)}
			/>
			<div className="time-text">Minutes:</div>
			<InputSlider className="u-slider-time" styles={styles} xmin={0} xmax={59} xstep={minStep} x={minutes}
									 onChange={change('minutes', value, onChange)}
			/>
			{children}
		</div>
	</div>
}
