import React, {useState} from 'react'
import {ModalForm} from './ModalForm'
import {timerForm} from '../../forms/forms'
import {useDispatch} from 'react-redux'
import {removeTimer} from '../../../state/actions/timersActions'
import {CommandsEditor} from './CommandsEditor'
import {eventValue} from '../../inputUtils'
import {EditTimerAction, registerModal} from './Modal'

registerModal(EditTimerAction, ({resolve, selected = {}}) => {
	const dispatch = useDispatch()
	const {timer, existing} = selected
	const [commands, setCommands] = useState(timer.commands)

	const finalResolve = item => {
		if (!item) resolve()
		else resolve({...item, commands})
	}

	console.log(`ModalEditTimerAction`, {timer})
	return <ModalForm resolve={finalResolve} selected={{
		title: `Timer ${timer.name}`,
		form: timerForm,
		requireChange: existing && timer.commands === commands,
		value: timer,
		delete: existing ? () => dispatch(removeTimer(timer.index)) : null
	}}>
		<div className="smallForm">
			<CommandsEditor commands={commands} onChange={(s) => setCommands(s)}/>
			<label>
				Code
				<textarea
					onChange={eventValue(setCommands)}
					className="codeSnippet"
					value={commands}
					style={{resize: 'vertical'}}/>
			</label>
		</div>
	</ModalForm>
})
