import React from 'react'
import {useSelector} from 'react-redux'
import {portMapSelector} from '../../../state/reducers/control'
import {IconContainer} from '../IconContainer'
import {finishEvent} from '../../../custom/eventUtils'
import {ControlSelect, registerModal} from './Modal'

const createFilter = (portMap, {unit, type}) => {
	if (unit) return key => portMap[key].unit === unit
	if (type) return key => portMap[key].type === type
	return () => true
}

registerModal(ControlSelect, ({resolve, selected}) => {
	const portMap = useSelector(portMapSelector)
	const keys = Object.keys(portMap).filter(createFilter(portMap, selected))
	keys.sort()

	console.log("-> render ModalControls.js", {keys})

	return <div>
		<h1>Choose item</h1>
		{keys.map(key =>
			<IconContainer
				key={key}
				icon={portMap[key]}
				primaryClick={(e) => {
					finishEvent(e)
					resolve(key)
				}}
				details
			/>
		)}
	</div>
})
