import React from 'react'
import {ConfirmAction, FormAction, onlyHide, openModal, UserAction} from './context/Modal'
import {sendLogoutUserAction, sendRemoveUserAction, sendSaveUserAction} from '../../custom/serverApi'
import {finishEvent} from '../../custom/eventUtils'
import {userForm} from '../forms/forms'

const toStr = time => {
	if (!time) return "-"
	const date = new Date(time)
	return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}

const lastLogin = user => {
	if (!user.lastLogin) return "didn't occurred"
	if (user.lastFailedLogin > user.lastLogin) return "failed"
	return "succeeded"
}

export const UserContainer = ({showHeader, user, isMe, hideButtons}) => {
	const deleteClick = () => openModal(ConfirmAction, {
		title: `Do you really want to remove user ${user.username}?`,
		okIcon: 'fa-trash'
	})
		.then(() => sendRemoveUserAction(user.username))
		.catch(onlyHide)

	const editUser = () => openModal(FormAction, {
		title: `User ${user.username}`,
		form: userForm,
		requireChange: true,
		value: user
	})
		.then(sendSaveUserAction)
		.catch(onlyHide)

	const logout = () => sendLogoutUserAction(user.username)

	const accessDetail = () => openModal(UserAction, {user})
		.catch(onlyHide)

	return <div className={`userContainer ${isMe ? 'sel' : ''}`}>
		<div hidden={!showHeader} className="title">
			<span className="moduleType">{user.username}</span>
			<span className="portCount">{toStr(user.lastLogin)}</span>
		</div>
		<p>
			<label>name</label>
			<span className="name">{user.name} {user.surname}</span>
		</p>
		<p>
			<label>e-mail</label>
			<span>{user.email || '-'}</span>
		</p>
		<p hidden={showHeader}>
			<label>username</label>
			<span>{user.username}</span>
		</p>
		<p hidden={showHeader}>
			<label>login</label>
			<span>{toStr(user.lastLogin)}</span>
		</p>
		<p>
			<label>failed</label>
			<span>{toStr(user.lastFailedLogin)}</span>
		</p>
		<p>
			<label>status</label>
			<span>last login {lastLogin(user)}</span>
		</p>
		<p hidden={hideButtons} className="buttons" onClick={finishEvent}>
			<span title="Edit user" onClick={editUser} className="fa-regular fa-pencil clickable"/>
			<span title="Access details" onClick={accessDetail} className="fa-regular fa-key clickable"/>
			<span title="Delete user" onClick={isMe ? null : deleteClick}
						className={`fa-regular fa-trash ${isMe ? '' : 'clickable'}`}/>
			<span title="Logout user" onClick={user.hasSession ? logout : null}
						className={`fa-regular fa-sign-out ${user.hasSession ? 'clickable' : ''}`}/>
		</p>
	</div>
}
