import React, {useState} from 'react'
import {finishEvent} from '../../../custom/eventUtils'
import {ControlEditor} from '../../context/ControlEditor'
import {getPortValue} from '../../../custom/portValues'
import {useDispatch, useSelector} from 'react-redux'
import {controlsSelector, portMapSelector} from '../../../state/reducers/control'
import {
	ConfirmAction,
	ControlPositionSelect,
	EditIconAction,
	onlyHide,
	openModal,
	registerModal,
	StringValue
} from './Modal'
import {RoomSelectBox} from '../RoomSelectBox'
import {changeRoomName, removeIcon} from '../../../state/actions/contextAction'
import {addControlElement, editPort} from '../../../features/edit'

const iconAndPort = (controlIndex, controls, portMap, selectedKey) => {
	let icon, port
	if (controlIndex >= 0) {
		icon = controls[controlIndex]
		port = portMap[icon.key]
	} else {
		port = portMap[selectedKey]
		icon = port
	}
	return {icon, port}
}

const EditPortButton = ({item, controlIndex, resolve, setItem}) => {
	const dispatch = useDispatch()

	const onEditPortClick = () => {
		resolve()
		setTimeout(() => editPort(dispatch, item.key), 200)
	}

	const onReposition = () => {
		const control = {...item}
		openModal(ControlPositionSelect, {control, controlIndex})
			.then(b => setItem({...item, b}))
			.catch(onlyHide)
	}

	const remove = () => openModal(ConfirmAction, {
		title: `Do you really want to remove control element?`,
		okIcon: 'fa-trash'
	}).then(() => {
		resolve()
		dispatch(removeIcon(controlIndex))
	}).catch(onlyHide)

	return <>
		<button onClick={onEditPortClick}><span className="fa-regular fa-pencil"/> Port</button>
		<button onClick={onReposition}><span className="fa-regular fa-map-location"/> Position</button>
		<button onClick={remove}><span className="fa-regular fa-trash"/> Remove</button>
	</>

}

const RoomSwitcher = ({controlIndex, item, setItem}) => {
	const dispatch = useDispatch()
	if (!(controlIndex >= 0)) return null

	const onClick = (roomIndex, selected, name) => {
		if (selected) {
			openModal(StringValue, {
				title: `Change room name ${name}`,
				value: name,
				okIcon: 'fa-pencil'
			})
				.then(name => dispatch(changeRoomName(roomIndex, name)))
				.catch(onlyHide)
		} else {
			setItem({...item, roomIndex})
		}
	}

	return <>
		<label className="textLeft">Room</label>
		<RoomSelectBox selectedRoom={item.roomIndex} onClick={onClick}/>
	</>
}

const trim = (item, port) => {
	const result = {...item}
	Object.entries(port).forEach(([field, value]) => {
		if (field !== 'key' && item[field] === value) delete result[field]
	})
	return result
}

registerModal(EditIconAction, ({resolve, selected = {}}) => {
	const dispatch = useDispatch()
	const controls = useSelector(controlsSelector)
	const portMap = useSelector(portMapSelector)
	const {portKey, controlIndex} = selected
	const {icon, port} = iconAndPort(controlIndex, controls, portMap, portKey)
	const isPort = icon === port
	const [item, setItem] = useState({...port, ...icon, value: getPortValue(icon.key)})

	if (!icon || !port) {
		resolve()
		return <button onClick={() => resolve()}>Port or Control doesn't exist</button>
	}

	const addControl = () => addControlElement(dispatch, {key: icon.key})

	const finish = () => resolve(isPort ? item : trim(item, port))

	return <div className="centerDiv" onClick={finishEvent}>
		<h1>{isPort ? 'Edit module port' : 'Edit control element'}</h1>
		<div className="smallForm">
			<ControlEditor controlIndex={controlIndex} item={item} setItem={setItem} port={isPort ? null : port}/>
			<RoomSwitcher controlIndex={controlIndex} item={item} setItem={setItem}/>
		</div>
		<div className="smallForm">
			<button onClick={finish}><span className="fa-regular fa-floppy-disk"/> OK</button>
			{controlIndex >= 0
				? <EditPortButton item={item} controlIndex={controlIndex} resolve={resolve} setItem={setItem}/>
				: <button onClick={addControl}><span className="fa-regular fa-plus"/> Add to room</button>
			}
			<button onClick={() => resolve()}><span className="fa-regular fa-ban"/> Cancel</button>
		</div>
	</div>
})
