import React from 'react'
import {usePortValue} from '../../custom/portValues'
import {defaultIcons} from '../../shared/faConstants'
import {finishEvent} from '../../custom/eventUtils'
import {editPortValue} from '../../features/edit'

export const Meter = ({style, unit, valueKey: key, selected, icon}) => {
	console.log("-> render Meter.js", key)
	const value = usePortValue(key)
	const onClick = e => finishEvent(e, () => editPortValue(key))
	return <div className={`valueSelector ${selected ? 'sel' : ''}`} style={style} onClick={onClick}>
		<span key="icon" className={icon || defaultIcons.meter}/>
		<span key="valueSpan" className="value">{`${value || 0}${unit}`}</span>
	</div>
}
