import React from 'react'
import {finishEvent} from '../../../custom/eventUtils'
import {ConfirmAction, registerModal} from './Modal'

registerModal(ConfirmAction, ({resolve, selected = {}}) => {

	console.log("-> render ModalConfirmAction.js", {selected})

	return <div className="centerDiv" onClick={finishEvent}>
		<h1>{selected.title}</h1>
		<div>
			<button onClick={() => resolve(true)}><span className={`fa-regular ${selected.okIcon || 'fa-rocket'}`}/> OK
			</button>
			<button onClick={() => resolve()}><span className={`fa-regular ${selected.cancelIcon || 'fa-ban'}`}/> Cancel
			</button>
		</div>
	</div>
})
