
export const elementSize = (element) => ({width: element.offsetWidth, height: element.offsetHeight})

export const parent = (element) => element.parentElement

export const sameSize = (element1, element2) => element1 && element2 && element1.width === element2.width && element1.height === element2.height

export const fitSize = (outerSize, innerSize, {padding=0, units=0}={}) => {
	if (!outerSize || !innerSize) return null
	const doublePadding = 2*padding
	let width, height, left, top
	if (outerSize.width-doublePadding > innerSize.width && outerSize.height-doublePadding > innerSize.height) {
		width = innerSize.width+units
		height = innerSize.height+units
		left = ((outerSize.width-innerSize.width)>>1) + units
		top = ((outerSize.height-innerSize.height)>>1) + units
	} else {
		const innerRatio = innerSize.width/innerSize.height
		if ((outerSize.width-doublePadding)/(outerSize.height-doublePadding)<innerRatio) {
			width = Math.min(outerSize.width-doublePadding, innerSize.width)
			height = Math.round(width/innerRatio)
			left = padding+units
			top = ((outerSize.height-height)>>1) + units
			width = width + units
			height = height + units
		} else {
			height = Math.min(outerSize.height-doublePadding, innerSize.height)
			width = Math.round(height*innerRatio)
			left = ((outerSize.width-width)>>1) + units
			top = padding+units
			width = width + units
			height = height + units
		}
	}
	return {width, height, left, top}
}

export const elementPosition = (el) => {
	let top = 0, left = 0
	while (el) {
		left += el.offsetLeft
		top += el.offsetTop
		el = el.offsetParent
	}
	return {left, top}
}
