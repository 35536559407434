import React from 'react'
import {ControlPositionSelect, registerModal} from './Modal'
import {finishEvent} from '../../../custom/eventUtils'
import {Visual} from '../../content/Visual'
import {useSelector} from 'react-redux'
import {screenSizeSelector} from '../../../state/reducers/visual'

const position = 'relative'

registerModal(ControlPositionSelect, ({resolve, selected = {}}) => {
	const {control, controlIndex} = selected
	const size = useSelector(screenSizeSelector)

	const width = `100%`
	const height = `${size.height - 60}px`
	const overflow = "hidden"

	return <div className="centerDiv" onClick={finishEvent}>
		<div style={{width, height, position, overflow}}>
			<Visual control={control} controlIndex={controlIndex}/>
		</div>
		<div className="smallForm">
			<button onClick={() => resolve(control.b)}><span className="fa-regular fa-check"/> OK</button>
			<button onClick={() => resolve()}><span className="fa-regular fa-ban"/> Cancel</button>
		</div>
	</div>
})
