import React from 'react'
import {registerModal, RoomSelect} from './Modal'
import {finishEvent} from '../../../custom/eventUtils'
import {RoomSelectBox} from '../RoomSelectBox'

registerModal(RoomSelect, ({resolve, selected = {}}) => {
	const {roomIndex} = selected

	return <div className="centerDiv" onClick={finishEvent}>
		<h1>Choose room</h1>
		<div className="smallForm">
			<RoomSelectBox selectedRoom={roomIndex} onClick={resolve}/>
		</div>
	</div>
})
