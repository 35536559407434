import React, {useEffect, useRef, useState} from 'react'
import {finishEvent} from '../../../custom/eventUtils'
import {isSame} from '../../forms/forms'
import {ConfirmAction, FormAction, onlyHide, openModal, registerModal} from './Modal'
import {eventValue} from '../../inputUtils'

const validate = (form, value) => {
	if (!form.validation) {
		return []
	}
	const validations = []
	const fail = (field, message) => validations.push({field, message})
	form.validation(value, fail)
	return validations
}

const Errors = ({errors}) => {
	if (!errors) return null
	return errors.map((error, i) => <span className="validation-warning" key={i}>{error}</span>)
}

export const required = '*Required'
export const notUnique = 'Already exists'

export const Field = ({field, title, reference, name, def, value, errors, onChange}) => {
	if (def?.options) {
		return <label key={`${field}`}>
			{title}:
			<select
				ref={reference}
				name={name || field}
				value={value || ''}
				onChange={e => onChange(e.target.value)}>
				{def.options.map((option, i) =>
					<option key={`${i}-${option}`} value={option}>{option}</option>
				)}
			</select>
			{errors ? errors.map((error, i) => <span className="validation-warning" key={i}>{error}</span>) : null}
		</label>
	}
	if (def === 'boolean' || def?.type === 'boolean') {
		return (
			<label key={`${field}`}
						 ref={reference}
						 onClick={(event) => {
							 if (onChange) onChange(!value)
							 event.preventDefault()
							 event.stopPropagation()
						 }}>
				<span className={`fa-regular ${value ? 'fa-check' : 'fa-circle'}`} style={{width: '15px'}}/>
				{title}
				<Errors errors={errors}/>
			</label>
		)
	}
	return (
		<label key={`${field}`}>
			{title}:
			<input
				ref={reference}
				name={name || field}
				type={def?.type || 'text'}
				value={value || ''}
				disabled={!onChange}
				onChange={onChange ? eventValue(onChange) : null}/>
			<Errors errors={errors}/>
		</label>
	)
}

export const ModalForm = ({resolve, selected = {}, children}) => {
	const first = useRef(null)
	const [value, setValue] = useState(selected.value || {})
	const [validations, setValidations] = useState([])
	useEffect(() => {
		if (first.current?.select) first.current.select()
		if (first.current?.focus) first.current.focus()
	}, [])

	const fields = selected.form.fields
	const canNotSave = selected.requireChange && isSame(value, selected.value)

	console.log("-> render ModalForm.js", {selected, value})


	return <div className="centerDiv" onClick={finishEvent}>
		<h1>{selected.title || selected.form.title}</h1>
		<div className="smallForm">
			{fields.map(({title, type, field}, i) => (
				<Field
					key={field || i}
					field={field}
					title={title}
					reference={i === 0 ? first : null}
					name={field}
					def={type}
					value={value[field] || false}
					errors={validations
						.filter(validation => validation.field === field)
						.map(validation => validation.message)}
					onChange={fieldValue => setValue({...value, [field]: fieldValue})}
				/>
			))}
		</div>
		{children}
		<div>
			<button disabled={canNotSave} onClick={() => {
				const v = validate(selected.form, value)
				if (v.length) setValidations(v)
				else resolve(value)
			}}><span className={`fa-regular ${selected.okIcon || 'fa-pencil'}`}/> OK
			</button>
			<button onClick={() => resolve()}><span className={`fa-regular ${selected.cancelIcon || 'fa-ban'}`}/> Cancel
			</button>
			{
				selected.delete
					? <button onClick={() => {
						openModal(ConfirmAction, {
							title: `Do you really want to remove it?`,
							okIcon: 'fa-trash'
						}).then(() => {
							selected.delete()
							resolve()
						}).catch(onlyHide)
					}}><span className="fa-regular fa-trash"/> Delete</button>
					: null
			}
		</div>
	</div>
}

registerModal(FormAction, ModalForm)
