export const formFields = {
	numberOfDI: 'DI count',
	numberOfDO: 'DO count',
	numberOfAI: 'AI count',
	numberOfAO: 'AO count',
	digitalInputKeys: 'DI keys',
	digitalOutputKeys: 'DO keys',
	analogOutputKeys: 'AO keys',
	analogInputKeys: 'AI keys',
	sunriseDelaySec: 'Sunrise Δ [s]',
	sunsetDelaySec: 'Sunset Δ [s]'
}

export const userForm = {
	title: 'Change user detail',
	fields: [
		{title: 'Name', field: 'name'},
		{title: 'Surname', field: 'surname'},
		{title: 'E-Mail', field: 'email'}
	]
}

export const timerForm = {
	title: 'Change timer',
	fields: [
		{title: 'Active', field: 'active', type: 'boolean'},
		{title: 'Name', field: 'name'}
	]
}

export const passChangeForm = {
	title: 'Change password',
	fields: [
		{title: 'Password', field: 'password', type: "password", key: "password"},
		{title: 'Repeat', field: 'confirmPassword', type: "password", key: "password"}
	],
	validation: (data, fail) => {
		if (!data.password) fail('password', 'Password can not be empty')
		if (data.password !== data.confirmPassword) fail('confirmPassword', 'Password is not same')
	}
}

export const autoLoginForm = {
	title: 'Change autologin',
	fields: [
		{title: 'IP address', field: 'autoLoginIpAddress'}
	]
}

export const isSame = (a, b) => {
	for (const i in a) {
		const ai = a[i]
		const bi = b[i]
		if (typeof ai === 'object') {
			if (!isSame(ai, bi)) {
				return false
			}
		} else if (ai !== bi) {
			return false
		}
	}
	return true
}

export const capitalize = (field) => {
	let result = ''
	for (const c of field) {
		const upperC = c.toUpperCase()
		if (c === upperC) result += ' '
		result += result ? c : upperC
	}
	return result
}

export const labelForModuleField = (type, field) => (formFields[type] || formFields)[field] || capitalize(field)
