export const changeTimerAction = "TIMER/CHANGE"
export const changeTimer = timer => ({type: changeTimerAction, timer})

export const changeTimerTimeAction = "TIMER/CHANGE_TIME"
export const changeTimerTime = (time, index) => ({type: changeTimerTimeAction, time, index})

export const changeTimerDaysAction = "TIMER/CHANGE_DAYS"
export const changeTimerDays = (days, index) => ({type: changeTimerDaysAction, days, index})

export const changeTimerActiveAction = "TIMER/CHANGE_ACTIVE"
export const changeTimerActive = (active, index) => ({type: changeTimerActiveAction, active, index})

export const removeTimerAction = "TIMER/REMOVE"
export const removeTimer = (index) => ({type: removeTimerAction, index})

