import React from 'react'
import {CommandEditor} from './CommandEditor'
import {ControlSelect, onlyHide, openModal} from './Modal'


export const CommandsEditor = ({onChange, commands}) => {
	const array = commands ? commands.split(',') : []

	const onChangeAt = (index, command) => {
		if (onChange) {
			if (!command) {
				array.splice(index, 1)
			} else if (index < 0) {
				array.splice(1 - index, 0, command)
			} else {
				array[index] = command
			}
			const newCommands = array.join(',')
			if (commands !== newCommands) {
				onChange(newCommands)
			}
		}
	}

	const addNewClick = () => openModal(ControlSelect, {})
		.then(key => onChangeAt(-array.length, `${key}:0`))
		.catch(onlyHide)

	const onChangeCommand = index => {
		if (!onChange) {
			return null
		}
		return command => onChangeAt(index, command)
	}

	return <div className="commandsEditor">
		{array.map((command, index) =>
			<CommandEditor onChange={onChangeCommand(index)} remove={() => onChangeAt(index)} key={index} command={command}/>
		)}
		{onChange ? <div className="buttons">
			<button onClick={addNewClick}>
				<span className="fa-solid fa-plus"/> Add
			</button>
		</div> : null}
	</div>
}
