import React from 'react'
import {useSelector} from 'react-redux'
import '../stylesheet/main.scss'
import {Login} from './Login'
import {Menu} from './Menu'
import {Switcher} from './Switcher'
import {Modal} from './reusable/context/Modal'
import './reusable/context/ModalControls'
import './reusable/context/ModalModuleSelect'
import './reusable/context/ModalStringValue'
import './reusable/context/ModalConfirmAction'
import './reusable/context/ModalIcons'
import './reusable/context/ModalForm'
import './reusable/context/ModalModuleAction'
import './reusable/context/ModalUserAction'
import './reusable/context/ModalEditTimerAction'
import './reusable/context/ModalEditEspInstanceAction'
import './reusable/context/ModalEditIconAction'
import './reusable/context/ModalEditBlockAction'
import './reusable/context/ModalEditRoomAction'
import './reusable/context/ModalPortValue'
import './reusable/context/ModalUnitValue'
import './reusable/context/ModalRoomSelect'
import './reusable/context/ModalControlPositionSelect'
import {lastLoginSelector, loadingSelector} from '../state/reducers/login.reducer'
import {offlineSelector} from '../state/reducers/menu'

export const TouchPanel = () => {
	const loading = useSelector(loadingSelector)
	const lastLogin = useSelector(lastLoginSelector)
	const offline = useSelector(offlineSelector)

	console.log("-> render TouchPanel.js", {loading, lastLogin})

	if (!lastLogin) return <Login/>

	if (loading) return <div className="loader">Please wait</div>

	return <div className="fullHeight">
		{offline ? <div className="layout">connection lost</div> : null}
		<Modal/>
		<div className="mainView">
			<Menu/>
			<Switcher/>
		</div>
	</div>
}
