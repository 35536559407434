export const isEnter = e => {
	if (!e.shiftKey && e.key === "Enter") {
		e.preventDefault()
		return true
	}
	return false
}

export const [stylesActive, stylesInactive] = [true, false].map(active => ({
	track: {
		width: "100%",
		height: "4px",
		backgroundColor: "rgba(0, 0, 0, 0.20)"
	},
	active: {
		backgroundColor: active ? '#0A86C9' : "rgba(0, 0, 0, 0.20)",
		borderRadius: "3px"
	},
	thumb: {
		backgroundColor: 'white',
		width: 30,
		height: 30,
		borderColor: active ? '#0A86C9' : "rgba(0, 0, 0, 0.20)",
		borderStyle: "solid",
		borderWidth: "4px",
		boxShadow: "none"
	}
}))

export const eventValue = f => (event) => f(event.target.value)
