export const CODE = 'code'
export const BLOCKS = 'blocks'
export const ESPHOME = 'espHome'


export const setTab = "SETTINGS/SET_TAB"
export const setTabAction = (tab) => ({type: setTab, tab})

export const espHomeYaml = "SETTINGS/ESP_HOME_YAML"
export const espHomeYamlAction = (yaml) => ({type: espHomeYaml, yaml})

export const selectEspHomeInstance = "SETTINGS/SELECT_ESP_HOME_INSTANCE"
export const selectEspHomeInstanceAction = (instance) => ({type: selectEspHomeInstance, instance})
