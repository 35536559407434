import React from 'react'
import {usePortValue} from '../../custom/portValues'
import {finishEvent} from '../../custom/eventUtils'
import {editPortValue} from '../../features/edit'
import {rangeByUnit} from '../../shared/portConstants'
import {defaultIcons} from '../../shared/faConstants'

export const Thermometer = ({style, unit, valueKey: key, icon, selected}) => {
	console.log("-> render Thermometer.js", key)
	const {unit: tempUnit} = rangeByUnit[unit] || {unit}
	const value = usePortValue(key)

	return <div className={`valueSelector clickable ${selected ? 'sel' : ''}`} style={style} onClick={
		e => finishEvent(e, () => editPortValue(key))}>
		<span key="icon" className={icon || defaultIcons.term}/>
		<span key="valueSpan" className="value">{`${value || 0}${tempUnit}`}</span>
	</div>
}
