import React from 'react'
import {logout, switchMenu} from '../state/actions/menuAction'
import {contentItems} from './items'
import {useDispatch, useSelector} from 'react-redux'
import {openMenuSelector} from '../state/reducers/menu'
import {editRoom} from '../features/edit'
import {roomsSelector} from '../state/reducers/control'
import {selectedMenuSelector} from '../state/reducers/visual'
import {openPanel} from '../state/actions/visualAction'
import './menu.scss'

const ContentItem = ({item, selected, onMenuSwitch}) => {
	const dispatch = useDispatch()
	const onClick = selected ? onMenuSwitch : () => dispatch(openPanel(item))

	return <span className={`fa-regular ${item.icon} ${selected ? 'sel' : ''}`} title={item.name} onClick={onClick}/>
}

export const Menu = () => {
	const dispatch = useDispatch()
	const selected = useSelector(selectedMenuSelector)
	const open = useSelector(openMenuSelector)
	const rooms = useSelector(roomsSelector)

	console.log("-> render Menu.js", {open, selected})

	const onEditClick = () => editRoom(dispatch, rooms)

	const onMenuSwitch = () => dispatch(switchMenu(open))

	return <nav className={`nav${open ? '-open' : ''}`}>
			<span onClick={onMenuSwitch} className="menu-icon">
				<span className="button-text">Show Menu</span>
			</span>
		<div>
			<section className="menu">
				<div className="close"/>
				{contentItems.map(item =>
					<ContentItem
						key={item.name}
						item={item}
						selected={selected === item.name}
						onMenuSwitch={onMenuSwitch}
					/>
				)}
			</section>
			<section className="menu-items">
					<span className="menu-link" onClick={onEditClick}>
						<span className="fa-regular fa-edit"/> Edit
					</span>
				<span className="menu-link" onClick={() => logout(dispatch)}>
					<span className="fa-regular fa-sign-out"/> Logout
				</span>
			</section>
		</div>
	</nav>
}
