import React, {useEffect, useRef, useState} from 'react'
import {finishEvent} from '../../../custom/eventUtils'
import {eventValue} from '../../inputUtils'
import {energyUnits, otherUnits, powerUnits, tempUnits, timeUnits} from '../../../shared/portConstants'

import './modalUnitValue.scss'
import {registerModal, UnitValue} from './Modal'


const units = (setValue, arr) => arr.map(unit =>
	<span
		className="unit-item"
		key={unit}
		onClick={() => setValue(unit)}
	>{unit} </span>
)

registerModal(UnitValue, ({resolve, selected = {}}) => {
	const input = useRef(null)

	const [value, setValue] = useState(selected || '')
	useEffect(() => {
		input.current.select()
		input.current.focus()
	}, [])

	console.log("-> render ModalUnitValue.js", {selected, value})

	const canNotSave = selected === value

	return <div className="centerDiv" onClick={finishEvent}>
		<h1>Change units</h1>
		<input ref={input} className="modalTextInput"
					 onChange={eventValue(setValue)}
					 onKeyUp={e => e.keyCode === 13 && resolve(value)}
					 onSubmit={() => resolve(value)}
					 value={value}/>
		<div className="unit-values">
			<span className="fa-regular fa-temperature-half"/> {units(setValue, tempUnits)}
		</div>
		<div className="unit-values">
			<span className="fa-regular fa-plug"/> {units(setValue, powerUnits)}
		</div>
		<div className="unit-values">
			<span className="fa-regular fa-bolt"/> {units(setValue, energyUnits)}
		</div>
		<div className="unit-values">
			<span className="fa-regular fa-clock"/> {units(setValue, timeUnits)}
		</div>
		<div className="unit-values">
			<span className="fa-regular fa-sliders"/> {units(setValue, otherUnits)}
		</div>
		<div>
			<button disabled={canNotSave} onClick={() => resolve(value)}><span className="fa-regular fa-pencil"/> OK</button>
			<button onClick={() => resolve()}><span className="fa-regular fa-ban"/> Cancel</button>
		</div>
	</div>
})
