import React from 'react'
import {useSelector} from 'react-redux'
import {getPortValue, usePortRefListener} from '../../custom/portValues'
import {sendCommands} from '../../custom/serverApi'
import {OnOffAuto} from './OnOffAuto'
import {Shade} from './Shade'
import {Meter} from './Meter'
import {Thermometer} from './Thermometer'
import {Thermostat} from './Thermostat'
import {BasicIcon, basicIconClick, createBasicIconOnClick} from './BasicIcon'
import {portMapSelector} from '../../state/reducers/control'
import {defaultTempUnitSelector} from '../../state/reducers/visual'
import {basicType, defaultIcons} from '../../shared/faConstants'
import {onOffOffAutoNextValue} from '../../shared/portConstants'
import {typeVariants} from '../../shared/portUtils'
import {editPortValue} from '../../features/edit'

const ValueBasedIconElement = ({portKey, portIcons, onClick}) => {
	const ref = usePortRefListener(portKey, 'className', value => `small-icon ${portIcons[value]} clickable`)
	return <span ref={ref} onClick={onClick}/>
}

export const IconElement = ({port, onClick}) => {
	if (!port.icon && port.icons) {
		return <ValueBasedIconElement portKey={port.key} portIcons={port.icons} onClick={onClick}/>
	}
	const iconName = port.icon || defaultIcons[port.type]
	return <span className={`small-icon ${iconName} clickable`} onClick={onClick}/>
}

export const Icon = ({icon, style, selected, component, onClick}) => {
	const key = icon.key
	const portMap = useSelector(portMapSelector)
	const port = portMap[key] || icon
	const defaultTempUnit = useSelector(defaultTempUnitSelector)
	const item = {...port, ...icon}

	const {type, name, unit, icon: customIcon, icons} = item

	console.log("-> render Icon.js", {key, icons})

	if (type === basicType)
		return <BasicIcon iconKey={key}
											name={name}
											style={style}
											icons={icons}
											selected={selected}
											component={component}
											onClick={onClick || createBasicIconOnClick(icon)}
		/>

	if (type === typeVariants.shade) {
		return <Shade
			selected={selected}
			style={style}
			valueKey={key}
			icon={customIcon}
		/>
	}

	if (type === typeVariants.onOffAuto) {
		return <OnOffAuto
			selected={selected}
			style={style}
			valueKey={key}
			icon={customIcon}
		/>
	}

	if (type === typeVariants.powerMeter || type === typeVariants.meter) {
		return <Meter
			selected={selected}
			style={style}
			valueKey={key}
			icon={customIcon}
			unit={unit || ""}
		/>
	}

	if (type === typeVariants.temp) {
		return <Thermometer
			selected={selected}
			style={style}
			valueKey={key}
			icon={customIcon}
			unit={unit || defaultTempUnit}
		/>
	}

	if (type === typeVariants.thermostat) {
		return <Thermostat
			selected={selected}
			style={style}
			item={item}
			unit={unit || defaultTempUnit}
			onClick={onClick || createBasicIconOnClick(icon)}
		/>
	}

	return null
}

export const simplePortOnClick = (port) => {
	const {key, type} = port
	if (type === basicType) {
		return basicIconClick(port)
	}
	if (type === typeVariants.onOffAuto) {
		const value = onOffOffAutoNextValue(getPortValue(key))
		return sendCommands(`${key}:${value}`)
	}
	editPortValue(key)
}
