import {
	changeFormType,
	configurationDataType,
	doLoginType,
	loginFailedType,
	loginPreparedType,
	loginSuccessType
} from '../actions/loginAction'

const initialState = {
	loading: true,
	loginDisabled: false,
	wrongPassword: false,
	username: "",
	password: "",
	rememberMe: false,
	email: "",
	lastLogin: 0,
	surname: "",
	name: ""
}

export function login(state = initialState, action = {}) {
	switch (action.type) {
		case configurationDataType:
			return {
				...state,
				loading: false
			}
		case loginPreparedType:
			return {
				...state,
				rememberMe: false,
				email: "",
				lastLogin: 0,
				surname: "",
				name: "",
				loginDisabled: false,
				wrongPassword: false
			}
		case doLoginType:
			return {
				...state,
				loginDisabled: true,
				wrongPassword: false
			}
		case loginFailedType:
			return {
				...state,
				loginDisabled: false,
				wrongPassword: true
			}
		case loginSuccessType:
			const {email, lastLogin, name, surname, username} = action.data
			return {
				...state,
				email, lastLogin, name, surname, username,
				password: ""
			}
		case changeFormType:
			return {
				...state,
				[action.key]: action.value
			}
		default:
			return state
	}
}

export const loadingSelector = state => state.login.loading
export const lastLoginSelector = state => {
	sessionStorage.setItem("state", JSON.stringify(state))
	return state.login.lastLogin
}
export const authenticatedUserSelector = state => state.login
export const authenticatedUsernameSelector = state => state.login.username
