import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {receiveLogs, sendAction, stopLogs} from '../../custom/serverApi'
import {debugSelector, keepScrollingSelector, logsSelector, timeSelector} from '../../state/reducers/maintenance'
import {toggleDebug, toggleScrolling, toggleTime} from '../../state/actions/application'
import {paddingTop, TopPanel} from '../context/TopPanel'
import {showValue} from '../../shared/portUtils'
import {millisecondUnit} from '../../shared/portConstants'

export const Maintenance = () => {
	const logsElement = useRef(null)
	const logs = useSelector(logsSelector)
	const keepScrolling = useSelector(keepScrollingSelector)
	const debug = useSelector(debugSelector)
	const time = useSelector(timeSelector)
	const [lines, setLines] = useState({})
	useEffect(() => {
		receiveLogs()
		return () => stopLogs()
	}, [])
	useEffect(() => {
		if (keepScrolling) {
			logsElement.current.scrollTop = logsElement.current.scrollHeight
		}
	}, [logs, keepScrolling])

	console.log("-> render Maintenance.js")
	const items = []
	let lastDate = null
	if (logs) {
		logs.forEach((log, index) => {
			const i = log.indexOf(' ')
			const type = log.substring(i + 1, i + 3)
			const isDebug = type === '#D'
			if (!debug && isDebug) {
				return null
			}
			const timestamp = new Date(log.substring(0, i))
			const isError = !isDebug && type === '#E'
			const messageStart = i + (isDebug || isError ? 4 : 1)
			const newLine = log.indexOf('\n', messageStart)
			const isMore = newLine > 0
			const showButton = isMore && !lines[`line${index}`]
			const message = showButton ? log.substring(messageStart, newLine) : log.substring(messageStart)
			const className = isDebug ? "dbg" : isError ? "err" : "inf"
			const d = timestamp.toLocaleDateString()
			if (d !== lastDate) {
				items.push(<div key={`date${index}`} className="dat">{d}</div>)
				lastDate = d
			}
			let onClick = isMore ? () => setLines({...lines, [`line${index}`]: showButton}) : null
			items.push(
				<div onClick={onClick} key={index} className={className}>
					{time
						? `${timestamp.toLocaleTimeString()} ${showValue(timestamp.getMilliseconds(), millisecondUnit)} ${message}`
						: message}
					{showButton && <span className="fa-regular fa-square-caret-down"/>}
				</div>)
		})
	}

	const buttons = [
		{title: 'Show timestamps', onDispatch: toggleTime, selected: time, icon: 'fa-clock'},
		{title: 'Show debug messages', onDispatch: toggleDebug, selected: debug, icon: 'fa-bug'},
		{title: 'Keep scrolling', onDispatch: toggleScrolling, selected: keepScrolling, icon: 'fa-arrow-down'},
		{title: 'Restart HomeKit', onClick: () => sendAction('homeKit', {restart: {}})},
		{title: 'Stop TouchPanel', onClick: () => sendAction('system', {action: "stop"})},
		{title: 'Restart TouchPanel', onClick: () => sendAction('system', {action: "restart"})},
		{title: 'Upgrade TouchPanel', onClick: () => sendAction('system', {action: "upgrade"})}
	]

	return <div className="maintenance" ref={logsElement} style={{paddingTop}}>
		<TopPanel buttons={buttons}/>
		{items}
	</div>
}
