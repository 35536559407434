import {
	ControlPositionSelect,
	ControlSelect,
	EditIconAction,
	EditPortValue,
	EditRoomAction,
	onlyHide,
	openModal,
	RoomSelect
} from '../view/reusable/context/Modal'
import {changeIconProperty, changePortProperty, changeRooms, placeNewIcon} from '../state/actions/contextAction'
import {sendCommands} from '../custom/serverApi'

export const editControlElement = (dispatch, controlIndex) => openModal(EditIconAction, {controlIndex})
	.then(item => item && dispatch(changeIconProperty(item, controlIndex)))
	.catch(onlyHide)

export const editPort = (dispatch, portKey) => openModal(EditIconAction, {portKey})
	.then(item => item && dispatch(changePortProperty(item)))
	.catch(onlyHide)

export const editRoom = (dispatch, rooms, roomIndex) => {
	rooms = rooms.map((item, roomIndex) => ({...item, roomIndex}))
	openModal(EditRoomAction, {rooms, roomIndex})
		.then(rooms => rooms && dispatch(changeRooms(rooms)))
		.catch(onlyHide)
}

export const editPortValue = (portKey) => openModal(EditPortValue, {portKey})
	.then(value => value != null && sendCommands(`${portKey}:${value}`))
	.catch(onlyHide)

export const addControlElement = (dispatch, control) => {
	const steps = async () => {
		if (control.key == null) {
			control.key = await openModal(ControlSelect, {key: control.key})
		}
		if (control.b == null) {
			control.b = await openModal(ControlPositionSelect, {control})
		}
		if (control.roomIndex == null) {
			control.roomIndex = await openModal(RoomSelect, {roomIndex: control.roomIndex})
		}

		dispatch(placeNewIcon(control))

	}

	steps().catch(e => console.log(`Not adding element ${JSON.stringify(control)}`, e))
}
