import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {IconElement, simplePortOnClick} from './Icon'
import {finishEvent} from '../../custom/eventUtils'
import {portMapSelector} from '../../state/reducers/control'
import {editPort} from '../../features/edit'
import {EditBlockAction, onlyHide, openModal} from './context/Modal'
import {showValue} from '../../shared/portUtils.js'

const typeIcons = {
	'customBlock': 'fa-gear',
	'switchBlock': 'fa-toggle-on',
	'thermostatBlock': 'fa-temperature-half',
	'light': 'fa-circle'
}

const Items = ({type, items, blockType, editMode}) => {
	const dispatch = useDispatch()
	const portMap = useSelector(portMapSelector)
	const rows = blockType[type]

	return <div className={`${(type !== 'inputs' ? type !== 'outputs' ? type !== 'fields' ? 'unknown'
				: 'fieldPorts'
			: 'outputPorts'
		: 'inputPorts')}`}>
		{rows.map(({name, description, units}, index) => {
			if (name === 'id') return null
			const available = items.filter(({type}) => type === name)
			if (!editMode && !available.length) return null
			if (type === 'fields') {
				return <div title={name} key={index} className="field">
					<span className="description">{description}:</span>
					{available.map(({key}) => showValue(key, units)).join(', ')}
				</div>
			}
			return <div title={name} key={index} className="port">
				<div className="description">{description}</div>
				{available.map(({key}) => {
					const port = portMap[key]
					if (!port) {
						return `${key}`
					}
					const onClick = (e) => finishEvent(e) && editPort(dispatch, key)
					const onClickIcon = e => finishEvent(e, () => simplePortOnClick(port))
					return <div style={{float: "left", width: "100%"}}>
						<span className="key clickable" onClick={onClick}>{key}</span>
						<IconElement port={port} onClick={onClickIcon}/>
						<span className="name clickable" onClick={onClick}>{port.name}</span>
					</div>
				})}
			</div>
		})}
	</div>
}

export const Block = ({block, canEdit, selected, blockType, editMode}) => {
	if (!block) {
		return null
	}

	const onClick = canEdit
		? () => openModal(EditBlockAction, {block})
			.then()
			.catch(onlyHide)
		: null

	const {inputs, outputs, fields} = block
	return <div className={`logic-block ${selected ? 'sel' : ''} ${onClick ? 'clickable' : ''}`} onClick={onClick}>
		<Items type="inputs" items={inputs} blockType={blockType} editMode={editMode}/>
		<div className="iconContainer">
			<div className="title">
				<span className="portKey"/>
				<span className="portModule">{block.type}</span>
			</div>
			<div className="iconDock"><span className={`fa-regular ${typeIcons[block.type] || 'fa-circle-question'}`}/></div>
			<div className="iconName">{block.id}</div>
		</div>
		<Items type="outputs" items={outputs} blockType={blockType} editMode={editMode}/>
		<Items type="fields" items={fields} blockType={blockType} editMode={editMode}/>
	</div>
}
