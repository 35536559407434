import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {IconContainer} from '../reusable/IconContainer'
import {controlsSelector, portMapSelector, roomsSelector} from '../../state/reducers/control'
import {editControlElement, editPort, editRoom} from '../../features/edit'

export const Control = () => {
	const dispatch = useDispatch()
	const portMap = useSelector(portMapSelector)
	const controls = useSelector(controlsSelector)
	const rooms = useSelector(roomsSelector)

	return <div className="controls">
		{rooms.map((room, roomIndex) => <div key={`${room.name}-${roomIndex}`}>
			<div className="caption clickable" onClick={() => editRoom(dispatch, rooms, roomIndex)}>{room.name}</div>
			{controls.map((icon, iconIndex) =>
				icon.roomIndex === roomIndex ? <IconContainer
					key={`key-${roomIndex}-${iconIndex}-${icon.key}`}
					secondaryClick={() => editControlElement(dispatch, iconIndex)}
					icon={icon}
				/> : null)}
			<div className="controlLine"/>
		</div>)}
		<div className="caption clickable" onClick={() => editRoom(dispatch, rooms, -1)}>All Available Controls</div>
		{Object.keys(portMap).map((key, index) =>
			<IconContainer
				key={`key-${index}`}
				icon={portMap[key]}
				secondaryClick={() => editPort(dispatch, key)}
				details
			/>
		)}
	</div>
}
