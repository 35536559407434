import {ConfirmAction, EditEspHomeAction, onlyHide, openModal, registerModal} from "./Modal";
import {finishEvent} from "../../../custom/eventUtils";
import React, {useState} from "react";
import {Field, notUnique, required} from "./ModalForm";
import {sendAction} from "../../../custom/serverApi";

const i2cDefault = {
	"sda": 4,
	"scl": 5,
	"scan": true,
	"id": "bus_a"
}

const customBoard = 'other'
const boards = {
	options: ['d1_mini', customBoard]
}

const defaultData = {
	i2c: i2cDefault,
	ota: '',
	encryptionKey: '',
	wifi: {
		ssid: '',
		password: '',
		ap: {
			ssid: "Hotspot",
			password: "password"
		}
	}
}
registerModal(EditEspHomeAction, ({resolve, selected = {}}) => {
	const {instance: originalInstance, data: originalData, espHomeInstances} = selected
	const [data, setData] = useState(originalData || defaultData);
	const [instance, setInstance] = useState(originalInstance)
	const [board, setBoard] = useState(boards.options[0])
	const [boardJSON, setBoardJSON] = useState("{}")

	const finish = () => {
		if (originalInstance) {
			sendAction('configuration', {saveEspHome: {instance, data}})
		} else {
			const platform = board !== customBoard ? {board} : JSON.parse(boardJSON);
			const newData = {...data, ...platform}
			sendAction('configuration', {saveEspHome: {instance, data: newData}})
		}
		resolve({instance, data})
	}

	return <div className="centerDiv" onClick={finishEvent}>
		<h1>{originalInstance ? `Edit ${instance}` : 'Add new EspHome'}</h1>
		<div className="smallForm">
			{!originalInstance && <Field field="id" title="Unique ID" value={instance} onChange={setInstance}
																	 errors={espHomeInstances.includes(instance) && [notUnique]}

			/>}
			{!originalInstance && <Field field="boardType" title="ESP board" value={board} onChange={setBoard}
																	 def={boards}
			/>}
			{board === customBoard && <Field field="boardJSON" title="JSON" value={boardJSON} onChange={setBoardJSON}
																			 errors={espHomeInstances.includes(instance) && [required]}

			/>}
			<Field field="name" title="Name" value={data.name} errors={!data.name && [required]}
						 onChange={name => setData({...data, name})}
			/>
			<Field field="yamlPath" title="YAML path" value={data.yamlPath}
						 onChange={yamlPath => setData({...data, yamlPath})}
			/>
			<Field field="encryptionKey" title="Key" value={data.encryptionKey}
						 onChange={encryptionKey => setData({...data, encryptionKey})}
			/>
			<Field field="ota" title="OTA" value={data.ota}
						 onChange={ota => setData({...data, ota})}
			/>
			<Field field="ssid" title="Wifi ssid" value={data.wifi.ssid}
						 onChange={ssid => setData({...data, wifi: {...data.wifi, ssid}})}
			/>
			<Field field="wifiPassword" title="Password" value={data.wifi.password}
						 onChange={password => setData({...data, wifi: {...data.wifi, password}})}
			/>
			<Field field="hasI2C" title="I2C" value={Boolean(data.i2c)} def="boolean"
						 onChange={hasI2C => setData({...data, i2c: hasI2C ? originalData.i2c || i2cDefault : undefined})}
			/>
			{data.i2c && <Field field="id" title="I2C ID" value={data.i2c.id}
													onChange={id => setData({...data, i2c: {...data.i2c, id}})}
			/>}
			{data.i2c && <Field field="sda" title="I2C SDA" value={data.i2c.sda} def={{type: 'number'}}
													onChange={sda => setData({...data, i2c: {...data.i2c, sda: +sda}})}
			/>}
			{data.i2c && <Field field="scl" title="I2C SCL" value={data.i2c.scl} def={{type: 'number'}}
													onChange={scl => setData({...data, i2c: {...data.i2c, scl: +scl}})}
			/>}
		</div>
		<div className="smallForm">
			<button onClick={finish}><span className="fa-regular fa-floppy-disk"/> Save</button>
			{originalInstance && <button onClick={() => openModal(ConfirmAction, {
				title: `Do you really want to remove it?`,
				okIcon: 'fa-trash'
			}).then(() => {
				sendAction('configuration', {saveEspHome: {instance}})
				resolve({instance})
			}).catch(onlyHide)}><span className="fa-regular fa-trash"/> Delete</button>}
			<button onClick={() => resolve()}><span className="fa-regular fa-ban"/> Cancel</button>
		</div>
	</div>
})
