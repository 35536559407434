import {ConfirmAction, EditRoomAction, onlyHide, openModal, registerModal} from './Modal'
import React, {useState} from 'react'
import {Field, required} from './ModalForm'
import {finishEvent} from '../../../custom/eventUtils'
import {apiImgUrl} from '../../../custom/serverApi'
import {useDispatch, useSelector} from 'react-redux'
import {imageSelector} from '../../../state/reducers/visual'
import {addControlElement} from '../../../features/edit'
import {useDraggableContext, useDraggableRef, useDroppableRef} from '../dragging'
import './modalEditRoomAction.scss'

const draggableRoomInitializer = () => {
	const context = {}
	context.dragEnter = element => element.className = 'smallForm movingLine visibleLine'
	context.dragLeave = element => element.className = 'smallForm movingLine'
	context.canDrop = (movingRoomIndex, index) => {
		if (movingRoomIndex < index) index--
		return movingRoomIndex !== index
	}
	context.drop = (movingRoomIndex, index) => {
		if (movingRoomIndex < index) index--
		if (movingRoomIndex === index) return
		const {items, setItems} = context
		if (movingRoomIndex < 0 || movingRoomIndex >= items.length) return
		const newItems = [...items]
		const movingRoom = items[movingRoomIndex]
		newItems.splice(movingRoomIndex, 1)
		if (movingRoomIndex === items.length) newItems.push(movingRoom)
		else newItems.splice(index, 0, movingRoom)
		setItems(newItems)
	}
	return context
}

const Room = ({rooms, room, index, roomIndex, setItem, draggableContext}) => {
	const droppableRef = useDroppableRef(draggableContext, index)
	const draggableRef = useDraggableRef(draggableContext, index)
	const dispatch = useDispatch()
	const existingRoom = rooms[room.roomIndex]
	const name = existingRoom?.name || 'new room'

	const remove = () => !existingRoom
		? setItem(null, index)
		: openModal(ConfirmAction, {
			title: `Do you really want to remove room ${name} with all control elements?`,
			okIcon: 'fa-trash'
		})
			.then(() => setItem(null, index))
			.catch(onlyHide)

	const addControl = () => addControlElement(dispatch, {roomIndex: room.roomIndex})

	return <div>
		<div ref={droppableRef} className="smallForm movingLine">
			<div/>
		</div>
		<div ref={draggableRef} key={`${index}`} className={`smallForm`} draggable="true">
			<label className={`${room.roomIndex === roomIndex ? 'sel' : ''}`}>
				<span className="roomName">{name}</span>
				<span className="smallFormButton fa-regular fa-trash clickable"
							title={`Remove room ${name}`} onClick={remove}/>
				{existingRoom && <span className="smallFormButton fa-regular fa-plus clickable"
															 title={`Add control element to room ${name}`} onClick={addControl}/>}

			</label>
			<Field field="name" title="name" value={room.name} errors={!room.name && [required]}
						 onChange={name => setItem({...room, name}, index)}
			/>
		</div>
	</div>
}

registerModal(EditRoomAction, ({resolve, selected = {}}) => {
	const {rooms, roomIndex} = selected
	const [items, setItems] = useState(rooms)

	const draggableContext = useDraggableContext(draggableRoomInitializer)
	const lastDropRef = useDroppableRef(draggableContext, items.length)

	const image = useSelector(imageSelector)

	const setItem = (room, index) => room
		? setItems(items.map((item, itemIndex) => index === itemIndex ? room : item))
		: setItems(items.filter((item, itemIndex) => index !== itemIndex))
	draggableContext.items = items
	draggableContext.setItems = setItems

	return <div className="centerDiv" onClick={finishEvent}>
		<h1>Edit rooms</h1>
		{items.map((room, index) =>
			<Room key={`${room.roomIndex}`} rooms={rooms} roomIndex={roomIndex} index={index} room={room}
						setItem={setItem} draggableContext={draggableContext}/>
		)}
		<div ref={lastDropRef} className="smallForm movingLine">
			<div/>
		</div>
		<div className="smallForm smallTextButton" onClick={() => setItems([...items, {name: ''}])}>
			<span className="fa-regular fa-plus"/> insert new room
		</div>
		<div className="smallForm">
			<label>Floor plan</label>
			<img alt="floor plan" src={apiImgUrl(image)} style={{width: "100%", padding: "10px"}}/>
		</div>
		<div className="smallForm">
			<button onClick={() => resolve(items)}><span className="fa-regular fa-floppy-disk"/> OK</button>
			<button onClick={() => resolve()}><span className="fa-regular fa-ban"/> Cancel</button>
		</div>
	</div>
})
