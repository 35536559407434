import React from 'react'
import {usePortValue} from '../../custom/portValues'
import {sendCommands} from '../../custom/serverApi'
import {defaultIcons} from '../../shared/faConstants'
import {shadeMovingValue} from '../../shared/portConstants'
import {finishEvent} from '../../custom/eventUtils'

const startTimes = {}
const start = (e, key, value) => {
	finishEvent(e)
	startTimes[key] = Date.now()
	sendCommands(`${key}:${value}`)
}

const stop = (e, key) => {
	finishEvent(e)
	const startTime = startTimes[key] || 0
	if (Date.now() - startTime > 300) {
		sendCommands(`${key}:${shadeMovingValue.stop}`)
	}
}

export const Shade = ({style, valueKey: key, onClick, icon, selected}) => {
	const value = usePortValue(key)
	return <div className={`valueSelector ${selected ? 'sel' : ''}`} style={style} onClick={onClick}>
		<span key="icon" className={icon || defaultIcons.shade}/>
		<span className="arrows">
			  <span
					className={`${value === shadeMovingValue.up ? 'sel' : ''} button fa-regular fa-caret-up clickable`}
					onMouseDown={e => start(e, key, shadeMovingValue.up)}
					onMouseUp={e => stop(e, key)}
					onTouchStart={e => start(e, key, shadeMovingValue.up)}
					onTouchEnd={e => stop(e, key)}
				/>
			  <br/>
			  <span
					className={`${value === shadeMovingValue.down ? 'sel' : ''} button fa-regular fa-caret-down clickable`}
					onMouseDown={e => start(e, key, shadeMovingValue.down)}
					onMouseUp={e => stop(e, key)}
					onTouchStart={e => start(e, key, shadeMovingValue.down)}
					onTouchEnd={e => stop(e, key)}
				/>
			</span>
	</div>
}
