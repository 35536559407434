import React, {useEffect, useRef, useState} from 'react'
import {finishEvent} from '../../../custom/eventUtils'
import {eventValue} from '../../inputUtils'
import {registerModal, StringValue} from './Modal'

registerModal(StringValue, ({resolve, selected = {}}) => {
	const input = useRef(null)
	const [value, setValue] = useState(selected.value || '')
	useEffect(() => {
		input.current.select()
		input.current.focus()
	}, [])

	console.log("-> render ModalStringValue.js", {selected, value})

	const canNotSave = selected.requireChange && selected.value === value

	return <div className="centerDiv" onClick={finishEvent}>
		<h1>{selected.title}</h1>
		<input ref={input} className="modalTextInput"
					 onChange={eventValue(setValue)}
					 onKeyUp={e => e.keyCode === 13 && resolve(value)}
					 onSubmit={() => resolve(value)}
					 value={value}/>
		<div>
			<button disabled={canNotSave} onClick={() => resolve(value)}><span
				className={`fa-regular ${selected.okIcon || 'fa-pencil'}`}/> OK
			</button>
			<button onClick={() => resolve()}><span className={`fa-regular ${selected.cancelIcon || 'fa-ban'}`}/> Cancel
			</button>
		</div>
	</div>
})
