import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {usePortRefListener} from '../../custom/portValues'
import {ModulePortTypes} from '../reusable/context/ModuleContext'
import {hasUnit, onOffAuto, shadeMovingValue} from '../../shared/portConstants'
import {typeVariants} from '../../shared/portUtils'
import {IconElement, simplePortOnClick} from '../reusable/Icon'
import {
	devicesSelector,
	moduleSelector,
	modulesSelector,
	portMapSelector,
	portMetadataSelector
} from '../../state/reducers/control'
import {activeModuleSelector} from '../../state/reducers/meta'
import {screenSizeSelector} from '../../state/reducers/visual'
import {ModuleAction, onlyHide, openModal} from '../reusable/context/Modal'
import {activateModule, addModule, deactivateModule, saveModule} from '../../state/actions/contextAction'
import {editPort} from '../../features/edit'
import {finishEvent} from '../../custom/eventUtils'

const moduleWidth = 260

// const basicDescriptions = {0: "off", 1: "on"}
export const valueDescriptions = {
	/*
		[none]: basicDescriptions,
		[light]: basicDescriptions,
		[heat]: basicDescriptions,
		[fan]: basicDescriptions,
		[athome]: basicDescriptions,
		[night]: basicDescriptions,
		[run]: basicDescriptions,
	*/
	[typeVariants.shade]: {
		[shadeMovingValue.still]: "still",
		[shadeMovingValue.down]: "down",
		[shadeMovingValue.up]: "up",
		[shadeMovingValue.stop]: "stop"
	},
	[typeVariants.onOffAuto]: {
		[onOffAuto.on]: "on",
		[onOffAuto.off]: "off",
		[onOffAuto.auto]: "auto"
	}
}

const valueWithDescription = (type, value) => {
	const descriptions = valueDescriptions[type]
	const description = descriptions && descriptions[value]
	return description ? `${value} (${description})` : value
}

const range = (lastIndex = -1, endIndex, cb) => {
	const result = []
	for (let index = lastIndex + 1; index <= endIndex; index++) result.push(cb(index))
	return result
}

const countEndingIndexes = (slots, minItemsPerSlot, modules) => {
	if (slots < 2) {
		return [modules.length - 1]
	}
	const sizes = modules.map(({keys}) => 4 + keys.length)
	const count = sizes.reduce((sum, i) => sum + i, 0)
	const optimalItemsPerSlot = count / slots
	const itemsPerSlot = Math.max(minItemsPerSlot, optimalItemsPerSlot)
	const dontOverflow = itemsPerSlot > optimalItemsPerSlot
	let slotSize = 0
	let lastIndex = 0
	const endingIndexes = []
	sizes.forEach((size, endIndex) => {
		slotSize += size
		if (slotSize > itemsPerSlot) {
			if (dontOverflow) {
				lastIndex = endIndex - 1
				slotSize = size
			} else {
				lastIndex = endIndex
				slotSize = 0
			}
			endingIndexes.push(lastIndex)
		}
	})
	if (lastIndex === 0 || lastIndex < modules.length - 1) endingIndexes.push(modules.length - 1)
	endingIndexes[endingIndexes.length - 1]++
	return endingIndexes
}

const ModulePort = ({port, onClick}) => {
	const portMetadata = useSelector(portMetadataSelector)
	const ref = usePortRefListener(port.key, 'innerHTML', value => valueWithDescription(port.type, value))

	const unit = port.unit || hasUnit[port.type]
	const onClickIcon = e => finishEvent(e, () => simplePortOnClick(port))
	const onClickPort = onClick ? e => finishEvent(e) && onClick(port.key) : null
	return <div className="port-item clickable" onClick={onClickPort}>
		<span className={`key ${port.inverted ? 'inverted' : ''}`} title={portMetadata[port.key]}>{port.key}</span>
		<IconElement port={port} onClick={onClickIcon}/>
		<span className="value" onClick={onClickIcon}><span ref={ref}/>{unit ? <span>{unit} </span> : null}</span>
		<span className="name">{port.name}</span>
	</div>
}

const newDeviceName = device => {
	if (device.subtype) return `${device.subtype}(${device.type})`
	return `${device.type}`
}
const NewModule = () => {
	const dispatch = useDispatch()
	const devices = useSelector(devicesSelector)
	const modules = useSelector(modulesSelector)
	const count = modules.length
	const onClick = module => () => openModal(ModuleAction, {index: -1, module})
		.then(data => dispatch(addModule(data.module, count)))
		.catch(onlyHide)
	return <div className="moduleContainer">
		<div className="newModule">
			<span onClick={onClick({type: null, name: "", parameters: {}})} className="fa-solid fa-plus clickable"/>
			{devices && devices.map((device, index) =>
				<p key={index}>
					{' '}
					<span key={`device${index}`} className="clickable"
								onClick={onClick({type: device.type, name: device.name || "", parameters: {...device.parameters}})}>
					{newDeviceName(device)}
				</span>
				</p>
			)}
		</div>
	</div>
}

export const ModuleContainer = ({
																	index,
																	type,
																	name,
																	active,
																	keys,
																	filter,
																	onClick,
																	onActivationClick,
																	onPortClick
																}) => {
	const portMap = useSelector(portMapSelector)
	console.log("-> render ModuleContainer", {type, name})
	return <div key={index} className={`moduleContainer clickable ${active ? 'active-module' : ''}`} onClick={onClick}>
		<div className="title">
			<ModulePortTypes keys={keys}/>
			<span className="moduleType clickable" onClick={onActivationClick}>
				{`${index + 1} ${type.substring(0, 12) + (type.length > 12 ? '...' : '')}`}
			</span>
		</div>
		<div className="blockName">{name || '-'}</div>
		<div className="ports">
			{keys.map(key => (!filter || filter(key)) &&
				<ModulePort key={key} port={portMap[key]} onClick={onPortClick}/>)}
		</div>
	</div>
}

const Module = ({index}) => {
	const dispatch = useDispatch()
	const active = useSelector(activeModuleSelector(index))
	const module = useSelector(moduleSelector(index))

	console.log("-> render Module", {module})

	if (!module) {
		return <NewModule/>
	}

	const {name, type, keys} = module
	const onEdit = () => openModal(ModuleAction, {index, module})
		.then(data => dispatch(saveModule(data.module, 'module', data.index)))
		.catch(onlyHide)

	const onActivationClick = (e) => {
		finishEvent(e)
		if (active) dispatch(deactivateModule(index))
		else dispatch(activateModule(index))
	}

	return <ModuleContainer index={index} active={active} keys={keys} name={name} type={type} onClick={onEdit}
													onActivationClick={onActivationClick} onPortClick={key => editPort(dispatch, key)}/>
}

export const Modules = () => {
	const modules = useSelector(modulesSelector)
	const {width, height} = useSelector(screenSizeSelector)
	const slots = Math.floor(width / moduleWidth)
	const minItemsPerSlot = (height / 16) - 8
	const endingIndexes = countEndingIndexes(slots, minItemsPerSlot, modules)

	console.log("-> render Modules.js", {modules, minItemsPerSlot, endingIndexes})

	if (!modules.length) {
		return <div className="modules">
			<div className="moduleSlots">
				<NewModule/>
			</div>
		</div>
	}

	return <div className="modules">
		{endingIndexes.map((endIndex, i) => (
			<div key={i} className="moduleSlots">
				{range(endingIndexes[i - 1], endIndex, (index) => <Module key={index} index={index}/>)}
			</div>
		))}
	</div>
}
