import {combineReducers} from 'redux'
import meta from './reducers/meta'
import {login} from './reducers/login.reducer'
import menu from './reducers/menu'
import visual from './reducers/visual'
import control from './reducers/control'
import code from './reducers/code'
import maintenance from './reducers/maintenance'
import settings from './reducers/settings'

export const rootReducer = combineReducers(
	{meta, login, menu, visual, control, code, maintenance, settings}
)
