import React from 'react'
import {Icon} from './Icon'
import {useSelector} from 'react-redux'
import {moduleTypeSelector, portMapSelector} from '../../state/reducers/control'
import {finishEvent} from '../../custom/eventUtils'
import {basicIconClick} from './BasicIcon'
import {basicType} from '../../shared/faConstants'
import {typeVariants} from '../../shared/portUtils'

const Details = ({details, portKey, portModule}) => {
	if (!details) return <div className="title"/>

	return <div className="title">
		<span className="portKey">{portKey}</span>
		<span className="portModule">{portModule}</span>
	</div>

}
export const IconContainer = ({icon, selected, details, isSmall, primaryClick, secondaryClick}) => {
	const portMap = useSelector(portMapSelector)
	const port = portMap[icon?.key]
	const {name, type} = useSelector(moduleTypeSelector(port?.moduleIndex || 0))

	if (!port || !icon) {
		return null
	}
	const iconClick = (event) => {
		finishEvent(event)
		if (primaryClick) {
			return primaryClick(event)
		}
		const type = icon.type || port?.type
		if (type === basicType) basicIconClick(icon)
		else if (type === typeVariants.thermostat) basicIconClick(icon)
	}
	const containerClick = secondaryClick || iconClick
	return <div onClick={containerClick}
							className={`${isSmall ? 'iconSmallContainer' : 'iconContainer'}${selected ? ' iconSelected' : ''} clickable`}>
		<Details details={details} portKey={`${icon.key}`} portModule={`${port.moduleIndex + 1} ${name || type}`}/>
		<div hidden={isSmall} className="iconDock">
			<Icon icon={icon} component="container" onClick={iconClick}/>
		</div>
		<div className="iconName">{`${icon.name || port.name || '---'}`}</div>
	</div>
}
