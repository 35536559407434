import React, {useState} from 'react'
import {Buttons} from '../../context/Buttons'
import './modalIcons.scss'
import {faActive, faBeatAndFade, faHot, faSolid, faSpin, iconKeys} from '../../../shared/faConstants'
import {isTrue} from '../../../shared/utils'
import {IconSelect, registerModal} from './Modal'

const types = Object.keys(iconKeys)

const options = [
	{title: 'solid', icon: `${faSolid} fa-circle`, tag: faSolid},
	{title: 'Fading', icon: `${faBeatAndFade} fa-balloon`, tag: faBeatAndFade},
	{title: 'Rotate', icon: `${faSpin} fa-rotate-right`, tag: faSpin},
	{title: 'is highlighted', icon: 'fa-highlighter', tag: faActive},
	{title: 'is HOT', icon: 'fa-fire', tag: faHot}
]

const resolveOptions = icon => options.map(({tag}) => icon.includes(tag))

const switchArray = index => (value, i) => index === i ? !value : value
const optionButton = (option, setOption) =>
	({title, icon}, index) => ({
		title,
		icon,
		onClick: () => setOption(option.map(switchArray(index))),
		selected: option[index]
	})

const typeButton = (type, setType) =>
	title => ({title, onClick: () => setType(title), selected: type === title})

const evaluate = (option, icon) => {
	const icons = options.map(({tag}, l) => option[l] && tag).filter(isTrue)
	icons.push(icon)
	if (!icons.includes(faSolid)) icons.splice(0, 0, 'fa-regular')
	return icons.join(' ')
}

registerModal(IconSelect, ({resolve, selected}) => {
	const {icon = ''} = selected
	console.log("-> render ModalIcons.js", {icon, iconKeys})

	const [type, setType] = useState(types[0])
	const [option, setOption] = useState(resolveOptions(icon))

	const selectedIcon = options
		.reduce((icon, {tag}) => icon.replace(tag, ''), icon)
		.replace('fa-regular', '')
		.trim()

	const buttons = [
		...options.map(optionButton(option, setOption)),
		...types.map(typeButton(type, setType))
	]

	return <div className="ModalIcons">
		<h1>Choose icon</h1>
		<Buttons buttons={buttons}/>
		<div className="icons">
			{iconKeys[type].map(faIcon =>
				<span title={faIcon} key={faIcon}
							className={`${evaluate(option, faIcon)} ${faIcon === selectedIcon ? 'sel' : ''}`}
							onClick={() => resolve(evaluate(option, faIcon))}/>
			)}
		</div>
	</div>
})
