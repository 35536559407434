import React from 'react'
import {portKeyType} from '../../../shared/portUtils'

const reduceKeys = (keys) => {
	const counts = {di: 0, do: 0, ai: 0, ao: 0}
	for (const key of keys) {
		counts[portKeyType(key)]++
	}
	return counts
}

export const ModulePortTypes = ({keys, onClick}) => {
	if (!keys) {
		return null
	}

	const counts = reduceKeys(keys)

	return <span className={`portCount ${onClick ? 'clickable' : ''}`} onClick={onClick}>
		<span className="count">{counts.di}</span>
		<span className="fa-regular fa-times"/>
		<span className="type">di</span>
		<span className="count">{counts.do}</span>
		<span className="fa-regular fa-times"/>
		<span className="type">do</span>
		<span className="count">{counts.ai}</span>
		<span className="fa-regular fa-times"/>
		<span className="type">ai</span>
		<span className="count">{counts.ao}</span>
		<span className="fa-regular fa-times"/>
		<span className="type">ao</span>
	</span>
}

