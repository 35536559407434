import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {elementPosition} from '../../custom/dom'
import {Icon} from '../reusable/Icon'
import {finishEvent} from '../../custom/eventUtils'
import {styleSelector} from '../../state/reducers/visual'
import {controlsSelector} from '../../state/reducers/control'

const ibSize = 32
const mainStyle = (padding, stateWidth, stateHeight) => {
	const doublePadding = 2 * padding
	const left = -padding
	const top = -padding
	const width = stateWidth > 0 ? stateWidth + doublePadding : doublePadding
	const height = stateHeight > 0 ? stateHeight + doublePadding : doublePadding
	return {left, top, width, height}
}

const percentageToStyle = (icon, multiplier, shift, maxHeight) => {
	const [leftPercentage, topPercentage] = icon.b || [50, 50]
	const left = Math.round(leftPercentage * multiplier) + shift
	const top = Math.min(Math.round(topPercentage * multiplier), maxHeight) + shift
	return {left, top}
}

const positionToPercentage = (left, top, multiplier, leftShift, rightShift) => {
	const leftPercentage = Math.round((left - leftShift) * 100 / multiplier) / 100
	const topPercentage = Math.round((top - rightShift) * 100 / multiplier) / 100
	return [leftPercentage, topPercentage]
}

const getPercentage = (e, div, multiplier, padding) => {
	const {x, y, pageX, pageY} = e.nativeEvent
	// const {offsetX, offsetY} = e.nativeEvent
	const {left, top} = elementPosition(div)
	const fromLeft = x || pageX
	const fromTop = y || pageY
	// const offset = positionToPercentage(offsetX, offsetY, multiplier, padding, padding)
	return positionToPercentage(fromLeft - left, fromTop - top, multiplier, padding, padding)
}

const emptyOnClick = () => null
const MovableControl = ({control, multiplier, shift, height, setter}) => {
	setter.set = useState()[1]
	if (!control) return null
	const style = percentageToStyle(control, multiplier, shift, height)
	return <Icon onClick={emptyOnClick} style={style} icon={control} component="visual" selected/>
}

export const VisualIcons = ({control, controlIndex, padding}) => {
	const div = useRef(null)
	const style = useSelector(styleSelector)
	const controls = useSelector(controlsSelector)
	const [setter] = useState({})
	const {width = 0, height = 0} = style || {}

	console.log("-> render VisualIcons.js", style)

	const multiplier = width > 0 ? width / 100 : 0.01
	const shift = padding - (ibSize >> 1)

	const showPercentage = control
		? (e) => {
			const multiplier = width > 0 ? width / 100 : 0.01
			const b = getPercentage(e, div.current, multiplier, padding)
			console.log(`Registered cursor position is [${b}]`)
			control.b = b
			setter.set(b)
			setter.move = true
		}
		: null

	const endPercentage = control
		? (e) => {
			if (setter.move) {
				finishEvent(e)
				setter.move = false
			}
		}
		: null

	const changePercentage = control
		? (e) => {
			if (setter.move) {
				finishEvent(e)
				const multiplier = width > 0 ? width / 100 : 0.01
				control.b = getPercentage(e, div.current, multiplier, padding)
				setter.set(control.b)
			}
		}
		: null

	return <div ref={div} className="visualIcons" style={mainStyle(padding, width, height)}
							onMouseDown={showPercentage} onMouseUp={endPercentage} onMouseMove={changePercentage}
							onTouchStart={showPercentage} onTouchEnd={endPercentage} onTouchMove={changePercentage}>
		{controls.map((icon, index) => {
				if (controlIndex === index) return null
				const style = percentageToStyle(icon, multiplier, shift, height)
				return <Icon key={`${index}`} style={style} icon={icon} component="visual"/>
			}
		)}
		<MovableControl control={control} multiplier={multiplier} shift={shift} height={height} setter={setter}/>
	</div>
}
