import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {VisualIcons} from './VisualIcons'
import {elementSize, parent, sameSize} from '../../custom/dom'
import {resize} from '../../state/actions/visualAction'
import {apiImgUrl} from '../../custom/serverApi'
import {imageSelector, sectionSizeSelector, sizeSelector, styleSelector} from '../../state/reducers/visual'
import './visual.scss'

const options = {padding: 40, units: 0}

export const Visual = ({control, controlIndex}) => {
	const section = useRef(null)
	const dispatch = useDispatch()
	const size = useSelector(sizeSelector)
	const sectionSize = useSelector(sectionSizeSelector)
	const style = useSelector(styleSelector)
	const image = useSelector(imageSelector)
	const [canResize, setCanResize] = useState(true)
	useEffect(() => {
		const computedSize = elementSize(section.current)
		console.log('111111', {sectionSize, computedSize})
		if (canResize && !sameSize(sectionSize, computedSize)) {
			dispatch(resize(size, computedSize, options))
			setCanResize(false)
		}
	})

	const onLoad = size ? null : ({target}) => dispatch(resize(elementSize(target), elementSize(parent(target)), options))

	console.log("-> render Visual.js", {size, sectionSize, style})

	return <section ref={section} className="fullHeight">
		{image && <img alt="map" className="floorImage" src={apiImgUrl(image)} onLoad={onLoad} style={style}/>}
		<div className="floorImage" style={style}>
			<VisualIcons padding={options.padding} control={control} controlIndex={controlIndex}/>
		</div>
	</section>
}
