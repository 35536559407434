
export const resizeWindowAction = "WINDOW/RESIZE"
export const resizeWindow = (width, height) => ({type: resizeWindowAction, screen: {width, height}})

export const receivedAllLogsAction = "MAINTENANCE/LOGS"
export const receivedAllLogs = (logs) => ({type: receivedAllLogsAction, logs})

export const receivedNewLogsAction = "MAINTENANCE/ADD_LOGS"
export const receivedNewLogs = (logs) => ({type: receivedNewLogsAction, logs})

export const toggleScrollingAction = "MAINTENANCE/TOGGLE_SCROLLING"
export const toggleScrolling = () => ({type: toggleScrollingAction})

export const toggleDebugAction = "MAINTENANCE/TOGGLE_DEBUG"
export const toggleDebug = () => ({type: toggleDebugAction})

export const toggleTimeAction = "MAINTENANCE/TOGGLE_TIME"
export const toggleTime = () => ({type: toggleTimeAction})

export const serverConnectionAction = "SERVER/CONNECTION"
export const serverConnection = (connected) => ({type: serverConnectionAction, connected})
