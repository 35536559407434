import React from 'react'
import {useSelector} from 'react-redux'
import {IconContainer} from '../IconContainer'
import {ControlSelect, onlyHide, openModal} from './Modal'
import {finishEvent} from '../../../custom/eventUtils'
import {portMapSelector} from '../../../state/reducers/control'
import {eventValue} from '../../inputUtils'
import {basicType} from '../../../shared/faConstants'
import {onOffAuto} from '../../../shared/portConstants'
import {typeVariants} from '../../../shared/portUtils'
import './commandEditor.scss'

const timedCommands = {
	"linear_ms": "linear",
	"slow_ms": "slow",
	"fast_ms": "fast"
}

const set_off_ms = "set_off_ms"
const set_on_ms = "set_on_ms"
const switch_ms = "switch_ms"
const linear_ms = "linear_ms"
const slow_ms = "slow_ms"
const fast_ms = "fast_ms"
const timeouts = [set_off_ms, set_on_ms, switch_ms]
const timeoutsSimple = [0, 1, 's']
const linears = [linear_ms, slow_ms, fast_ms]
const getCommand = ({isBasic, key, cmd, value, timed, interval, step_ms, timeout}) => {
	if (timed) {
		if (isBasic) {
			const v = `${value}`
			if (v === "0") {
				return `${key}:${set_off_ms}/${timeout}`
			}
			if (v === "1") {
				return `${key}:${set_on_ms}/${timeout}`
			}
			return `${key}:${switch_ms}/${timeout}`
		}
		const linearCommand = linears.includes(cmd) ? cmd : linear_ms
		if (step_ms > 0) {
			return `${key}:${linearCommand}/${value}/${interval}/${step_ms}`
		}
		return `${key}:${linearCommand}/${value}/${interval}`
	}
	const timeoutsIndex = timeouts.indexOf(value)
	if (timeoutsIndex >= 0) return `${key}:${timeoutsSimple[timeoutsIndex]}`
	return `${key}:${value}`
}

const values = {
	"switch": "s",
	"set_off_ms": "0",
	"set_on_ms": "1",
	"switch_ms": "s",
	"linear_ms": "{param_0}",
	"slow_ms": "{param_0}",
	"fast_ms": "{param_0}"
}
const getValue = (cmd, parameters) => {
	const value = values[cmd] || cmd
	if (value == null) return '0'
	return `${value}`.replace("{param_0}", parameters[0])
}

const TimedBasic = ({c, timeout, onChange}) => {
	if (!onChange) {
		return <div className="timedParameter">undo after {timeout}</div>
	}
	const changeMethod = eventValue(timeout => c({timeout}))
	return <div className="timedParameter">
		<label>
			undo after: <input title="timeout in [ms]" type="text" value={timeout} onChange={changeMethod}/>
		</label>
	</div>
}

const NumberValue = ({c, value}) => <div className="basicValues">
	set to: <input type="text" title="value" value={value} onChange={eventValue(value => c({value}))}/>
</div>

const TimedValue = ({c, interval, step_ms}) => {
	return <div className="timedParameter">
		<select onChange={eventValue(cmd => c({cmd}))}>
			{Object.entries(timedCommands).map(([value, title]) =>
				<option key={value} value={value}>{title}</option>
			)}
		</select>
		<input title="duration in [ms]" type="text" value={interval} onChange={eventValue(interval => c({interval}))}/>
		{' by '}
		<input title="step in [ms]" type="text" value={step_ms} onChange={eventValue(step_ms => c({step_ms}))}/>
	</div>
}

const BasicValueOption = ({title, className, c, actual, value}) => {
	return <div title={title} className={`switch ${actual === value ? 'sel' : ''}`} onClick={() => c({value})}>
		<span className={`${className} clickable`}/>
	</div>
}

const BasicValue = ({c, icon, value}) => {
	const {icons = []} = icon
	return <div className="basicValues">
		<BasicValueOption title="switch it" className="fa-regular fa-arrow-right-arrow-left" c={c}
											actual={value} value="s"/>
		<BasicValueOption title="turn off" className={icons[0]} c={c} actual={value} value="0"/>
		<BasicValueOption title="turn on" className={icons[1]} c={c} actual={value} value="1"/>
	</div>
}

const OnOffAutoValue = ({c, value}) => <div className="basicValues">
	<BasicValueOption title="set to auto" className="fa-regular fa-gears" c={c}
										actual={value} value={`${onOffAuto.auto}`}/>
	<BasicValueOption title="turn off" className="fa-regular fa-toggle-off" c={c} actual={value}
										value={`${onOffAuto.off}`}/>
	<BasicValueOption title="turn on" className="fa-regular fa-toggle-on" c={c} actual={value} value={`${onOffAuto.on}`}/>
</div>


export const CommandEditor = ({command, onChange, remove}) => {
	const portMap = useSelector(portMapSelector)

	const [key, cmdExpression] = command ? command.split(':') : []
	const [cmd, ...parameters] = cmdExpression ? cmdExpression.split('/') : []
	const port = portMap[key]
	const {type} = port || {}
	const isBasic = type === basicType
	const value = getValue(cmd, parameters)
	const hasTimeout = timeouts.includes(cmd)
	const hasLinear = linears.includes(cmd)
	const timed = hasTimeout || hasLinear
	const timeout = hasTimeout ? parameters[0] : 1000
	const interval = parameters[1] || 1000
	const step_ms = parameters[2] || 0
	console.log('CommandEditor', {command, type})
	const c = (o) => {
		const params = {isBasic, key, cmd, value, timed, interval, step_ms, timeout, ...o}
		const newCommand = getCommand(params)
		console.log('changing', {o, params, newCommand})
		onChange(newCommand)
		return newCommand
	}

	const changeIcon = () => openModal(ControlSelect, {key})
		.then(key => c({key, isBasic: portMap[key].type === basicType}))
		.catch(onlyHide)

	return <div className="commandEditor" title={command} onClick={e => finishEvent(e)}>
		<IconContainer icon={port} primaryClick={changeIcon} details isSmall/>
		<div className="action">
			{/*
			<span title="now" onClick={() => sendCommands(command)} className="fa-regular fa-hand-point-right clickable"/>
*/}
			<span className={`fa-regular fa-clock ${timed ? 'sel' : ''}`} onClick={() => c({timed: !timed})}/>
			<span className="fa-regular fa-trash" onClick={remove}/>
		</div>
		{type !== basicType ? type !== typeVariants.onOffAuto
				? <NumberValue c={c} value={value}/>
				: <OnOffAutoValue c={c} value={value}/>
			: <BasicValue c={c} icon={port} value={value}/>
		}
		{timed ? type !== basicType ? type !== typeVariants.onOffAuto
					? <TimedValue c={c} interval={interval} step_ms={step_ms}/>
					: null // onOffAuto doesn't support timing
				: <TimedBasic c={c} timeout={timeout} onChange={onChange}/>
			: null
		}
	</div>
}
