export const isTrue = item => !!item

export const equals = (a, b) => {
  if (a === b) return true
  if (!a || !b) return false
  if (typeof a === 'object' && typeof b === 'object') {
    const entries = Object.entries(a)
    return entries.length === Object.keys(b).length
      && entries.find(([key, value]) => !equals(value, b[key])) === undefined
  }
  return false
}

export const isNotNull = i => i != null
