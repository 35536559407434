import {
	configurationDataType,
	loginSuccessType,
	newModuleAction,
	removedModuleAction,
	unregisteredDevicesAction
} from '../actions/loginAction'
import {
	addRoomAction,
	changeIconAction,
	changePortAction,
	changeRoomAction,
	changeRoomNameAction,
	changeRoomsAction,
	placeNewIconAction,
	removeIconAction,
	saveModuleAction
} from '../actions/contextAction'
import {
	changeTimerAction,
	changeTimerActiveAction,
	changeTimerDaysAction,
	changeTimerTimeAction,
	removeTimerAction
} from '../actions/timersActions'
import {isSame} from '../../view/forms/forms'
import {isTrue} from '../../shared/utils'

const initialState = {
	rooms: [],
	controls: [],
	timers: [],
	modules: [],
	devices: [],
	portMap: {},
	customParameter: {},
	portMetadata: {}

}
// key = key of the port
// port = {key, name, type, moduleIndex , ...modulePort}
// portMap = {key: port}
// customParameter = {moduleType: {parameterKey: {type, regVal, defaultValue}}}
// portMetadata = {key: description}
// room = {name: ""}
// module = {name, type, keys, parameters: {key: value}, extraActions: {[name]: params}}
// control = {key:"di01", type: null, b:[x,y], roomIndex: 0}
// b = relative percentage position: left=x*width/100

export const newTimer = (timers, i) => {
	const name = `timer ${timers.length + 1}`
	const index = Math.min(timers.length, i)
	const now = new Date()
	const configuration = {days: {}, time: {hours: now.getHours(), minutes: now.getMinutes()}}
	return {name, index, configuration, commands: "", active: false, type: "timer"}
}

const mapRooms = (newRooms) => newRooms.map(({name}, configurationIndex) => ({name, configurationIndex}))

const sameArray = (oldArray, newArray) => {
	if (oldArray.length !== newArray.length) return false
	return !oldArray.find((item, i) => item !== newArray[i])
}

/*
const deleteDeviceKeys = (obj, ...keys) => {
	keys.forEach(key => {
		if (key !== "subtype") {
			delete obj[key]
		}
	})
	return obj
}

const setKey = (obj, ...keyValues) => {
	const copy = obj ? {...obj} : {}
	keyValues.forEach(({key, value}) => copy[key] = value)
	return copy
}

*/

const mergeRooms = (oldRooms, newRooms) => {
	if (!newRooms) newRooms = []
	if (oldRooms.length !== newRooms.length || oldRooms.find(({name}, index) => name !== newRooms[index].name)) {
		return mapRooms(newRooms)
	}
	return oldRooms
}

const mergeControls = (oldControls, rooms) => {
	let i = 0
	let changed = false
	const newControls = []
	rooms && rooms.forEach(({bulbs}, roomIndex) => bulbs.forEach((bulb) => {
		const control = oldControls[i++]
		if (!control || !isSame(bulb, control)) {
			changed = true
			newControls.push({...bulb, roomIndex})
		} else {
			newControls.push(control)
		}
	}))
	return changed || newControls.length !== oldControls.length ? newControls : oldControls
}

const createModule = (module, keys) => {
	const {type} = module
	const name = module.name || ""
	const parameters = module.parameters
	const extraActions = module.extraActions || {}
	return {type, name, keys, parameters, extraActions}

}

const portMapAndModules = (oldPortMap, oldModules, oldPortMetadata, dataModules) => {
	let ports = 0
	const newPortMap = {}
	const newModules = []
	const newPortMetadata = {}
	let changed = false

	const mapPort = (port, moduleIndex, oldPortMap) => {
		const {key} = port
		const oldPort = oldPortMap[key]
		const newPort = {...port, moduleIndex}
		if (!oldPort || !isSame(newPort, oldPort)) {
			changed = true
			newPortMap[key] = newPort
		} else {
			newPortMap[key] = oldPort
		}
		ports--
		return key
	}

	ports += Object.values(oldPortMap).length
	dataModules.forEach((module, moduleIndex) => {
		const keys = module.ports.map(port => mapPort(port, moduleIndex, oldPortMap))

		const oldModule = oldModules[moduleIndex]

		if (oldModule) {
			oldModule.parameters = module.parameters
			oldModule.name = module.name
			oldModule.extraActions = module.extraActions || {}
		}

		if (!oldModule || oldModule.type !== module.type || !sameArray(oldModule.keys, keys)) {
			newModules.push(createModule(module, keys))
		} else {
			newModules.push(oldModule)
		}

		// collectModuleTitles(newPortMetadata, type, keys, module.parameters)
	})
	changed = changed || ports !== 0
	const portMap = changed ? newPortMap : oldPortMap
	const modules = changed ? newModules : oldModules
	const portMetadata = !isSame(newPortMetadata, oldPortMetadata) ? newPortMetadata : oldPortMetadata
	return {portMap, modules, portMetadata}
}

const reindexTimers = timers => timers.map((timer, index) => ({...timer, index}))
const removeTimer = (state, timerToRemoveIndex) => reindexTimers(
	state.timers.filter((timer, index) => timerToRemoveIndex !== index)
)

const cleanRemovedRooms = (rooms) => {
	const roomIndexes = {}
	rooms.forEach((room, newIndex) => {
		if (room.roomIndex != null) roomIndexes[room.roomIndex] = newIndex
	})
	return control => {
		const roomIndex = roomIndexes[control.roomIndex]
		return roomIndex != null ? {...control, roomIndex} : null
	}
}
const trimRoom = ({name}) => ({name})

const normalizeTimer = ({name = "", configuration, commands = "", active = false}, index) =>
	({name, configuration, commands, active, type: "timer", index})

export default function control(state = initialState, action = {}) {
	switch (action.type) {
		case changeRoomNameAction:
			return {
				...state,
				rooms: state.rooms.map((room, index) => action.roomIndex === index ? {...room, name: action.name} : room)
			}
		case changeRoomAction:
			return {
				...state,
				rooms: state.rooms.map((room, index) => action.roomIndex === index ? action.room : room)
			}
		case changeRoomsAction:
			return {
				...state,
				controls: state.controls.map(cleanRemovedRooms(action.rooms)).filter(isTrue),
				rooms: action.rooms.map(trimRoom)
			}
		case removeTimerAction:
			return {
				...state,
				timers: removeTimer(state, action.index)
			}
		case changeTimerAction: {
			if (action.timer.index === state.timers.length) {
				return {
					...state,
					timers: [...state.timers, action.timer]
				}
			}
			return {
				...state,
				timers: state.timers.map((timer) => action.timer.index === timer.index ? action.timer : timer)
			}
		}
		case changeTimerTimeAction:
		case changeTimerActiveAction:
		case changeTimerDaysAction: {
			return {
				...state,
				timers: state.timers.map((timer, index) => {
					if (action.index === index) {
						const actual = timer.configuration
						const {
							time = actual.time, days = actual.days, name = timer.name, commands = timer.commands,
							active = timer.active != null ? timer.active : true
						} = action
						return {
							...timer, name, commands, active, configuration: {
								...timer.configuration, time, days
							}
						}
					}
					return timer
				})
			}
		}
		case addRoomAction:
			return {
				...state,
				rooms: [
					...state.rooms,
					{name: action.name}
				]
			}
		case placeNewIconAction:
			return {
				...state,
				controls: [...state.controls, action.control]
			}
		case removeIconAction:
			return {
				...state,
				controls: state.controls.filter((_, index) => index !== action.controlIndex)
			}
		case changeIconAction:
			return {
				...state,
				controls: state.controls.map((item, i) => i === action.controlIndex ? action.item : item)
			}
		case changePortAction:
			return {
				...state,
				portMap: {...state.portMap, [action.item.key]: action.item}
			}
		case configurationDataType:
			const {data} = action
			const {customParameter} = data
			const {
				portMap, modules, portMetadata
			} = portMapAndModules(state.portMap, state.modules, state.portMetadata, data.modules)
			const rooms = mergeRooms(state.rooms, data.rooms)
			const controls = mergeControls(state.controls, data.rooms)
			const timers = (data.timers || []).map(normalizeTimer)
			return {...state, rooms, controls, portMap, modules, portMetadata, timers, customParameter}
		case unregisteredDevicesAction:
			return {
				...state,
				devices: action.data
			}
		case saveModuleAction: {
			const {index} = action
			if (index === -1) {
				break
			}
			const modules = state.modules
			if (modules) {
				return {
					...state,
					modules: modules.map((item, i) => i === index ? action.module : item)
				}
			}
			break
		}
		case newModuleAction: {
			const module = action.data
			const moduleIndex = state.modules.length
			const portMap = {...state.portMap}
			const keys = module.ports.map(port => {
				const {key} = port
				portMap[key] = {...port, moduleIndex}
				return key
			})
			const modules = [...state.modules, createModule(module, keys)]
			return {
				...state,
				portMap,
				modules
			}
		}
		case removedModuleAction: {
			const index = action.data
			const module = state.modules[index]
			const portMap = {...state.portMap}
			module.keys.forEach(key => {
				delete portMap[key]
			})
			const modules = state.modules.filter((_, i) => i !== index)
			return {
				...state,
				portMap,
				modules
			}
		}
		case loginSuccessType:
			return state
		default:
			return state

	}
	return state
}


export const portSelector = key => state => state.control.portMap[key]
export const portMapSelector = state => state.control.portMap
export const controlsSelector = state => state.control.controls
export const roomsSelector = state => state.control.rooms
export const timersSelector = state => state.control.timers
export const modulesSelector = state => state.control.modules
export const moduleSelector = index => state => state.control.modules[index]
export const devicesSelector = state => state.control.devices
export const customParameterSelector = state => state.control.customParameter
export const portMetadataSelector = state => state.control.portMetadata
export const moduleTypeSelector = moduleIndex => state => state.control.modules[moduleIndex]
