import {millisecondUnit, onOffAuto} from './portConstants.js'

const digital = {
  di: true,
  do: true,
  ai: false,
  ao: false
}

const input = {
  di: true,
  do: false,
  ai: true,
  ao: false
}

export const portTypes = {
  di: "di",
  do: "do",
  ai: "ai",
  ao: "ao"
}

const none = "none"
const light = "light"
const heat = "heat"
const fan = "fan"
const athome = "athome"
const night = "night"
const run = "run"

const meter = "meter"
const powerMeter = "powerMeter"
const temp = "temp"
const thermostat = "thermostat"
const shade = "shade"
const timer = "timer"
const rgbLight = "rgbLight"

export const typeVariants = {
  switch: none,
  light,
  heat,
  fan,
  athome,
  night,
  run,
  onOffAuto: onOffAuto.fieldName,
  meter,
  powerMeter,
  temp,
  thermostat,
  shade,
  timer,
  rgbLight
}

export const portKeyType = key => typeof key === 'string' ? key.substring(0, 2) : null

export const portNumber = key => typeof key === 'string' ? +key.substring(2) : null

export const isDigitalByType = keyType => digital[keyType]

export const isDigital = key => isDigitalByType(portKeyType(key))

export const isInput = key => input[portKeyType(key)]

export const isInputByType = type => input[type]

const joinNonZero = (...items) => {
  let result = ''
  while (items.length) {
    const unit = items.pop()
    const value = Math.floor(items.pop())
    if (value) result += `${value}${unit} `
  }
  return result.trim()
}
export const showValue = (value, units) => {
  if (units === millisecondUnit) {
    const seconds = value / 1000
    const minutes = seconds / 60
    const hours = minutes / 60
    const days = hours / 24
    const ms = value % 1000
    return joinNonZero(ms, 'ms', seconds % 60, 's', minutes % 60, 'm', hours % 24, 'h', days, 'd')
  }
  if (Array.isArray(units)) {
    return value + units.join('/')

  }
  if (typeof value === 'object') {
    return JSON.stringify(value)
  }
  return value
}
