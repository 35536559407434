const INIT_STATE = {
	name: "TouchPanel",
	activeModules: []
}

const meta = (state = INIT_STATE, action) => {
	switch (action.type) {
		case 'modules/active':
			return {
				...state,
				activeModules: action.data || []
			}
		default:
			return state
	}
}

export default meta


export const metaNameSelector = state => state.meta.name
export const activeModulesSelector = state => state.meta.activeModules
export const activeModuleSelector = index => state => state.meta.activeModules[index]
