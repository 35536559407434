import {registerCallAction} from 'redux-action-processor'
import {doLoginType, loginFailed, loginSuccess, loginSuccessType} from '../state/actions/loginAction'
import {initializeConfiguration, loginUser} from '../custom/serverApi'

const deviceName = () => {
	return `${window.navigator.appName} (${window.navigator.platform})`
}

export function initLoginLogic() {
	registerCallAction(doLoginType, async ({username, password, rememberMe, name: givenName}) => {
		const name = rememberMe && (givenName || deviceName())
		return await loginUser({username, password, name})
	})
		.thenDispatchResult(data => loginSuccess(data))
		.thenDispatchError((error) => loginFailed(error))


	registerCallAction(loginSuccessType, async () => await initializeConfiguration())
}
