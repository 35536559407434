export const changeIconAction = "CONTEXT/ICON_CHANGE_PROPERTY"
export const changeIconProperty = (item, controlIndex = -1) => ({type: changeIconAction, item, controlIndex})

export const changePortAction = "CONTEXT/PORT_CHANGE_PROPERTY"
export const changePortProperty = (item) => ({type: changePortAction, item})

export const addRoomAction = "CONTEXT/ADD_ROOM"
export const addRoom = (room, name) => ({type: addRoomAction, room, name})

export const changeRoomNameAction = "CONTEXT/RENAME_ROOM"
export const changeRoomName = (roomIndex, name) => ({type: changeRoomNameAction, roomIndex, name})

export const changeRoomAction = "CONTEXT/CHANGE_ROOM"
// export const changeRoom = (room, roomIndex) => ({type: changeRoomAction, room, roomIndex})

export const changeRoomsAction = "CONTEXT/CHANGE_ROOMS"
export const changeRooms = (rooms) => ({type: changeRoomsAction, rooms})

export const removeIconAction = "CONTEXT/REMOVE_ICON"
export const removeIcon = (controlIndex) => ({type: removeIconAction, controlIndex})

export const removeModuleAction = "CONTEXT/REMOVE_MODULE"
export const removeModule = (moduleIndex) => ({type: removeModuleAction, moduleIndex})

export const activateModuleAction = "CONTEXT/ACTIVATE_MODULE"
export const activateModule = (moduleIndex) => ({type: activateModuleAction, moduleIndex})

export const deactivateModuleAction = "CONTEXT/DEACTIVATE_MODULE"
export const deactivateModule = (moduleIndex) => ({type: deactivateModuleAction, moduleIndex})

export const addModuleAction = "CONTEXT/ADD_MODULE"
export const addModule = (module, index) => ({type: addModuleAction, module, index})

export const saveModuleAction = "CONTEXT/SAVE_MODULE"
export const saveModule = (module, moduleType, index) => ({type: saveModuleAction, module, moduleType, index})

export const placeNewIconAction = "ICON/PLACE_NEW"
export const placeNewIcon = (control) => ({type: placeNewIconAction, control})
