import {configurationDataType} from '../actions/loginAction'
import {editCodeType, hintCodeKeyType} from '../actions/codeActions'

const Identifier = "Identifier"
const Literal = "Literal"
const ObjectExpression = "ObjectExpression"
const Property = "Property"

const initialState = {
	blockTypes: {},
	blocks: [],
	code: "",
	selectedKey: null,
	editedCode: null
}

const createFieldFromEntity = ([type, key]) => ({type, key})
const createBlocks = blocks => blocks.map(block => {
	return {...block, fields: Object.entries(block.fields).map(createFieldFromEntity)}
})

const createBlockType = ({typeName, params}) => {
	const inputs = []
	const outputs = []
	const fields = []
	const collect = ({
										 description, field, inputName, outputName,
										 isOptional = false,
										 mustBeArray = false,
										 units
									 }) => {
		if (field) {
			fields.push({name: field, isOptional, mustBeArray, description, units})
			return
		}
		if (inputName) {
			inputs.push({name: inputName, isOptional, mustBeArray, description})
			return
		}

		if (outputName) {
			outputs.push({name: outputName, isOptional, mustBeArray, description})
		}
	}
	params.forEach(param => {
		if (param.type === Literal) {
			if (param.field) collect(param)
			return
		}
		if (param.type === ObjectExpression) {
			param.properties.forEach(property => {
				if (property.type === Property) {
					// const {key: {name}, value: {description, inputName, outputName, isOptional, keyType, mustBeArray, type}}
					// = property
					collect(property.value)
					return
				}
				if (property.type === Literal) {
					// const {description, inputName, outputName, isOptional, mustBeArray} = property
					collect(property)
				}
			})
			return
		}
		if (param.type === Identifier) {
			collect(param)
		}
	})
	return {typeName, inputs, outputs, fields, params}
}

const createBlockTypes = blockTypes => {
	const result = {}
	Object.entries(blockTypes || [])
		.forEach(([key, value]) => result[key] = createBlockType(value))
	return result
}


export default function code(state = initialState, action = {}) {
	const type = action.type

	if (type === editCodeType) {
		return {
			...state,
			editedCode: action.code
		}
	}
	if (type === configurationDataType) {
		return {
			...state,
			blocks: createBlocks(action.data.blocks),
			code: action.data.program,
			blockTypes: createBlockTypes(action.data.blockTypes)
		}
	}
	if (type === hintCodeKeyType) {
		return {
			...state,
			selectedKey: action.key
		}
	}
	return state
}

export const blockTypesSelector = state => state.code.blockTypes
export const blocksSelector = state => state.code.blocks
export const codeSelector = state => state.code.code
export const editedCodeSelector = state => state.code.editedCode
