import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {sendAddUserAction, sendRefreshUsersAction} from '../../custom/serverApi'
import {UserContainer} from '../reusable/UserContainer'
import {authenticatedUsernameSelector} from '../../state/reducers/login.reducer'
import {usersSelector} from '../../state/reducers/maintenance'
import {onlyHide, openModal, StringValue} from '../reusable/context/Modal'

export const User = () => {
	const username = useSelector(authenticatedUsernameSelector)
	const users = useSelector(usersSelector) || []
	useEffect(() => {
		sendRefreshUsersAction()
	}, [username])

	console.log("-> render User.js", {users})

	const newUserClick = () => openModal(StringValue, {
		title: "Create user with username name",
		requireChange: true,
		value: '',
		okIcon: "fa-plus"
	})
		.then(username => {
			sendAddUserAction(username)
		})
		.catch(onlyHide)


	return <div className="user">
		<div className="caption">Users</div>
		{users.map(user => <UserContainer
				key={user.username}
				user={user}
				isMe={username === user.username}
				showHeader/>
		)}
		<div className="userContainer">
			<div className="userContainerIcon" onClick={newUserClick}>
				<span className="fa-solid fa-plus"/>
			</div>
		</div>
	</div>
}
