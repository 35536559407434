import {useSelector} from 'react-redux'
import {ModuleSelect, registerModal} from './Modal'
import {modulesSelector, portMapSelector} from '../../../state/reducers/control'
import {finishEvent} from '../../../custom/eventUtils'
import {lightKeysIcons} from '../../../shared/faConstants'
import {isTrue} from '../../../shared/utils'
import './ModalModuleSelect.scss'
import {ModuleContainer} from '../../content/Modules'
import {activeModuleSelector} from '../../../state/reducers/meta'

const reMapObject = o => {
	const result = {}
	Object.entries(o).forEach(([key, value]) => result[value] = key)
	return result
}
const iconKeys = {
	light: reMapObject(lightKeysIcons)
}

const Module = ({item, resolve}) => {
	const active = useSelector(activeModuleSelector(item.key))
	const onClick = e => finishEvent(e) && resolve(item.result)
	return <ModuleContainer index={item.key} keys={item.keys} name={item.name} type={item.type} active={active}
													onClick={onClick} onActivationClick={onClick} filter={item.filter}/>
}
registerModal(ModuleSelect, ({resolve, selected}) => {
	const {defaultType} = selected
	const icons = iconKeys[defaultType]
	if (!icons) {
		resolve()
	}
	const modules = useSelector(modulesSelector)
	const portMap = useSelector(portMapSelector)

	const items = modules
		.map((module, key) => {
			const fields = module.keys
				.map(portKey => {
					const port = portMap[portKey]
					if (!port) return null
					const {icon, name} = port
					const field = icons[icon]
					if (!field) return null
					return {field, portKey, name, icon}
				})
				.filter(isTrue)
			if (!fields.length) return null
			const filter = key => fields.find(({portKey}) => portKey === key)
			const {name, type, keys} = module
			const result = {}
			fields.forEach(({field, portKey}) => result[field] = portKey)
			return {key, keys, name, type, fields, filter, result}
		})
		.filter(isTrue)

	console.log("-> render ModalModuleSelect.js", {defaultType, items})

	return <div>
		<h1>Choose item</h1>
		<div className="modules">
			{items.map((item) => <Module item={item} resolve={resolve}/>)}
		</div>
	</div>
})
