import React from 'react'
import newStore from './state/store'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import {TouchPanel} from './view/TouchPanel'
import {checkUser, registerStore, sendAction, sendCommands} from './custom/serverApi'
import {loginPrepared, loginSuccess} from './state/actions/loginAction'
import {resizeWindow} from "./state/actions/application"
import {initLoginLogic} from "./logic/login.logic"
import {initConfigurationLogic} from "./logic/configuration.logic"

// TODO implement pnotify support in the future

const initialState = () => {
	const state = sessionStorage.getItem("state")
	return state ? JSON.parse(state) : undefined
}

(async () => {
	const store = newStore(initialState())
	const {dispatch} = store
	registerStore(store)

	initLoginLogic()
	initConfigurationLogic(store)

	await new Promise(resolve => {
		render(
			<Provider store={store}>
				<TouchPanel/>
			</Provider>,
			document.getElementById('TouchPanel'),
			resolve
		)
	})

	window.addEventListener('resize', ({target}) => {
		const {innerWidth, innerHeight} = target
		dispatch(resizeWindow(innerWidth, innerHeight))
	})

	try {
		const user = await checkUser()
		if (user) {
			dispatch(loginSuccess(user))
		}
	} catch (e) {
		dispatch(loginPrepared())
	}


	window.sendCommands = sendCommands
	window.sendAction = sendAction
	window.store = store

})()

