export const basicSwitch = {
  off: 0,
  on: 1,
  switch: 's'
}

export const onOffAuto = {
  fieldName: "onOffAuto",
  on: 2,
  off: 0,
  auto: 1,
  cool: 3,
  2: "on",
  0: "off",
  1: "auto",
  3: "cool"
}

export const onOffOffAutoNextValue = value => {
  switch (value) {
    case onOffAuto.on:
      return onOffAuto.off
    case onOffAuto.off:
      return onOffAuto.auto
    default:
      return onOffAuto.on
  }
}

export const celsiusTempUnit = "°C"
export const fahrenheitTempUnit = "°F"
export const kelvinTempUnit = "K"
export const tempUnits = [celsiusTempUnit, fahrenheitTempUnit, kelvinTempUnit]

export const wattUnit = 'W'
export const kiloWattUnit = 'kW'
export const kiloJoulePerSecondUnit = 'kJ/s'
export const powerUnits = [wattUnit, kiloWattUnit, kiloJoulePerSecondUnit]

export const wattSecondUnit = 'Ws'
export const kiloWattHourUnit = 'kWh'
export const kiloJouleUnit = 'kJ'
export const energyUnits = [wattSecondUnit, kiloWattHourUnit, kiloJouleUnit]

export const dayUnit = 'd'
export const hourUnit = 'h'
export const minuteUnit = 'm'
export const secondUnit = 's'
export const millisecondUnit = 'ms'

export const timeUnits = [dayUnit, hourUnit, minuteUnit, secondUnit, millisecondUnit]

export const percentageUnit = '%'
export const permilUnit = '‰'
export const angleUnit = '°'
export const redUnit = 'r'
export const greenUnit = 'g'
export const blueUnit = 'b'
export const otherUnits = [percentageUnit, permilUnit, angleUnit, redUnit, greenUnit, blueUnit]

const celsiusRange = {unit: celsiusTempUnit, minValue: -10, maxValue: 60, step: 0.1}
const fahrenheitRange = {unit: fahrenheitTempUnit, minValue: 14, maxValue: 140, step: 0.2}
export const rangeByUnit = {
  C: celsiusRange,
  [celsiusTempUnit]: celsiusRange,
  F: fahrenheitRange,
  [fahrenheitTempUnit]: fahrenheitRange,
  K: {unit: kelvinTempUnit, minValue: 260, maxValue: 308, step: 0.1}
}

export const hasUnit = {
  temp: celsiusTempUnit,
  thermostat: celsiusTempUnit,
  powerMeter: wattUnit,
  meter: wattSecondUnit
}

export const shadeMovingValue = {
  still: 0,
  stop: 3,
  up: 1,
  down: 2
}
