import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {isDigital, typeVariants} from '../../shared/portUtils'
import {blueUnit, greenUnit, hasUnit, redUnit} from '../../shared/portConstants'
import {eventValue} from '../inputUtils'
import {IconContainer} from '../reusable/IconContainer'
import {
	basicIconClassNames,
	basicType,
	defaultIcons,
	findBasicIconTypeByClassName,
	lightKeysIcons
} from '../../shared/faConstants'
import {ControlSelect, IconSelect, ModuleSelect, onlyHide, openModal, UnitValue} from '../reusable/context/Modal'
import {editPort, editPortValue} from '../../features/edit'
import {portMapSelector} from '../../state/reducers/control'
import {finishEvent} from '../../custom/eventUtils'
import {simplePortOnClick} from '../reusable/Icon'
import './controlEditor.scss'

const customIcons = ['fa-regular fa-square', 'fa-solid fa-square']
const types = [
	{
		name: 'RGB Light',
		type: typeVariants.rgbLight,
		digital: false,
		port: false,
		className: 'fa-regular fa-palette'
	},
	{
		name: 'On/Off/Auto switch',
		type: typeVariants.onOffAuto,
		digital: false,
		port: true,
		className: 'fa-regular fa-traffic-light'
	},
	{
		name: 'Sun shades',
		type: typeVariants.shade,
		digital: false,
		port: false,
		className: 'fa-regular fa-shutters'
	},
	{
		name: 'Meter',
		type: 'meter',
		digital: false,
		port: true,
		className: 'fa-regular fa-meter'
	},
	{
		name: 'Thermometer',
		type: typeVariants.temp,
		digital: false,
		port: true,
		className: 'fa-regular fa-temperature-low'
	},
	{
		name: 'Thermostat',
		type: typeVariants.thermostat,
		digital: true,
		port: false,
		className: 'fa-regular fa-temperature-list'
	},
	{
		name: 'Thermostat',
		type: typeVariants.thermostat,
		digital: false,
		port: false,
		className: 'fa-regular fa-temperature-list'
	}
]

const CustomBasicIcon = ({item, setItem, port, defaultType}) => {
	const itemType = item.type || port?.type
	if (itemType !== basicType) return null

	return <div title={`Custom icons '${defaultType}'`} className={`item ${defaultType ? '' : 'sel'}`}>
		<span className={`fa-regular fa-sliders-simple`} onClick={() => setItem({...item, icons: customIcons})}/>
	</div>
}

const analyzeDigitalTypes = (item, port) => {
	const itemType = item.type || port?.type
	const itemIcons = item.icons || port?.icons || []
	const defaultType = itemType === basicType && findBasicIconTypeByClassName(itemIcons)
	const types = Object.entries(basicIconClassNames)
		.map(([type, [classNameOff, classNameOn]]) => ({type, classNameOff, classNameOn}))

	return {types, defaultType}
}
const DigitalTypes = ({item, setItem, digitalTypes}) => {
	if (!digitalTypes) return null
	return <>
		{digitalTypes.types.map(({type, classNameOff, classNameOn}) =>
			<div key={type} title={`Type ${type}`} className={`item-double ${type === digitalTypes.defaultType ? 'sel' : ''}`}
					 onClick={() => setItem({...item, type: basicType, icons: [classNameOff, classNameOn]})}>
				<span className={classNameOff}/>
				<span className={classNameOn}/>
			</div>
		)}
	</>
}

const CustomBasicIcons = ({item, setItem, port, digital}) => {
	const itemType = item.type || port?.type
	if (!digital || itemType !== basicType) return null
	const [firstIcon, secondIcon] = item.icons

	const chooseIcon = icon => openModal(IconSelect, {icon}).catch(onlyHide)

	return <>
		{!port ? <div className={`item ${item.inverted ? 'switched-on' : ''}`}>
			<span title="Invert value" className="fa-regular fa-rotate "
						onClick={() => setItem({...item, inverted: !item.inverted})}/>
		</div> : null}
		<div className="item-double">
			<span title="'off' icon" className={firstIcon}
						onClick={() => chooseIcon(firstIcon)
							.then(icon => icon && setItem({...item, icons: [icon, secondIcon]}))}/>
			<span title="'on' icon" className={secondIcon}
						onClick={() => chooseIcon(secondIcon)
							.then(icon => icon && setItem({...item, icons: [firstIcon, icon]}))}/>
		</div>
	</>
}

const IconSelector = ({item, setItem, port}) => {
	const iconType = defaultIcons[item.type || port?.type]
	if (!iconType) {
		return null
	}
	const isDefault = !item.icon
	const changeIcon = (icon) => {
		const newItem = {...item, icon}
		if (icon === iconType) delete newItem.icon
		setItem(newItem)
	}
	const resetIcon = isDefault ? null : () => changeIcon(iconType)

	const onClick = () => openModal(IconSelect, {icon: item.icon})
		.then(changeIcon)
		.catch(onlyHide)

	return <div className="item-double">
		<span title="Custom icon: default" className={`fa-regular fa-icons ${isDefault ? 'faActive' : ''}`}
					onClick={resetIcon}/>
		<span title="Change icon" className={item.icon || port?.icon || iconType} onClick={onClick}/>
	</div>
}

const UnitSelector = ({item, setItem, port}) => {
	const unitType = hasUnit[item.type || port?.type]
	if (!unitType) {
		return null
	}
	const isDefault = !item.unit
	const changeUnits = (unit) => {
		const newItem = {...item, unit}
		if (unit === unitType) delete newItem.unit
		setItem(newItem)
	}
	const unit = item.unit || port?.unit || unitType
	const resetUnits = isDefault ? null : () => changeUnits(unitType)
	const onClick = () => openModal(UnitValue, unit).then(changeUnits).catch(onlyHide)

	console.log("-> render UnitSelector", {unitType, item, unit})

	return <div className="item-double">
		<span title="Custom Units: default" className={`fa-regular fa-ruler-triangle ${isDefault ? 'faActive' : ''}`}
					onClick={resetUnits}/>
		<span title="Change Units" className="units" onClick={onClick}>{unit}</span>
	</div>
}

const ComplexType = ({analogType, item, setItem, port, itemType}) => {
	const {name, type, className} = analogType
	const onClick = () => {
		const newItem = {...item, type}
		if (type === port?.type) delete newItem.type
		setItem(newItem)
	}

	return <div key={className} className={`item ${itemType === type ? 'sel' : ''}`}>
		<span title={name} className={className} onClick={onClick}/>
	</div>
}

const ComplexTypes = ({item, setItem, port, digital}) => {
	const itemType = item.type || port?.type
	const availableAnalogTypes = types.filter(t => t.digital === digital && (t.port || port))
	return <>
		{availableAnalogTypes.map(analogType =>
			<ComplexType key={analogType.type} analogType={analogType} itemType={itemType} item={item} setItem={setItem}
									 port={port}/>
		)}
		<IconSelector item={item} setItem={setItem} port={port}/>
		<UnitSelector item={item} setItem={setItem} port={port}/>
	</>
}

const Types = ({item, setItem, port, digitalTypes}) => {
	const digital = !!digitalTypes
	return <div className="basic-icons">
		<DigitalTypes item={item} setItem={setItem} digitalTypes={digitalTypes}/>
		<ComplexTypes item={item} setItem={setItem} port={port} digital={digital}/>
		{digital && <CustomBasicIcon item={item} setItem={setItem} port={port} defaultType={digitalTypes.defaultType}/>}
		<CustomBasicIcons item={item} setItem={setItem} port={port} digital={digital}/>
	</div>
}

const fieldUnits = {
	redKey: redUnit,
	greenKey: greenUnit,
	blueKey: blueUnit
}
const fieldTypes = {
	onTempKey: typeVariants.temp,
	offTempKey: typeVariants.temp,
	airTempKey: typeVariants.temp,
	mediumTempKey: typeVariants.temp,
	//fanKey: typeVariants.fan,
	onOffAutoKey: typeVariants.onOffAuto
}
const fieldClassNames = {
	...lightKeysIcons,
	positionKey: "fa-solid fa-sort",
	tiltKey: "fa-solid fa-magic",
	onTempKey: "fa-solid fa-temperature-arrow-up",
	offTempKey: "fa-solid fa-temperature-arrow-down",
	airTempKey: "fa-solid fa-temperature-half",
	mediumTempKey: "fa-solid fa-heat",
	heatingKey: "fa-solid fa-heat",
	fanKey: "fa-solid fa-fan-table",
	onOffAutoKey: "fa-solid fa-toggle-on"
}
const fieldTitles = {
	positionKey: "Blinds position",
	tiltKey: "Blinds tilt",
	hueKey: "Light hue color",
	brightnessKey: "Light brightness",
	saturationKey: "Light saturation",
	temperatureKey: "Light temperature",
	redKey: "Red light brightness",
	greenKey: "Green light brightness",
	blueKey: "Blue light brightness",
	onTempKey: "High temperature value",
	offTempKey: "Low temperature value",
	airTempKey: "Room thermometer",
	mediumTempKey: "Heater thermometer",
	heatingKey: "Heating switch",
	fanKey: "Fan",
	onOffAutoKey: "Power switch"
}

const ControlPickerContainer = ({portKey, title, icon, onClick, onClearClick}) => {
	const dispatch = useDispatch()
	const portMap = useSelector(portMapSelector)
	const secondaryClick = onClearClick
		? (e) => finishEvent(e) && editPort(dispatch, portKey)
		: (e) => finishEvent(e) && portKey && simplePortOnClick(portMap[portKey])
	return <div title={title} className="ControlPicker" onClick={onClick}>
		<div className="picker-icon"><span className={icon}/></div>
		<div className="picker-title">{title}</div>
		<div className="picker-clear" onClick={onClearClick}>{onClearClick && <span className="fa-regular fa-trash"/>}</div>
		{portKey
			? <IconContainer icon={portMap[portKey]} secondaryClick={secondaryClick} details/>
			: <div className="picker-plus"><span className="fa-solid fa-plus"/></div>}
	</div>
}
const ControlPicker = ({item, setItem, port, field}) => {
	const key = item[field] || (port && port[field])
	const unit = fieldUnits[field]
	const type = fieldTypes[field]

	const onClick = () => openModal(ControlSelect, {key, unit, type})
		.then(key => setItem({...item, [field]: key}))
		.catch(onlyHide)
	const onClearClick = key ? e => finishEvent(e) && setItem({...item, [field]: null}) : null

	return <ControlPickerContainer portKey={key} title={fieldTitles[field]} icon={fieldClassNames[field]}
																 onClick={onClick} onClearClick={onClearClick}/>
}

const TypeContext = ({item, setItem, port, defaultType}) => {
	const itemType = item.type || port?.type

	console.log("-> render TypeContext", {itemType})

	if (itemType === typeVariants.rgbLight) {
		return <>
			<ControlPicker item={item} setItem={setItem} port={port} field="redKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="greenKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="blueKey"/>
		</>
	}

	if (itemType === typeVariants.shade) {
		return <>
			<ControlPicker item={item} setItem={setItem} port={port} field="positionKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="tiltKey"/>
		</>
	}

	if (itemType === typeVariants.thermostat) {
		const hasTemperatureSwitch = isDigital(item.key)
		if (hasTemperatureSwitch) return <>
			<ControlPicker item={item} setItem={setItem} port={port} field="onTempKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="offTempKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="airTempKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="mediumTempKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="heatingKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="fanKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="onOffAutoKey"/>
		</>
		return <>
			<ControlPicker item={item} setItem={setItem} port={port} field="onTempKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="airTempKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="mediumTempKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="heatingKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="fanKey"/>
		</>
	}

	if (port && defaultType === typeVariants.light) {
		const onClick = () => openModal(ModuleSelect, {defaultType})
			.then((fields) => fields && setItem({...item, ...fields}))
			.catch(onlyHide)
		return <>
			<ControlPicker item={item} setItem={setItem} port={port} field="brightnessKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="temperatureKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="redKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="greenKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="blueKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="hueKey"/>
			<ControlPicker item={item} setItem={setItem} port={port} field="saturationKey"/>
			<ControlPickerContainer title="Fill from module" icon="fa-regular fa-cubes" onClick={onClick}/>
		</>
	}

	return null

}

export const ControlEditor = ({item, setItem, port}) => {
	const {name, key} = item
	const digitalTypes = isDigital(key) && analyzeDigitalTypes(item, port)

	return <>
		<div className="ControlEditor">
			<IconContainer icon={item} details/>
			<div className="content">
				<label>
					Name <input type="text" value={name || ''}
											onChange={eventValue(name => setItem({...item, name}))}/>
					<span className="fa-regular fa-slider" onClick={() => editPortValue(item.key)}/>
				</label>

				<Types item={item} setItem={setItem} port={port} digitalTypes={digitalTypes}/>
			</div>
		</div>
		<div style={{display: "block"}}>
			{port && <ControlPickerContainer portKey={key} title="Parent module port" icon="fa-regular fa-sitemap"/>}
			<TypeContext item={item} setItem={setItem} port={port} defaultType={digitalTypes?.defaultType}/>
		</div>
	</>
}
