import React from 'react'
import {usePortValue} from '../../custom/portValues'
import {isDigital} from '../../shared/portUtils'
import {onOffAuto, rangeByUnit} from '../../shared/portConstants'
import './thermostat.scss'

const Heating = ({className, children}) => <>
	<rect className={className} width="4" height="24" x="88" y="80" ry="2">{children}</rect>
	<rect className={className} width="4" height="24" x="96" y="80" ry="2">{children}</rect>
	<rect className={className} width="4" height="24" x="104" y="80" ry="2">{children}</rect>
</>
const HeatingIcon = ({heatingKey, heating}) => {
	if (!heatingKey) return null
	if (heating) return <Heating className="heating_on">
		<animate attributeName="opacity" values="0.3;1;0.3" dur="1s" repeatCount="indefinite" calcMode="paced"/>
	</Heating>
	return <Heating className="heating_off"/>
}
const Propeller = ({fanKey, fan}) => {
	if (!fanKey) return null
	const d = "m 82,86 c -2.1642,0 -4.32809,1.63072 -5.63893,2.844141 -0.44648,-0.440249 -1.00291,-0.768673 -1.62435,-0.941847 0.50228,-1.090872 1.45987,-1.939269 2.43832,-2.591617 2.06783,-1.378569 1.84875,-3.715636 0,-4.824957 -3.44632,-2.067819 -8.96053,-0.689248 -8.96053,2.75714 0,2.164193 1.63072,4.328085 2.84415,5.63893 -0.44026,0.446475 -0.76867,1.002898 -0.94178,1.62427 -1.09094,-0.502272 -1.93941,-1.45987 -2.59169,-2.438316 -1.3785,-2.067744 -3.715567,-1.848744 -4.824889,0 -2.06782,3.446388 -0.689248,8.960593 2.757139,8.960593 2.1642,0 4.32816,-1.630719 5.63894,-2.844142 0.44648,0.440251 1.0029,0.768675 1.62428,0.941774 -0.50227,1.090945 -1.45987,1.939344 -2.43824,2.59169 -2.06783,1.378571 -1.84883,3.715636 0,4.824959 3.44637,2.067819 8.9606,0.689248 8.9606,-2.75714 0,-2.164193 -1.63072,-4.32816 -2.84415,-5.638931 0.44025,-0.446474 0.76867,-1.002897 0.94177,-1.624269 1.09095,0.502273 1.93942,1.459869 2.5917,2.438316 1.37849,2.067743 3.71562,1.848743 4.82495,0 2.06768,-3.446462 0.68911,-8.960594 -2.75729,-8.960594 z m -8.27128,7.014126 c -0.82837,0 -1.5,-0.671548 -1.5,-1.499994 0,-0.828448 0.67163,-1.499995 1.5,-1.499995 0.82838,0 1.5,0.671547 1.5,1.499995 0,0.828446 -0.67162,1.499994 -1.5,1.499994 z"
	if (!fan) return <path className="propeller_off" d={d}/>
	const duration = fan && isDigital(fanKey) ? 3000 : 10000 / fan
	return <path className="propeller_on" d={d}>
		<animateTransform attributeName="transform" type="rotate" from="0 74 91.5" to="360 74 91.5" dur={`${duration}ms`}
											repeatCount="indefinite"/>
	</path>

}
export const Thermostat = ({style, selected, item, unit, onClick}) => {
	const {key, airTempKey, onTempKey, offTempKey, mediumTempKey, heatingKey, fanKey, onOffAutoKey} = item
	const hasTemperatureSwitch = isDigital(key)
	const value = usePortValue(key)
	const optionaValue = usePortValue(onOffAutoKey)
	const airTemp = usePortValue(airTempKey)
	const onTemp = usePortValue(onTempKey)
	const offTemp = usePortValue(offTempKey)
	const mediumTemp = usePortValue(mediumTempKey)
	const heating = usePortValue(heatingKey)
	const fan = usePortValue(fanKey)
	const {minValue, maxValue} = rangeByUnit[unit] || {minValue: 0, maxValue: 100}
	const tempUnit = '°' // rangeByUnit[unit]
	const percentage = Math.max(0, Math.min(100, 100 * (airTemp - minValue) / (maxValue - minValue)))
	const strokeDashoffset = Math.round(2.48 * (100 - percentage))

	const onOffAutoValue = hasTemperatureSwitch && onOffAutoKey ? optionaValue : value

	console.log("-> render Thermostat.js", key)

	const tempKey = hasTemperatureSwitch ? value || !offTempKey ? onTempKey : offTempKey : onTempKey
	const tempValue = hasTemperatureSwitch ? value || !offTempKey ? onTemp : offTemp : onTemp
	const valueType = hasTemperatureSwitch && onOffAutoKey ? onOffAuto[onOffAutoValue] : onOffAuto[value]
	const tempClassName = hasTemperatureSwitch ? value ? 'on' : 'cool' : valueType

	return <div className={`thermostat ${selected ? 'sel' : ''}`} style={style} onClick={onClick}>
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="10 0 166 130">
			<path className="grey" d="M55,90 A55,55 0 1,1 140,90"/>
			<path className={`active ${valueType}`} d="M55,90 A55,55 0 1,1 140,90" style={{strokeDashoffset}}/>
			<text x="100" y="66" className={`temperature ${valueType}`}
						textAnchor="middle">{`${airTemp || 0}${tempUnit}`}</text>
			{mediumTempKey && <text x="60" y="130" className="corner auto"
															textAnchor="middle">{`${mediumTemp || 0}${tempUnit}`}</text>}
			{tempKey && <text x="145" y="130" className={`corner ${tempClassName}`}
												textAnchor="middle">{`${tempValue || 0}${tempUnit}`}</text>}
			<HeatingIcon heatingKey={heatingKey} heating={heating}/>
			<Propeller fanKey={fanKey} fan={fan}/>
		</svg>
		{mediumTempKey && false && <span className="fa-regular fa-fire" style={{marginLeft: "20px"}}/>}
		{mediumTempKey && false && <span className="value">{`${mediumTemp}${tempUnit}`}</span>}
		{mediumTempKey && false && <br/>}
	</div>

}
